<template>
  <ul class="tit-list">
    <li>경기날짜</li>
    <li>요일</li>
    <li>총입장수</li>
    <li>총입장금액</li>
  </ul>

  <ul class="data-list" v-if="$store.state.enroll.selectedGame == ''">
    <li :class="{on: $store.state.enroll.selectedGame === game.gId}"
                v-for="game in gameList" :key="game.gId">
      <span>{{game.gameDate}} {{game.headerNo !== '0'? `(DH${game.headerNo})` : ``}}</span>
      <span>{{game.gameday}}</span>
      <span class="txt-r">{{parseInt(game.crowdCn).toLocaleString()}}</span>
      <span class="txt-r">{{parseInt(game.crowdMo).toLocaleString()}}</span>
      <a v-if="$store.state.auth.authId !== '1'" class="btn" href="#"
        :class="game.approvalCk == 'True' ? 'approve' : 'enroll'"  @click.prevent="clickGame(game.gId, game.approvalCk)">
        {{game.approvalCk == 'True' ? '승인완료' : '등록'}}
      </a>
      <a v-if="$store.state.auth.authId === '1'  && game.approvalCk === 'True'" class="btn" href="#"
        :class="'approve'"  @click.prevent="clickGame(game.gId)">
        {{'승인완료'}}
      </a>
      <!-- <a v-if="$store.state.auth.authId === '1' && game.approvalCk != 'True'" class="btn" href="#"
        :class="'approve'"  @click.prevent="clickGame(game.gId)">
        {{'승인완료'}}
      </a> -->
      <a v-if="$store.state.auth.authId === '1' && game.approvalCk != 'True'" class="btn okay" href="#" @click.prevent="clickGame(game.gId)">확인</a>
      <a v-if="$store.state.auth.authId === '1' && game.approvalCk != 'True'" class="btn delete" href="#" @click.prevent="clickDelete(game.gId)">삭제</a>
    </li>
  </ul>

  <ul class="data-list" v-else>
    <li :class="{on: $store.state.enroll.selectedGame === game.gId}"
                v-for="game in gameList" :key="game.gId">
      <a @click.prevent="clickGame(game.gId, game.approvalCk)">
        <span>{{game.gameDate}} {{game.headerNo !== '0'? `(DH${game.headerNo})` : ``}}</span>
        <span>{{game.gameday}}</span>
        <span class="txt-r">{{parseInt(game.crowdCn).toLocaleString()}}</span>
        <span class="txt-r">{{parseInt(game.crowdMo).toLocaleString()}}</span>
      </a>
    </li>
  </ul>
  <ul class="total-list">
    <li>합계</li>
    <li>{{parseInt(monthTotal.totalCrowdCn).toLocaleString()}}</li>
    <li>{{parseInt(monthTotal.totalCrowdMo).toLocaleString()}}</li>
  </ul>
</template>
<script>

export default{ 
  name:'EnrollGameListView',
  components:{},
  data() {
    return{
      gameList: [],
      monthTotal: {totalCrowdCn: 0, totalCrowdMo: 0},
      isVisible:true,
    };
  },
  setup() {},
  created() {},
  mounted() {
    if(this.$store.state.enroll.selectedTeam.length > 0) {
      this.getGameList();
      this.getMonthTotal();
    }
  },
  unmounted() {},
  watch: {
    '$store.state.enroll.selectedTeam' () {
      //console.log(this.$store.state.enroll.selectedTeam)
      if(this.$store.state.enroll.selectedTeam.length > 0) {
        this.getGameList();
        this.getMonthTotal();
      }
    },

    '$store.state.enroll.isReload' () {
      this.getGameList();
      this.getMonthTotal();
    },
  },
  methods: {
    // 경기 목록 가져오기
    getGameList(){
      this.$store.dispatch('enroll/getGameList').then(res => {
        this.gameList = res.data.dataList;
      });
    },

    // 선택 월 합계 가져오기
    getMonthTotal(){
      this.$store.dispatch('enroll/getMonthTotal').then(res => {
        if(Object.keys(res.data.totalData).length !== 0) {
          this.monthTotal = res.data.totalData
        } else {
          this.monthTotal = {totalCrowdCn : 0, totalCrowdMo: 0}
        }
      });
    },

    // 경기 선택
    clickGame(gId, approvalCk = 'False') {
      this.$store.commit('enroll/setSelectedGame', gId);
      
      if (approvalCk === 'True') {
        this.$emit('hideUpdateButton', true);
      } else {
        this.$emit('hideUpdateButton', false);
      }

    },

    // 입장 수입 삭제하기
    clickDelete(gId){
      if(confirm('삭제 하시겠습니까?')){
        this.$store.dispatch('enroll/deleteAdmission', gId).then(res => {
          if(res.data.code === 201) {
            alert('삭제 되었습니다.');

            this.$store.commit('enroll/setIsReload', !this.$store.state.enroll.isReload);
            this.$store.commit('enroll/setSelectedGame', '');
          }
        });
      }
    }
  }
}
</script>
