<!-- 구단 입장수입 명세서 -->
<template>
  <template v-if=this.items >
    <div>
      <div class="report-tit"> 
        <p>{{$store.state.refer.year}}년 {{$store.state.refer.month}}월 입장수입 명세서</p>
        <span class="txt-l">구단명 : {{getTeamFullName(this.items[0]?.tId)}}</span>
        <span class="txt-r">(단위 : 명,원/vat포함)</span>
      </div>
      <table class="tb-data font-s">
        <colgroup>
          <col width="30px">
          <col width="30px">
          <col width="30px">
          <col width="30px">
          <col width="43px">

          <col width="68px">
          <col width="68px">
          <col width="68px">
          <col width="68px">
          <col width="68px">
          <col width="68px">

          <col width="94px">
          <col width="94px">
          <col width="84px">
          <col width="81px">
          <col width="81px">
        </colgroup>
        <tr>
          <th colspan="5">경기정보</th>
          <th colspan="6">관중 수</th>
          <th colspan="6">입장수입 </th>
        </tr>
        <tr>
          <th>구분</th>
          <th>월</th>
          <th>일</th>
          <th>요일</th>
          <th>대진팀</th>

          <th>유료 일반</th>
          <th>스카이박스</th>
          <th>계</th>
          <th>어린이(무료)</th>
          <th>우대석(무료)</th>
          <th>합계</th>

          <th>일반티켓<p>(일일,시즌권 등 전체)</p></th>
          <th>스카이박스<p>(일일,시즌권 등 전체)</p></th>
          <th>카드 할인</th>
          <th>멤버십</th>
          <th>광고 및 프로모션</th>

          <th>합계</th>
        </tr>
        <tr v-for="(itemChild, indexChild) in copyItems" :key="indexChild">
          <td class="txt-c">{{indexChild + 1}}</td>
          <td v-if="indexChild === 0" :rowspan="parseInt(this.items.length)" class="txt-c">{{itemChild.monthId}}</td>
          <td class="txt-c">{{itemChild.dayId}}</td>
          <td class="txt-c">{{itemChild.weekId.substring(0, 1)}}</td>
          <td class="txt-c">{{itemChild.vsTNm}}</td>

          <td>{{itemChild.normalCrowdAudienceSc}}</td>
          <td>{{itemChild.skyboxAudienceSc}}</td>
          <td>{{itemChild.normalCrowdSum == 0 ? '' : itemChild.normalCrowdSum}}</td>
          <td>{{itemChild.childFreeAudienceSc}}</td>
          <td>{{itemChild.preferFreeAudienceSc}}</td>
          <td>{{itemChild.allCrowdSum == 0 ? '' : itemChild.allCrowdSum}}</td>
          <td>{{itemChild.normalCrowdMoneySc}}</td>
          <td>{{itemChild.skyboxMoneySc}}</td>
          <td>{{itemChild.cardSaleMoneySc}}</td>
          <td>{{itemChild.membershipMoneySc}}</td>
          <td>{{itemChild.adPromoMoneySc}}</td>
          <td>{{itemChild.allIncomeSum == 0 ? '' : itemChild.allIncomeSum}}</td>
        </tr>
        <tr v-if="copySumItems" class="total">
          <td class="txt-c" colspan="5"><b>합계</b></td>
          <td>{{ copySumItems.sumAudience.normalCrowdAudienceSc }}</td>
          <td>{{ copySumItems.sumAudience.skyboxAudienceSc }}</td>
          <td>{{ copySumItems.sumAudience.normalCrowdSum == 0 ? '' : copySumItems.sumAudience.normalCrowdSum}}</td>
          <td>{{ copySumItems.sumAudience.childFreeAudienceSc }}</td>
          <td>{{ copySumItems.sumAudience.preferFreeAudienceSc }}</td>
          <td>{{ copySumItems.sumAudience.allCrowdSum == 0 ? '' : copySumItems.sumAudience.allCrowdSum}}</td>
          <td>{{ copySumItems.sumMoney.normalCrowdMoneySc }}</td>
          <td>{{ copySumItems.sumMoney.skyboxMoneySc }}</td>
          <td>{{ copySumItems.sumMoney.cardSaleMoneySc }}</td>
          <td>{{ copySumItems.sumMoney.membershipMoneySc }}</td>
          <td>{{ copySumItems.sumMoney.adPromoMoneySc }}</td>
          <td>{{ copySumItems.sumMoney.allIncomeSum == 0 ? '' : copySumItems.sumMoney.allIncomeSum}}</td>
        </tr>
      </table> 
      <div class="report-tit"><span>&nbsp;</span> </div>
      <div class="confirm-box">
        {{`${new Date().getFullYear()}년 ${(new Date().getMonth()+1)}월 ${(new Date().getDate())}일`}}<br/>
        상기 인원과 금액을 정히 확인함<br/>
        {{this.items[0]?.companyNm}} 대표이사 {{this.items[0]?.ceoNm}}
      </div>
    </div>  
    <div v-if="this.items.length !== 1" style='page-break-before:always'></div>
    <div :id="`hiddenDiv`"></div>
  </template>
</template>
<style scoped src="@/assets/css/print.css"></style>

<script>

export default{ 
  name:'TotalSpecification',
  components:{},
  data() {
    return{
      sampleData: '',
      items: null,
      itemsSumDetail: null,
      itemsSumMoney: null,
      copyItems: [],
      copySumItems: [],
      emptySpaces: [],
    };
  },
  watch:{
    '$store.state.refer.isReload'(){
      this.getTotalSpecification();
    },
    '$store.state.refer.isDownload'(){
      this.downloadMode(this.$store.state.refer.isDownload);
    }
  },
  setup() {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });  
  },
  mounted() {
    this.getTotalSpecification();
  },
  unmounted() {},
  methods: {
        getTotalSpecification() {
            this.$store.dispatch("refer/getTotalSpecification").then(res => {
                    this.items = res.data.value.data.detailList;
                    this.itemsSumDetail = res.data.value.data.sumDetailList;
                    
                    if(this.items !== undefined){
                      this.copyItems = res.data.value.data.detailList;
                      
                      this.copyItems.forEach(item =>{
                        item.normalCrowdSum = ((item.normalCrowdAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(item.normalCrowdAudienceSc.replace(/,/g, ''))) 
                                              + (item.skyboxAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(item.skyboxAudienceSc.replace(/,/g, '')))).toLocaleString();
                        item.allCrowdSum = ((item.normalCrowdAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(item.normalCrowdAudienceSc.replace(/,/g, ''))) 
                                            + (item.skyboxAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(item.skyboxAudienceSc.replace(/,/g, ''))) 
                                            + (item.childFreeAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(item.childFreeAudienceSc.replace(/,/g, ''))) 
                                            + (item.preferFreeAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(item.preferFreeAudienceSc.replace(/,/g, '')))).toLocaleString(); 
                        item.allIncomeSum = ((item.normalCrowdMoneySc.replace(/,/g, '') == '' ? 0 : parseInt(item.normalCrowdMoneySc.replace(/,/g, ''))) 
                                              + (item.skyboxMoneySc.replace(/,/g, '') == '' ? 0 : parseInt(item.skyboxMoneySc.replace(/,/g, ''))) 
                                              + (item.cardSaleMoneySc.replace(/,/g, '') == '' ? 0 : parseInt(item.cardSaleMoneySc.replace(/,/g, ''))) 
                                              + (item.membershipMoneySc.replace(/,/g, '') == '' ? 0 : parseInt(item.membershipMoneySc.replace(/,/g, ''))) 
                                              + (item.adPromoMoneySc.replace(/,/g, '') == '' ? 0 : parseInt(item.adPromoMoneySc.replace(/,/g, ''))) ).toLocaleString(); 
                      });
                    } 

                    if(this.itemsSumDetail !== undefined){
                      this.copySumItems = res.data.value.data.sumDetailList[0];
                      this.copySumItems.sumAudience.normalCrowdSum = ((this.copySumItems.sumAudience.normalCrowdAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumAudience.normalCrowdAudienceSc.replace(/,/g, ''))) 
                                              + (this.copySumItems.sumAudience.skyboxAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumAudience.skyboxAudienceSc.replace(/,/g, '')))).toLocaleString();
                      this.copySumItems.sumAudience.allCrowdSum = ((this.copySumItems.sumAudience.normalCrowdAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumAudience.normalCrowdAudienceSc.replace(/,/g, ''))) 
                                            + (this.copySumItems.sumAudience.skyboxAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumAudience.skyboxAudienceSc.replace(/,/g, ''))) 
                                            + (this.copySumItems.sumAudience.childFreeAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumAudience.childFreeAudienceSc.replace(/,/g, ''))) 
                                            + (this.copySumItems.sumAudience.preferFreeAudienceSc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumAudience.preferFreeAudienceSc.replace(/,/g, '')))).toLocaleString();          
                      this.copySumItems.sumMoney.allIncomeSum = ((this.copySumItems.sumMoney.normalCrowdMoneySc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumMoney.normalCrowdMoneySc.replace(/,/g, ''))) 
                                              + (this.copySumItems.sumMoney.skyboxMoneySc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumMoney.skyboxMoneySc.replace(/,/g, ''))) 
                                              + (this.copySumItems.sumMoney.cardSaleMoneySc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumMoney.cardSaleMoneySc.replace(/,/g, ''))) 
                                              + (this.copySumItems.sumMoney.membershipMoneySc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumMoney.membershipMoneySc.replace(/,/g, ''))) 
                                              + (this.copySumItems.sumMoney.adPromoMoneySc.replace(/,/g, '') == '' ? 0 : parseInt(this.copySumItems.sumMoney.adPromoMoneySc.replace(/,/g, ''))) ).toLocaleString(); 
                    
                    }
                });
    },    
    getTeamFullName(teamId) {
      const teamArray = this.$store.state.teamList.find(
        (item) => item.teamId == teamId
      );
      return teamArray ? teamArray.teamFullName : "";
    }, 
    downloadMode(isDownload){
      if(this.items != null && isDownload == true){    
        //for(let count = 0; count < this.items.length - 1; count++){
        //  const hiddenDiv = document.querySelector(`#hiddenDiv${count}`);
        //  hiddenDiv.style.height = (900 - (document.querySelector(`#hiddenHeight${count}`)).clientHeight) + 'px';
        //}
      }else{
        for(let count = 0; count < this.items.length; count++){
          const hiddenDiv = document.querySelector(`#hiddenDiv${count}`);
          hiddenDiv.style.height = 0 + 'px';
        }
      }
    }
  },
}
</script>
