<template>
  <div class="report-tit">
    <p>전체 입장수입 현황 ({{ this.common.yyyyMMddDot($store.state.refer.firstSelectDate) }}~{{ this.common.yyyyMMddDot($store.state.refer.secondSelectDate) }})</p>
    <span class="txt-r">(단위: 명, 원)</span>
  </div>
  <table class="tb-data">
    <colgroup>
      <col width="138px" />
      <col width="67px" />
    </colgroup>
    <thead>
      <tr>
        <th>일자</th>
        <th>구장</th>
        <th>원정팀</th>
        <th>홈팀</th>
        <th>DH</th>
        <th>총관중</th>
        <th>유료관중</th>
        <th>우대석(무료)</th>
        <th>어린이(무료)</th>
        <th>입장금액</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td id="gameDate" class="txt-c">{{ item.gameDate }}</td>
        <td class="txt-c">{{ item.stadiumName }}</td>
        <td class="txt-c">{{ getTeamFullName(item.awayId) }}</td>
        <td class="txt-c">{{ getTeamFullName(item.homeId) }}</td>
        <td class="txt-c">{{setDoubleHeaderText(item.doubleHeader)}}</td>
        <td class="txt-c">{{ item.totalCrowdCn }}</td>
        <td class="txt-c">{{ item.totalPayCrowdCn }}</td>
        <td class="txt-c">{{ item.priorityFreeCn }}</td>
        <td class="txt-c">{{ item.childFreeCn }}</td>
        <td class="txt-c">{{ item.totalCrowdMo }}</td>
      </tr>
    </tbody>
  </table>
  <div id="hidden-result" class="report-wrap hidden-table">
    <div class="report-tit">
      <p>전체 입장수입 현황 ({{ this.common.yyyyMMddDot($store.state.refer.firstSelectDate) }}~{{ this.common.yyyyMMddDot($store.state.refer.secondSelectDate) }})</p>
      <span class="txt-r">(단위: 명, 원)</span>
    </div>
    <table class="tb-data">
      <colgroup>
        <col width="138px" />
        <col width="67px" />
      </colgroup>
      <thead>
        <tr>
          <th>일자</th>
          <th>구장</th>
          <th>원정팀</th>
          <th>홈팀</th>
          <th>DH</th>
          <th>총관중</th>
          <th>유료관중</th>
          <th>우대석(무료)</th>
          <th>어린이(무료)</th>
          <th>입장금액</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in hiddenItems" :key="index">
          <td id="gameDate" class="txt-c">{{ item.gameDate }}</td>
          <td class="txt-c">{{ item.stadiumName }}</td>
          <td class="txt-c">{{ getTeamFullName(item.awayId) }}</td>
          <td class="txt-c">{{ getTeamFullName(item.homeId) }}</td>
          <td class="txt-c">{{setDoubleHeaderText(item.doubleHeader)}}</td>
          <td class="txt-c">{{ item.totalCrowdCn }}</td>
          <td class="txt-c">{{ item.totalPayCrowdCn }}</td>
          <td class="txt-c">{{ item.priorityFreeCn }}</td>
          <td class="txt-c">{{ item.childFreeCn }}</td>
          <td class="txt-c">{{ item.totalCrowdMo }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped src="@/assets/css/print.css"></style>

<script>
import Common from '@/utils/common';

export default {
  name: "TotalCrowd",
  components: {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });
  },
  data() {
    return {
      common: new Common(),
      items: [],
      hiddenItems: [],
      pageSize: 10
    };
  },
  setup() {},
  watch: {
    '$store.state.refer.isReload'() {
      this.resetPageAndFetch();
    },
    getNowPageNum: {
      handler: "getTotalCrowd",
      deep: true,
    },
  },
  computed: {
    getNowPageNum() {
      return this.$route.query.page;
    },
  },
  mounted() {
    this.getTotalCrowd();
  },
  methods: {
    getTotalCrowd() {
      this.$store.dispatch("refer/getTotalCrowd", {
        nowPageNum: this.getNowPageNum,
        pageSize: this.pageSize,
      }).then(res => {
        this.items = res.data.value.data || [];
        this.$store.commit("setTotalCnt", {
          totalCnt: Number(res.data.value.totalCnt),
        });
      }),
      this.$store.dispatch("refer/getTotalCrowd", {
        nowPageNum: 0,
        pageSize: 0
      }).then(res => {
        this.hiddenItems = res.data.value.data || [];
      });
    },
    getTeamFullName(teamId) {
      const teamArray = this.$store.state.teamList.find(
        (item) => item.teamId == teamId
      );
      return teamArray ? teamArray.teamFullName : "";
    },
    resetPageAndFetch() {
      this.$router.push({ query: { ...this.$route.query, page: 1 } }).then(() => {
        this.getTotalCrowd();
      });
    },
    setDoubleHeaderText(dHeader) {
      if(dHeader !== "0"){
        return `DH${dHeader}`;
      }
    }
  },

};
</script>
                                                      