<template>
  <!-- 알림 페이지는 <h3 /> 태그 존재하지 않아서 인라인 스타일 지정 -->
  <section class="board" style="margin-top: 66px">
    <h4>
      <router-link
        :class="{ on: this.$route.params.section === 'new' }"
        to="/alarm/new"
        >새로 받은 알림</router-link
      >
      <router-link
        :class="{ on: this.$route.params.section === 'history' }"
        to="/alarm/history?page=1"
        >등록·수정 이력</router-link
      >
    </h4>

    <AlarmNewView v-if="$route.params.section === 'new'"></AlarmNewView>
    <AlarmHistoryView
      v-else-if="$route.params.section === 'history'"
    ></AlarmHistoryView>

    <PagingView v-if="$route.params.section === 'history'"></PagingView>
  </section>
</template>

<script>
import AlarmNewView from "@/components/Alarm/AlarmNewView.vue";
import AlarmHistoryView from "@/components/Alarm/AlarmHistoryView.vue";
import PagingView from "@/components/Layout/PagingView.vue";

export default {
  name: "AlarmView",
  components: { AlarmNewView, AlarmHistoryView, PagingView },
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });
  },
};
</script>
