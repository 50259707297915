<!-- 구단별 // 정산수입 -->
<template>
  <div class="report-tit"> 
    <p>구단별 정산수입</p>
    <p class="term">( {{this.common.yyyyMMddDot($store.state.refer.firstSelectDate)}} 부터 ) ( {{this.common.yyyyMMddDot($store.state.refer.secondSelectDate)}} 까지 )</p>
    <span class="txt-r">Date : {{this.common.yyyyMMddDot(this.common.DateObjToString(new Date()))}}</span>
  </div>
  <table class="tb-data">
    <colgroup>
      <col width="63px">
      <col width="130px">
      <col width="63px">
      <col width="136px">
      <col width="63px">
      <col width="136px">
      <col width="63px">
      <col width="136px">
      <col width="136px">
    </colgroup>
    <tr>
      <th rowspan="2">순위</th>
      <th rowspan="2">구단</th>
      <th colspan="2">홈경기수입</th>
      <th colspan="2">원정경기수입</th>
      <th colspan="2">구단수입</th>
      <th rowspan="2">1위와의차액</th>
      <th rowspan="2">비고</th>
    </tr>
    <tr>
      <th class="border">경기수</th>
      <th>금액</th>
      <th>경기수</th>
      <th>금액</th>
      <th>경기수</th>
      <th>금액</th>
    </tr>
    <tr v-for="(item, index) in items" :key="index">
      <td class="txt-c">{{ item.orderNo }}</td>
      <td>{{ getTeamFullName(item.tId) }}</td>
      <td>{{ item.homeGCn }}</td>
      <td>{{ item.homeCrowdMo }}</td>
      <td>{{ item.awayGCn }}</td>
      <td>{{ item.awayCrowdMo }}</td>
      <td>{{ item.totalGCn }}</td>
      <td>{{ item.totalCrowdMo }}</td>
      <td>{{ item.differCrowdMo }}</td>
      <td></td>
    </tr>
    <tr v-if="itemsResult" class="total">
      <td class="txt-c"><b>합계</b></td>
      <td></td>
      <td>{{ itemsResult.homeGCn }}</td>
      <td>{{ itemsResult.homeCrowdMo }}</td>
      <td>{{ itemsResult.awayGCn }}</td>
      <td>{{ itemsResult.awayCrowdMo }}</td>
      <td>{{ itemsResult.totalGCn }}</td>
      <td>{{ itemsResult.totalCrowdMo }}</td>
      <td>{{ itemsResult.differCrowdMo }}</td>
      <td></td>
    </tr>
  </table>
</template>
<style scoped src="@/assets/css/print.css"></style>

<script>
import Common from '@/utils/common';

export default{ 
  name:'TeamCalculateIncome',
  components:{},
  data() {
    return{
      sampleData: '',
      common: new Common(),
      items: null,
      itemsResult: null,
    };
  },
  watch:{
    '$store.state.refer.isReload'(){
      this.getTeamCalculateIncome();
    }
  },
  setup() {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });    
  },
  mounted() {
    this.getTeamCalculateIncome();
  },
  unmounted() {},
  methods: {
    getTeamCalculateIncome() {
            this.$store.dispatch("refer/getTeamCalculateIncome").then(res => {
                    this.items = res.data.value.data.teamTotalIncomeList;
                    this.itemsResult = res.data.value.data.sumTotalIncomeList;
                });
    },
    getTeamFullName(teamId) {
      const teamArray = this.$store.state.teamList.find(
        (item) => item.teamId == teamId
      );
      return teamArray ? teamArray.teamFullName : "";
    },
  }
}
</script>
