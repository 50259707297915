//import axios from 'axios';
import CommonService from '@/utils/service/commonService'

class Common {
  /**
   * 날짜 형식 변경
   * @param {string} value 날짜 문자열 (ex.20230804)
   * @param {string} division 구분자 문자열 ('/' > '2023/08/04')
   * @param {boolean} isDayWeek 요일 표출 여부
   * @returns 
   */
  yyyyMMdd(value, division, isDayWeek){
    // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
    if(value == '') return '';

    var js_date = new Date(value);

    // 연도, 월, 일 추출
    var year = js_date.getFullYear();
    var month = js_date.getMonth() + 1;
    var day = js_date.getDate();

    const week = ['일', '월', '화', '수', '목', '금', '토'];
    var dayWeek = week[js_date.getDay()];

    // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
    if(month < 10){
        month = '0' + month;
    }

    if(day < 10){
        day = '0' + day;
    }
    
    if(isDayWeek) {
      return `${year}${division}${month}${division}${day} (${dayWeek})`;
    } else {
      return `${year}${division}${month}${division}${day}`; 
    }
  }

  /**
   * 날짜 형식 변경 (20230101 -> 2023-01-01)
   * @param {string} value 날짜 문자열
   * @returns {string} 변환된 날짜 문자열
   */
  yyyyMMddDash(value){
    var year = value.toString().substring(0,4);
    var month = value.toString().substring(4,6);
    var day = value.toString().substring(6,8);

    return year + '-' + month + '-' + day;
  }

  /**
   * 날짜 형식 변경 (20230101 -> 2023. 01. 01)
   * @param {string} value 날짜 문자열
   * @returns {string} 변환된 날짜 문자열
   */
  yyyyMMddDot(value){
    var year = value.toString().substring(0,4);
    var month = value.toString().substring(4,6);
    var day = value.toString().substring(6,8);

    return `${year}. ${month}. ${day}`;
  }

  /**
   * Date 객체를 문자열 yyyyMMdd 형태로 변환
   * @param {Object} DateObj 
   * @returns {string} 날짜 문자열
   */
  DateObjToString(DateObj) {
    const year = DateObj.getFullYear();
    const month = String(DateObj.getMonth() + 1).padStart(2, '0');
    const day = String(DateObj.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
    }

  /**
   * 두 Date 객체의 날짜를 비교한다. (년월일만 비교)
   * @param {Object} DateObj1
   * @param {Object} DateObj2
   * @returns {boolean} 동일 날짜 여부
   */
  isSameDate(DateObj1, DateObj2) {
    return DateObj1.getFullYear() === DateObj2.getFullYear()
      && DateObj1.getMonth() === DateObj2.getMonth()
      && DateObj1.getDate() === DateObj2.getDate();
  }
  
  /**
   * 쿠키 값을 읽기 위한 함수
   * @param {*} name Cookie Id 
   * @returns 
   */
  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  // 팀명 fullname 가져오기 (ex.한화 이글스)
  async getTeamFullName(teamId) {
    try {
      const res = await new CommonService().$getTeamList();
      const filteredItem = res.data.teamList.find(item => item.teamId === teamId);
      if (filteredItem) {
        return filteredItem.teamFullName;
      }
    } catch (error) {
      console.error(error);
    }
  }

}

export default Common