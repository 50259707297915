<template>
  <h4>{{ title }}</h4>
  <table class="tb type-row">
    <colgroup>
      <col width="200px" />
    </colgroup>
    <tr>
      <th>날짜</th>
      <td>
        <div class="calender">
          <VueDatePicker
            v-model="startDate"
            :clearable="false"
            :format="format"
            :max-date="endDate"
            locale="ko"
            auto-apply
            :enable-time-picker="false"
            year-first
            @update:model-value="selectCal('start')"
            :disabled="!isRegist"
          >
            <template #input-icon>
              <img
                class="input-slot-image"
                src="@/assets/images/contents/icon_cal.png"
              />
            </template>
          </VueDatePicker>
        </div>
        -
        <div class="calender">
          <VueDatePicker
            v-model="endDate"
            :min-date="startDate"
            :clearable="false"
            :format="format"
            locale="ko"
            auto-apply
            :enable-time-picker="false"
            year-first
            @update:model-value="selectCal('end')"
            :disabled="!isRegist"
          >
            <template #input-icon>
              <img
                class="input-slot-image"
                src="@/assets/images/contents/icon_cal.png"
              />
            </template>
          </VueDatePicker>
        </div>
      </td>
    </tr>

    <TeamManageRegistCount
      ref="count"
    ></TeamManageRegistCount>
  </table>

  <div class="btn-wrap">
    <a
      v-if="isRegist"
      class="btn save"
      href="#"
      @click.prevent="clickRegister(section, isRegist)"
      >등록</a
    >
    <a href="" v-if="isRegist" @click.prevent="cancelRegist" class="btn cancel">취소</a>
    <a href="" @click.prevent="cancelRegist" class="btn list">목록</a>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import Common from "@/utils/common";

import TeamManageRegistCount from "@/components/TeamManage/TeamManageRegist/TeamManageRegistCount.vue";

export default {
  name: "TeamManageRegistView",
  components: { VueDatePicker, TeamManageRegistCount },
  data() {
    return {
      common: new Common(),

      section: this.$route.params.section,
      title: "",
      isRegist: true,

      // datepicker
      startDate: "",
      endDate: "",
    };
  },
  mounted() {
    this.title = "입장 인원 관리";
  },
  unmounted() {},
  watch: {
    "$store.state.manage.modifyList"(data) {
      // console.log(data);
      if (data) {
        this.startDate = new Date(data[0].S_DT);
        this.endDate = new Date(data[0].E_DT);
        this.isRegist = false;
      }
    },
  },
  methods: {
    // 달력 format
    format(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      return `${year}.${month}.${day}`;
    },

    selectCal(section) {
      this.$store.commit("manage/setSelectedDate", {
        section: section,
        selectDate:
          section === "start"
            ? this.common.DateObjToString(this.startDate)
            : this.common.DateObjToString(this.endDate),
      });
    },
    // 취소 버튼 클릭
    cancelRegist() {
      this.$router.go(-1);
    },
    // 등록버튼 클릭
    clickRegister(section, isRegist) {
      if (confirm(`등록 하시겠습니까?`)) {
        if (isRegist) {
          this.$refs.count.registerCountCheck().then(count => {
            if (count > 0) {
              alert("기존 등록된 날짜입니다.");
            } else {
              this.$refs.count.registerCount();
            }
          });
        }
      }
    },
  },
};
</script>
