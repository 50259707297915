<template>
  <header>
    <div class="top">
      <div class="wrap">
        <a class="logo" href="#" @click.prevent="$router.push('/enroll')">
          <img
            src="@/assets/images/contents/txt_title.png"
            alt="KBO 관중입장수입"
          />
        </a>

        <a
          v-if="userSession.U_AUTH === '1'"
          href="#"
          @click.prevent="$router.push('/alarm/new')"
        >
          <i class="icon-alert"></i>
          <span class="count">{{ getNewAlarmCount }}</span>
        </a>

        <i class="icon-user"></i>
        <b>{{ userSession.U_T_NM }}</b
        >님 환영합니다.
        <a class="btn-logout" href="#" @click.prevent="btnLogout"> LOGOUT </a>
      </div>
    </div>

    <div class="gnb">
      <div class="wrap">
        <ul class="menu">
          <li
            :class="{on: this.$route.name.indexOf('enroll') &gt; -1 || this.$route.name == 'alarm'}"
          >
            <router-link to="/enroll" @click="clickGnb">등록</router-link>
          </li>
          <li
            v-if="userSession.U_AUTH === '1'"
            :class="{on: this.$route.name.indexOf('reference') &gt; -1}"
          >
            <router-link to="/reference/month/income" @click="clickGnb">자료실</router-link>
          </li>
          <li
            v-if="userSession.U_AUTH === '1'"
            :class="{on: this.$route.name.indexOf('manage') &gt; -1}"
          >
            <router-link to="/manage/count?page=1" @click="clickGnb">관리자</router-link>
          </li>
          <li
            v-if="userSession.U_AUTH === '2'"
            :class="{on: this.$route.name.indexOf('team') &gt; -1}"
          >
            <router-link to="/team/count?page=1" @click="clickGnb">관리자</router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import MemberService from "@/utils/service/memberService.js";
import Common from "@/utils/common";

export default {
  name: "HeaderView",
  components: {},
  data() {
    return {
      memberService: new MemberService(),
      common: new Common(),

      userSession: { U_T_NM: "" }, // 사용자 정보
    };
  },
  computed: {
    getNewAlarmCount() {
      return this.$store.state.alarm.newAlarmCount;
    },
  },
  setup() {},
  created() {
    this.$store.dispatch("alarm/getNewAlarmCount").then((res) => {
      this.$store.commit("alarm/setNewAlarmCount", res.data.totalAlarmCn);
    });
  },
  mounted() {
    this.getUserSession();
  },
  unmounted() {},
  methods: {
    // 로그아웃
    async btnLogout() {
      await this.memberService.$logout();
      this.$router.replace("/");
    },

    // 유저정보 세션값 확인
    async getUserSession() {
      const response = await this.memberService.$getUserSession();
      //console.log('getUserSession', response.data);

      if (response.data.length > 0) {
        // 세션값 있을 때
        this.userSession = JSON.parse(response.data);
        const teamFullName = await this.common.getTeamFullName(
          this.userSession.U_T_ID
        );
        this.userSession.U_T_NM = teamFullName ? teamFullName : "관리자";
        //this.$store.commit('setUserAuth', {teamId: this.userSession.U_T_ID || 'null', authId: this.userSession.U_AUTH});
      } else {
        // 세션값 없을 때
        this.btnLogout();
        this.$router.replace("/");
      }
    },

    clickGnb() {
      this.$store.commit("enroll/setSelectedGame", "");
      this.$store.commit('refer/setMainState', "");
      this.$store.commit('refer/setSubState', "");
    },
  },
};
</script>
