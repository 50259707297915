<template>
  <h3>{{year}}.{{month}}
    <VueDatePicker v-model="selectedMonth" month-picker
                locale="ko"
                :clearable="false"
                auto-apply
                :enable-time-picker="false"
                @update:model-value="selectCal()"
                >
      <template #input-icon>
        <img class="input-slot-image" src="@/assets/images/contents/btn_cal.png"/>
      </template>
    </VueDatePicker>
    <div class="btn-controll" v-if="$store.state.enroll.selectedGame && $store.state.auth.authId !== '1'">
      <a href="#" class="btn update" v-if="showUpdateButton" @click.prevent="clickBtn('isUpdate')">수정</a>
      <a href="#" class="btn cancel" @click.prevent="clickBtn('cancel')">취소</a>
      <a href="#" class="btn save" @click.prevent="clickBtn('save')">저장</a>
    </div>
    <div class="btn-controll" v-else-if="$store.state.enroll.selectedGame && $store.state.auth.authId === '1'">
      <a href="#" class="btn cancel" @click.prevent="clickBtn('update')">수정</a>
      <a href="#" class="btn save" @click.prevent="clickBtn('approve')">승인</a>
      <a href="#" class="btn send" @click.prevent="clickBtn('send')">전송</a>
    </div>
  </h3>
  <section class="board-cover">
    <EnrollTeamListView ref="enrollTeam"></EnrollTeamListView>
    <div class="board right" :class="{'edit-mode': $store.state.enroll.selectedGame != ''}">
      <EnrollGameListView v-if="$store.state.enroll.selectedTeam" @hideUpdateButton="handleUpdateButtonVisibility"></EnrollGameListView>
    </div>
  </section>
  <section class="board bottom">
    <!-- <EnrollBottomView ref="enrollBottom" v-if="$store.state.enroll.selectedGame"></EnrollBottomView> -->
    <EnrollBottomView  ref="enrollBottom" v-if="$store.state.enroll.selectedGame" :isEditable="isEditable" :showUpdateButton="showUpdateButton"></EnrollBottomView>
  </section>
</template>

<script>

import VueDatePicker from '@vuepic/vue-datepicker';
import EnrollTeamListView from './EnrollTeamListView.vue';
import EnrollGameListView from './EnrollGameListView.vue';
import EnrollBottomView from './EnrollBottomView.vue';

import EnrollService from '@/utils/service/enrollService';
import Common from '@/utils/common';

export default{ 
  name:'EnrollView',
  components:{VueDatePicker, EnrollTeamListView, EnrollGameListView, EnrollBottomView},
  data() {
    return{
      enrollService: new EnrollService(),
      common: new Common(),

      selectedMonth: {year: new Date().getFullYear(),
                      month: new Date().getMonth()},
      showUpdateButton: true, // 기본적으로는 버튼을 보이도록 설정
      isEditable: false, // 입력 필드가 편집 가능한지 여부를 제어
      idEditClick: false, // 수정 버튼 클릭 여부 확인
    };
  },
  setup() {},
  created() {},
  mounted() {
    //this.getSession()
    this.selectCal();
  },
  unmounted() {},
  computed: {
    year() {
      return this.selectedMonth.year;
    },
    month() {
      let month = this.selectedMonth.month + 1 > 9 ? this.selectedMonth.month + 1 : '0' + (this.selectedMonth.month + 1);
      return month;
    }
  },
  methods: {
    toggleEdit() {
      this.isEditable = !this.isEditable; // 편집 가능 상태 토글
    },
    handleUpdateButtonVisibility(show) {
      this.showUpdateButton = show;
    },
    // 달력 선택 시
    selectCal(){
      this.$store.commit('enroll/setCalendar', {year: this.year, month: this.month});
      this.$store.commit('enroll/setSelectedTeam', '');
      this.$store.commit('enroll/setSelectedGame', '');

      // 팀 불러오기
      this.$refs.enrollTeam.getTeamList();
    },

    // 상단 버튼 클릭
    async clickBtn(section){
      let alertMsg = '';

      if(section === 'cancel') { // 취소 버튼
        alertMsg = '취소';
        if(confirm(`${alertMsg} 하시겠습니까?`)){
          this.$store.commit('enroll/setSelectedGame', '');
        }
      } else if (section === 'approve') { // 승인 버튼
        // nn월 nn일 일 경우
        // const month = this.$store.state.enroll.selectedGame.substring(4, 6);
        // const day = this.$store.state.enroll.selectedGame.substring(6, 8);3

        // n월 n일 일 경우
        const gameDate = this.common.yyyyMMddDash(this.$store.state.enroll.selectedGame.substring(0, 8));
        const month = new Date(gameDate).getMonth() + 1;
        const day = new Date(gameDate).getDate();

        const teamId = this.$store.state.enroll.selectedTeam;
        //const gameId = this.$store.state.enroll.selectedGame;
        const teamFullName = await this.common.getTeamFullName(teamId);
        alertMsg = `${month}월 ${day}일 ${teamFullName} 승인`;
        
        if(confirm(`${alertMsg} 하시겠습니까?`)) {
          this.$refs.enrollBottom.approveAdmission(section);
          // this.enrollService.$approveAdmission(1, 0, gameDate.substring(0, 4), teamId, this.enrollService.getAlarmCd(section), gameId).then(res => {
          //   if(res.data.code === 201) {
          //     this.enrollService.$updateKboCrowd(1, 0, gameDate.substring(0, 4), gameId, 1).then(res => {
          //       console.log(res)
          //       if(res.data.code == '100') {
          //         alert('승인 되었습니다.');
          //         this.$store.commit('enroll/setSelectedGame', '');
          //         this.$store.dispatch("alarm/getNewAlarmCount").then(res => {
          //           this.$store.commit("alarm/setNewAlarmCount", res.data.totalAlarmCn);
          //         });
          //       }
          //     })
          //   }
          // })
        }
      } else if (section === 'send'){ // 전송 버튼
        if(confirm(`전송 하시겠습니까?`)){
          this.$refs.enrollBottom.sendCrowdData(section); // KBO 관리자 페이지 전송
        }
      } else if(section === 'isUpdate'){
        this.idEditClick = (this.idEditClick === true) ? false : true;
        this.toggleEdit(); // '수정' 버튼 클릭 시 편집 가능 상태 변경
      } else {
        alertMsg = section === 'save' ? '저장' : '수정';

        if(this.showUpdateButton && this.idEditClick){ // 수정 버튼이 활성화 되어 있을때
          if(confirm(`KBO 승인완료 경기의 입장객 및 입장금액 \n수정 시 반드시 KBO 업무용(공용폰)으로 연락주시기 바랍니다.`)) {
            this.$refs.enrollBottom.saveUpdateAdmission();
            this.idEditClick = true;
            this.toggleEdit(); // '수정' 버튼 클릭 시 편집 가능 상태 변경
          }
        }
        else{
          
          if(confirm(`${alertMsg} 하시겠습니까?`)) {
            this.$refs.enrollBottom.saveUpdateAdmission();
          }
        }
      } 
    },
    getGameInfo(){
      this.$store.dispatch('enroll/getGameInfo').then(res => {
        //console.log('getGameInfo', res, res.data.crowdArr.length);
        this.state = res.data.crowdArr.length > 0 ? 'update' : 'save';

        for(let i = 1; i <=this.sectionCn; i++){
          this.filterAndAssign(`${i}`, `sec${i}`, res);
        }

        this.gameInfo = res.data.gameInfoData;
        this.gameInfo.approvalCk = res.data.gameInfoData.approvalCk == 'True' ? true : false;
      });
    },
  }
}
</script>
