<template>
  <h4>{{ title }}</h4>
  <table class="tb type-row">
    <colgroup>
      <col width="200px" />
    </colgroup>
    <tr>
      <th>날짜</th>
      <td>
        <div class="calender">
          <VueDatePicker
            v-model="startDate"
            :clearable="false"
            :format="format"
            :max-date="endDate"
            locale="ko"
            auto-apply
            :enable-time-picker="false"
            year-first
            @update:model-value="selectCal('start')"
          >
            <template #input-icon>
              <img
                class="input-slot-image"
                src="@/assets/images/contents/icon_cal.png"
              />
            </template>
          </VueDatePicker>
        </div>
        -
        <div class="calender">
          <VueDatePicker
            v-model="endDate"
            :min-date="startDate"
            :clearable="false"
            :format="format"
            locale="ko"
            auto-apply
            :enable-time-picker="false"
            year-first
            @update:model-value="selectCal('end')"
          >
            <template #input-icon>
              <img
                class="input-slot-image"
                src="@/assets/images/contents/icon_cal.png"
              />
            </template>
          </VueDatePicker>
        </div>
      </td>
    </tr>

    <ManageRegistCount
      v-if="section == 'count'"
      ref="count"
    ></ManageRegistCount>
    <ManageRegistRate v-else ref="rate"></ManageRegistRate>
  </table>

  <div class="btn-wrap">
    <a
      class="btn save"
      href="#"
      @click.prevent="clickRegister(section, isModify)"
      >{{ isModify == false ? "등록" : "수정" }}</a
    >
    <a href="" @click.prevent="cancelRegist" class="btn cancel">취소</a>
    <a href="" @click.prevent="cancelRegist" class="btn list">목록</a>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import Common from "@/utils/common";

import ManageRegistCount from "@/components/Manage/ManageRegist/ManageRegistCount.vue";
import ManageRegistRate from "@/components/Manage/ManageRegist/ManageRegistRate.vue";

export default {
  name: "ManageRegistView",
  components: { VueDatePicker, ManageRegistCount, ManageRegistRate },
  data() {
    return {
      common: new Common(),

      section: this.$route.params.section,
      title: "",
      isModify: false,

      // datepicker
      startDate: "",
      endDate: "",
    };
  },
  mounted() {
    this.title =
      this.section == "count"
        ? "입장 인원 관리"
        : this.section == "rate"
        ? "입장 인원 분배"
        : "";
  },
  unmounted() {},
  watch: {
    "$store.state.manage.modifyList"(data) {
      // console.log(data);
      if (data) {
        this.startDate = new Date(data[0].S_DT);
        this.endDate = new Date(data[0].E_DT);
        this.isModify = true;
      }
    },
  },
  methods: {
    // 달력 format
    format(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      return `${year}.${month}.${day}`;
    },

    selectCal(section) {
      this.$store.commit("manage/setSelectedDate", {
        section: section,
        selectDate:
          section === "start"
            ? this.common.DateObjToString(this.startDate)
            : this.common.DateObjToString(this.endDate),
      });
    },
    // 취소 버튼 클릭
    cancelRegist() {
      this.$router.go(-1);
    },
    // 등록버튼 클릭
    clickRegister(section, isModify) {
      const msg = isModify == false ? "등록" : "수정";
      if (confirm(`${msg} 하시겠습니까?`)) {
        if (section === "count") {
          if (isModify) {
            this.$refs.count.modifyCount();
          } else {
            this.$refs.count.registerCount();
          }
        } else if (section === "rate") {
          if (isModify) {
            this.$refs.rate.modifyRate();
          } else {
            this.$refs.rate.registerRate();
          }
        }
      }
    },
  },
};
</script>
