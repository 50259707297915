<template>
  <div class="pagenation">
    <a
      class="prev"
      href=""
      @click.prevent="updateNowPageNum(Number(nowPageNum) - 1)"
    >
      <span>prev</span>
    </a>
    <span class="num">
      <ul>
        <li
          v-for="item in countInPages"
          :key="item"
          :class="{
            on: startPage - 1 + item == nowPageNum,
            one: countInPages == 1,
          }"
        >
          <a href="#" @click.prevent="updateNowPageNum(startPage - 1 + item)">{{
            startPage - 1 + item
          }}</a>
        </li>
      </ul>
    </span>
    <a
      class="next"
      href=""
      @click.prevent="updateNowPageNum(Number(nowPageNum) + 1)"
    >
      <span>next</span>
    </a>
  </div>
</template>
<script>
export default {
  name: "PagingView",
  data() {
    return {
      defaultPages: 5, // 한 페이지에 보이고 싶은 페이지 수
    };
  },
  computed: {
    nowPageNum() {
      return this.$route.query.page;
    },
    pageSize() {
      return (this.$route.name == "manage" || this.$route.name == "reference") ? 10 : 8; 
    },
    startPage() {
      let value =
        Math.floor((this.nowPageNum - 1) / this.defaultPages) *
          this.defaultPages +
        1;
      return value;
    },
    totalPage() {
      var count = this.pageSize ? this.pageSize : 10;
      return (
        Math.floor(this.$store.getters.totalCnt / count) +
        (this.$store.getters.totalCnt % count == 0 ? 0 : 1)
      );
    },
    countInPages() {
      let currPages =
        (this.totalPage == 0 ? 1 : this.totalPage) - (this.startPage - 1);
      //console.log('curr', currPages, 'default', this.defaultPages)
      if (currPages < this.defaultPages) {
        return currPages;
      } else {
        return this.defaultPages;
      }
    },
  },
  methods: {
    updateNowPageNum(index) {
      // 다음/이전 버튼 클릭 시, 1보다 작거나 전체 페이지 수보다 클 경우
      if (index < 1 || index > this.totalPage) {
        return;
      } else {
        this.$router.push({
          name: this.$route.name,
          section: this.$route.params.section,
          query: { page: index },
        });
      }
    },
  },
};
</script>
