import axios from "axios";

class CommonService {
  // 팀 리스트 가져오기
  async $getTeamList() {
    const result = axios.get("/api/common/teams");

    return await result;
  }

  // 쿠키 값을 읽기 위한 함수
  // getCookie('EIM_COOKIE_ID')로 사용
  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  // 방송사 리스트 가져오기
  async $getBroadcasts(){
    const result = axios.get('/api/common/broadcasts');
    return await result;
  }
}

export default CommonService;
