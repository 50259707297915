<template>
  <div class="search-wrap">
    <p class="tit">항목선택</p>
    <ul>
      <li :class="{on: paramData.main === 'month'}" @click="categoryClick">
        <router-link to="/reference/month/income">월별</router-link>
      </li>
      <li :class="{on: paramData.main === 'team'}" @click="categoryClick">
        <router-link to="/reference/team/seat">구단별</router-link>
      </li>
      <li :class="{on: paramData.main === 'day'}" @click="categoryClick">
        <router-link to="/reference/day/entrance">요일별</router-link>
      </li>
      <li :class="{on: paramData.main === 'total'}" @click="categoryClick">
        <router-link to="/reference/total/specification">구단 입장수입 명세서</router-link>
      </li> 
      <li :class="{on: paramData.main === 'soldout'}" @click="categoryClick">
        <router-link to="/reference/soldout/list?page=1">매진 리스트</router-link>
      </li>    
      <li :class="{on: paramData.main === 'totalCrowd'}" @click="categoryClick">
        <router-link to="/reference/totalCrowd/list?page=1">전체 입장수입 현황</router-link>
      </li>       
    </ul>

    <p v-if="paramData.main !== 'soldout' && paramData.main !== 'totalCrowd' && paramData.main === 'total'" class="tit">자료선택</p>
    <ul v-if="paramData.main == 'total'">
      <li v-for="item in arrReference" :key="item" :class="{on: $route.params.sub === item.sub}" @click="categoryClick">
        <router-link :to="`/reference/${paramData.main}/${item.sub}`">{{item.title}}</router-link>
      </li>
    </ul>

    <p v-if="paramData.main !== 'soldout' && paramData.main !== 'totalCrowd'" class="tit">{{paramData.main === 'total' ? '구단선택' : '자료선택'}}</p>
    <ul v-if="paramData.main !== 'total'">
      <li v-for="item in arrReference" :key="item" :class="{on: $route.params.sub === item.sub}" @click="categoryClick">
        <router-link :to="`/reference/${paramData.main}/${item.sub}`">{{item.title}}</router-link>
      </li>
    </ul>
    <select v-else v-model="teamId">
      <option :value="''" disabled>선택</option>
      <option v-for="item in this.$store.state.teamList" :key="item" :value="item.teamId">{{ item.teamName }}</option>
    </select>
    
    <p class="tit">{{paramData.main === 'soldout' ? '년도선택' : '날짜선택'}}</p>
    <select id="yearSelect" v-if="conditionSection === 1" v-model="selectedYear">
      <option v-for="year in years" :key="year">{{ year }}</option>
    </select>
    <select id="monthSelect" v-if="conditionSection === 1 && paramData.main !== 'soldout'" v-model="selectedMonth">
      <option v-for="month in months" :key="month" :value="month">{{ month }}월</option>
    </select>
    <div class="calender" v-if="conditionSection !== 1">
      <VueDatePicker v-model="firstSelectedDate"
                  :clearable="false"
                  :format="format"
                  :max-date="secondSelectedDate"
                  locale="ko"
                  auto-apply
                  :enable-time-picker="false"
                  year-first
                  >
        <template  #input-icon>
          <img class="input-slot-image" src="@/assets/images/contents/icon_cal.png"/>
        </template>
      </VueDatePicker>
    </div>
    {{conditionSection === 3 ? '-' : ''}}
    <div class="calender" v-if="conditionSection === 3">
      <VueDatePicker v-model="secondSelectedDate"
                  :clearable="false"
                  :format="format"
                  :min-date="firstSelectedDate"
                  locale="ko"
                  auto-apply
                  :enable-time-picker="false"
                  year-first
                  >
        <template  #input-icon>
          <img class="input-slot-image" src="@/assets/images/contents/icon_cal.png"/>
        </template>
      </VueDatePicker>
    </div>
    <a class="btn search" href="#none" @click="searchClick">검색</a>
  </div>
</template>
<script>

import VueDatePicker from '@vuepic/vue-datepicker';
import Common from "@/utils/common.js"


export default{ 
  name:'ReferenceSearchView',
  components:{VueDatePicker},
  data() {
    return{
      common: new Common(),
      firstSelectedDate: new Date(),   // 날짜
      secondSelectedDate: new Date(),   // 날짜
      selectedYear: new Date().getFullYear(),
      years: [],
      selectedMonth: new Date().getMonth() + 1,
      months: [],
      teamId: "",
    };
  },
  setup() {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });
  },
  mounted() {
    this.setYearOption();
    this.setMonthOption();
  },
  unmounted() {},
  watch: {
    '$route' (to, from) {
      if(to.fullPath !== from.fullPath){
        this.selectedYear = (new Date().getFullYear()).toString();
        this.selectedMonth = (new Date().getMonth() + 1).toString();

        if(to.params.main == from.params.main &&  to.params.sub == from.params.sub){
          this.firstSelectedDate = (this.compareDates("first") == false) ?  (new Date()).toString() : this.firstSelectedDate;
          this.secondSelectedDate = (this.compareDates("last") == false) ?  (new Date()).toString() : this.secondSelectedDate;
        }
        else{
          this.firstSelectedDate = (new Date()).toString();
          this.secondSelectedDate = (new Date()).toString();
        }
        
        this.teamId = "";
      }
    }
  },
  computed: {
    arrReference(){
      let arr = [];
      switch (this.paramData.main) {
        case 'month':
          arr = [{title: '입장수입 현황', sub: 'income'}];
          break;
        case 'team':
          arr = [
            {title: '좌석 점유율', sub: 'seat'},
            {title: '입장인원 현황', sub: 'count'},
            {title: '입장수입 현황', sub: 'income'},
            {title: '정산수입', sub: 'calculate'},
            {title: '대차 명세 집계표', sub: 'rental'},
            {title: '홈/원정 입장 현황', sub: 'homeaway'},
          ]
          break;
        case 'day':
          arr = [{title: '입장 현황', sub: 'entrance'}];
          break;
        case 'total':
          arr = [
              {title: '입장수입 명세서', sub: 'specification'},
              {title: '대차 상세 명세서', sub: 'rentalDetail'}
            ]
          break;
      }

      return arr;
    },

    paramData(){
      let objParam = {main : this.$route.params.main, sub : this.$route.params.sub};
      return objParam;
    },

    conditionSection(){
      let conditionSection = null;
      switch (this.paramData.main) {
        case 'month':
        case 'total':
          switch (this.paramData.sub) {
            case 'income':
            case 'specification':
              conditionSection = 1;       // 년, 월
              break;
            case 'rentalDetail':
              conditionSection = 3;   // 날짜 (시작, 끝)
              break;
            }
            break;
        case 'team':
          switch (this.paramData.sub) {
            case 'seat':
            case 'count':
            case 'income':
              conditionSection = 2;   // 날짜 (단일)
              break;
            case 'calculate':
            case 'rental':
            case 'homeaway':
              conditionSection = 3;   // 날짜 (시작, 끝)
              break;
          }
          break;
        case 'day':
          conditionSection = 3;
          break;
        case 'soldout':
          conditionSection = 1;
          break;
        case 'totalCrowd':
          conditionSection = 3;
      }
      return conditionSection;
    }
  },
  methods: {
    // 달력 format
    format(date){
      let arrDate = [];
      // 범위 선택인 경우
      if(date.length > 1) {
        date.forEach(element => {
          arrDate.push(element);
        });
      } else {
        arrDate.push(date);
      }
      
      let arrResult = [];
      arrDate.forEach(element => {
        let objDate = {};
        objDate.year = element.getFullYear();
        objDate.month = element.getMonth() + 1;
        objDate.day = element.getDate();

        // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
        if(objDate.month < 10){
          objDate.month = '0' + objDate.month;
        }

        if(objDate.day < 10){
          objDate.day = '0' + objDate.day;
        }
        // 각 날짜를 배열에 추가
        arrResult.push(`${objDate.year}.${objDate.month}.${objDate.day}`);
      });

      if(arrResult.length > 1) {
        return `${arrResult[0]} ~ ${arrResult[1]}`;
      } else {
        return arrResult[0];
      }
    },
    compareDates(section) {
      const selectedDate = (section == "first") ? new Date(this.firstSelectedDate) : new Date(this.secondSelectedDate);
      const today = new Date();
      
      // Set the time portion to 0 to compare only the dates
      selectedDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      // 선택한 날짜가 오늘 날짜라면 false 리턴
      if (selectedDate.getTime() === today.getTime()) {
        return false;
      }
        
    },
    setYearOption() {
      const currentYear = new Date().getFullYear();
      const startYear = 1982; 

      for (let year = currentYear; year >= startYear; year--) {
        this.years.push(year);
      }
    },

    setMonthOption() {
      for (let month = 1; month <= 12; month++) {
        this.months.push(month);
      }
    },

    searchClick(e){
      e.preventDefault()
      this.$store.commit('refer/setMainState',this.paramData.main)
      this.$store.commit('refer/setSubState',this.paramData.sub)
      
      // Date 객체로 변환
      const firstDate = new Date(this.firstSelectedDate);
      const secondDate = new Date(this.secondSelectedDate);
      // 검색 날짜 [년월일 / 년월] 분리
      if(this.paramData.main === "team" || this.paramData.main ==="day" || this.paramData.sub === "rentalDetail" || this.paramData.main === "totalCrowd"){
        const startYear = firstDate.getFullYear();
        const startMonth = (firstDate.getMonth() + 1).toString().padStart(2, "0"); 
        const startDay = (firstDate.getDate()).toString().padStart(2, "0");
        const endYear = secondDate.getFullYear();
        const endMonth = (secondDate.getMonth() + 1).toString().padStart(2, "0"); 
        const endDay = (secondDate.getDate()).toString().padStart(2, "0");
        // 공백 검색
        if(this.selectedDate === null){
          alert("날짜를 선택해주세요.");
          this.$store.commit('refer/setSubState', "");
        }else{
          this.$store.commit('refer/setFirstSelectDate', `${startYear}${startMonth}${startDay}`);
          this.$store.commit('refer/setSecondSelectDate', `${endYear}${endMonth}${endDay}`);
        }
      }else{

        if(this.selectedMonth < 10){
          this.$store.commit('refer/setMonthState', "0" + this.selectedMonth)
        }else{
          this.$store.commit('refer/setMonthState', this.selectedMonth)
        }
        this.$store.commit('refer/setYearState', this.selectedYear)
      }
      
      this.$store.commit('refer/setTeamState', this.teamId);
      this.$store.commit('refer/setIsReload', !this.$store.state.refer.isReload);

    },
    
    categoryClick(){
      this.$store.commit('refer/setMainState', "")
      this.$store.commit('refer/setSubState', "")
      this.$store.commit('refer/setTeamState', "");

      this.$store.commit('refer/setMonthState', "");
      this.$store.commit('refer/setYearState', "");
      this.$store.commit('refer/setFirstSelectDate', "");
    }
  }
}
</script>
