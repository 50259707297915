<!-- 구단별 // 입장인원 현황 -->
<template>
  <div class="report-tit"> 
    <p>구단별 입장인원 현황</p>
    <p class="term">( {{this.common.yyyyMMddDot($store.state.refer.firstSelectDate)}} 현재 )</p>
    <span class="txt-r">Date : {{this.common.yyyyMMddDot(this.common.DateObjToString(new Date()))}}</span>
  </div>
  <table class="tb-data">
    <colgroup>
      <col width="136px">
      <col width="63px"> 
    </colgroup>
    <tr>
      <th rowspan="2">구단</th>
      <th rowspan="2">경기수</th>
      <th colspan="2">{{parseInt(this.common.yyyyMMddDot($store.state.refer.firstSelectDate).substring(0, 4)) - 1 + '년'}}</th>
      <th colspan="4">{{parseInt(this.common.yyyyMMddDot($store.state.refer.firstSelectDate).substring(0, 4)) + '년'}}</th>
      <th colspan="2">평균유료인원</th>
    </tr>
    <tr>
      <th class="line">유료인원</th>
      <th>무료인원</th>

      <th>유료인원</th>
      <th>증감(%)</th>
      <th>무료인원</th>
      <th>증감(%)</th>

      <th>{{parseInt(this.common.yyyyMMddDot($store.state.refer.firstSelectDate).substring(0, 4)) - 1 + '년'}}</th>
      <th>{{parseInt(this.common.yyyyMMddDot($store.state.refer.firstSelectDate).substring(0, 4)) + '년'}}</th>
    </tr>
    <tr v-for="(item, index) in items" :key="index">
      <td class="txt-c">{{ getTeamFullName(item.tId) }}</td>
      <td>{{ item.nowTotalGCn }}</td>
      <td>{{ item.pastPayCn }}</td>
      <td>{{ item.pastFreeCn }}</td>
      <td>{{ item.nowPayCn }}</td>
      <td>{{ item.payRt }}</td>
      <td>{{ item.nowFreeCn }}</td>
      <td>{{ item.freeRt }}</td>
      <td>{{ item.pastAvgCn }}</td>
      <td>{{ item.nowAvgCn }}</td>
    </tr>
    <tr v-if="itemsResult" class="total">
      <td class="txt-c"><b>합계</b></td>
      <td>{{ itemsResult.nowTotalGCn }}</td>
      <td>{{ itemsResult.pastPayCn }}</td>
      <td>{{ itemsResult.pastFreeCn }}</td>
      <td>{{ itemsResult.nowPayCn }}</td>
      <td>{{ itemsResult.payRt }}</td>
      <td>{{ itemsResult.nowFreeCn }}</td>
      <td>{{ itemsResult.freeRt }}</td>
      <td>{{ itemsResult.pastAvgCn }}</td>     
      <td>{{ itemsResult.nowAvgCn }}</td>
    </tr>
  </table>
</template>
<style scoped src="@/assets/css/print.css"></style>

<script>
import Common from '@/utils/common';

export default{ 
  name:'TeamEntranceCount',
  components:{},
  data() {
    return{
      sampleData: '',
      common: new Common(),
      items: null,
      itemsResult: null,
    };
  },
  watch:{
    '$store.state.refer.isReload'(){
      this.getTeamEntranceCount();
    }
  },
  setup() {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });    
  },
  mounted() {
    this.getTeamEntranceCount();
  },
  unmounted() {},
  methods: {
    getTeamEntranceCount() {
            this.$store.dispatch("refer/getTeamEntranceCount").then(res => {
                    this.items = res.data.value.data.teamAdmissionCrowdList;
                    this.itemsResult = res.data.value.data.sumAdmissionCrowdList;
                });
    },
    getTeamFullName(teamId) {
      const teamArray = this.$store.state.teamList.find(
        (item) => item.teamId == teamId
      );
      return teamArray ? teamArray.teamFullName : "";
    },
  }
}
</script>
