import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

import LoginView from "../views/LoginView.vue";
import MainView from "../views/MainView.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/enroll",
    name: "enroll",
    component: MainView,
  },
  {
    path: "/alarm/:section",
    name: "alarm",
    component: MainView,
    beforeEnter: (to, from, next) => {
      if (store.state.auth.authId === "1") {
        next();
      } else {
        next("/enroll");
      }
    },
  },
  {
    path: "/reference/:main/:sub",
    name: "reference",
    component: MainView,
    beforeEnter: (to, from, next) => {
      if (store.state.auth.authId === "1") {
        next();
      } else {
        next("/enroll");
      }
    },
  },
  {
    path: "/manage/:section",
    name: "manage",
    component: MainView,
    beforeEnter: (to, from, next) => {
      if (store.state.auth.authId === "1") {
        next();
      } else {
        next("/enroll");
      }
    },
  },
  {
    path: "/manage/:section/register",
    name: "manageRegister",
    component: MainView,
    beforeEnter: (to, from, next) => {
      if (store.state.auth.authId === "1") {
        next();
      } else {
        next("/enroll");
      }
    },
  },
  {
    path: "/team/:section",
    name: "team",
    component: MainView,
    beforeEnter: (to, from, next) => {
      if (store.state.auth.authId === "2") {
        next();
      } else {
        next("/enroll");
      }
    },
  },
  {
    path: "/team/:section/register",
    name: "teamRegister",
    component: MainView,
    beforeEnter: (to, from, next) => {
      if (store.state.auth.authId === "2") {
        next();
      } else {
        next("/enroll");
      }
    },
  },
  {
    path: "/Reference/PrintReferenceDetail",
    name: "PrintReferenceDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Reference/PrintReferenceDetail.vue"
      ),
  },
  {
    path: "/Reference/ReferenceDetail/MonthEntranceIncome.vue",
    name: "MonthEntranceIncome",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Reference/ReferenceDetail/MonthEntranceIncome.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  try {
    await store.dispatch("getUserSession");
    next();
  } catch (error) {
    // 에러 처리
    console.error("getUserSession 에러:", error);
  }
});

export default router;
