import axios from 'axios';
import store from '@/store';

class EnrollService {
  // 팀 목록 가져오기
  async $getTeamList(leId, srId, month, season, teamId) {
    const result = await axios.get('/api/enroll/teams', {
      params: {
        leId: leId,
        srIdList: srId,
        month: month,
        season: season,
        teamId: teamId,
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    if(result.data.teamList.length > 0) {
      store.commit('enroll/setSelectedTeam', result.data.teamList[0].teamId);
    }
    return  result;
  }

  // 경기 목록 가져오기
  async $getGameList(leId, srId, month, season, teamId) {
    const result = axios.get('/api/enroll/daily-list', {
      params: {
        leId: leId,
        srIdList: srId,
        month: month,
        season: season,
        teamId: teamId,
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  // 선택 월 합계 가져오기
  async $getMonthTotal(leId, srId, month, season, teamId){
    const result = axios.get('/api/enroll/month-income', {
      params: {
        leId: leId,
        srIdList: srId,
        month: month,
        season: season,
        teamId: teamId,
      }
    });
    return await result;
  }

  // 경기 정보 가져오기
  async $getGameInfo(leId, srId, gId, season, teamId){
    const result = axios.get('/api/enroll/gameinfo', {
      params: {
        leId: leId,
        srIdList: srId,
        gId: gId,
        season: season,
        teamId: teamId,
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  // 입장 수입 저장하기
  async $insertAdmission(udtAdmission, alarmId, leId, srId, gId, season, teamId){
    console.log(srId);
    const formData = new FormData();
    formData.append("udt_admission_manage", udtAdmission);
    formData.append("alarmId", alarmId);
    formData.append("leId", leId);
    formData.append("srId", srId);
    formData.append("gId", gId);
    formData.append("seasonId", season);
    formData.append("teamId", teamId);
    const result = axios.post('/api/enroll/admissions', formData).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });

    return await result;
  }

  // 입장 수입 수정하기
  async $updateAdmission(udtAdmission, udtAlarmUpdate, alarmId, leId, srId, gId, season, teamId){
    console.log(srId);
    const formData = new FormData();
    formData.append("udt_admission_manage", udtAdmission);
    formData.append("udt_alarm_update", udtAlarmUpdate);
    formData.append("alarmId", alarmId);
    formData.append("leId", leId);
    formData.append("srId", srId);
    formData.append("gId", gId);
    formData.append("seasonId", season);
    formData.append("teamId", teamId);
    const result = axios.put('/api/enroll/admissions', formData).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
   
    return await result;
  }

    // 입장 수입 매진 업데이트
    async $updateSoldout(gId, gameCn, soldoutCk, soldoutDt){
      const formData = new FormData();
      formData.append("gId", gId);
      formData.append("gameCn", gameCn);
      formData.append("soldoutCk", soldoutCk);
      formData.append("soldoutDate", soldoutDt);
      const result = axios.put('/api/enroll/soldout', formData).catch(err => {
        if(err.response.status === 401){
          alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
          window.location.href = window.location.origin;
        }
      });
  
      return await result;
    }

  // 입장 수입 삭제하기
  async $deleteAdmission(leId, srId, seasonId, teamId, alarmId, gId){
    const result = axios.delete('/api/enroll/daily-list', {
      params: {
        leId, srIdList : srId, seasonId, teamId, alarmId, gId
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  // 입장 수입 승인하기
  async $approveAdmission(leId, srId, seasonId, teamId, alarmId, gId){
    const formData = new FormData();
    formData.append("leId", leId);
    formData.append("srId", srId);
    formData.append("seasonId", seasonId);
    formData.append("teamId", teamId);
    formData.append("alarmId", alarmId);
    formData.append("gId", gId);
    const result = axios.put('/api/enroll/daily-list', formData).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });

    return await result;
  }

  // KBO 관리자 관중수 UPDATE API 호출
  async $updateKboCrowd(leId, srId, seasonId, gId, crowdCn){
    const formData = new FormData();
    formData.append("leId", leId);
    formData.append("srId", srId);
    formData.append("seasonId", seasonId);
    formData.append("gId", gId);
    formData.append("crowdCn", crowdCn);
    const result = axios.post('/api/enroll/callApi', formData).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  // 알림 코드
  getAlarmCd(section) {
    let alarmCd = '';
    switch (section) {
      case 'save':
        alarmCd = 1;
        break;
      case 'update':
        alarmCd = 2;
        break;
      case 'delete':
        alarmCd = 3;
        break;
      case 'approve':
        alarmCd = 4;
        break;
    }
    return alarmCd;
  }
}

export default EnrollService;