import Vuex from "vuex";
import CommonService from "@/utils/service/commonService";
import MemberService from "@/utils/service/memberService";
const commonService = new CommonService();
const memberService = new MemberService();
// 작성한 모듈을 가져옵니다.
import enrollStore from "@/store/modules/enrollStore.js";
import manageStore from "@/store/modules/manageStore.js";
import referenceStore from "@/store/modules/referenceStore.js";
import alarmStore from "./modules/alarmStore";

const store = new Vuex.Store({
  modules: {
    // 키: 값 형태로 저장됩니다.
    enroll: enrollStore,
    manage: manageStore,
    refer: referenceStore,
    alarm: alarmStore,
  },
  state: {
    //auth: {},
    auth: { authId: "", teamId: "", userSe: "" },
    teamList: [],
    totalCnt: 0,
  },

  getters: {
    totalCnt(state) {
      return state.totalCnt;
    },
  },

  actions: {
    // 팀 리스트 가져오기
    getTeamList() {
      return commonService.$getTeamList();
      // context.commit("setTeamList", response.data.teamList);
    },
    // 유저 세션 가져오기
    getUserSession() {
      return memberService.$getUserSession();
    },
  },

  mutations: {
    // 유저 권한 확인
    setUserAuth: function (state, auth) {
      return (state.auth = auth);
    },
    setTeamList(state, payload) {
      state.teamList = payload;
    },
    setTotalCnt(state, payload) {
      state.totalCnt = payload.totalCnt;
    },
  },
});

export default store;
