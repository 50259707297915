<template>
  <tr>
    <th>리그</th>
    <td>
      <input type="text" style="width: 104px" disabled value="KBO 리그" />
    </td>
  </tr>
  <tr>
    <th>홈팀 수익(%)</th>
    <td>
      <input type="number" v-model="homeRate" @input="updateHomeRate" />
    </td>
  </tr>
  <tr>
    <th>원정팀 수익(%)</th>
    <td>
      <input type="number" v-model="awayRate" @input="updateAwayRate" />
    </td>
  </tr>
</template>
<script>
import ManageService from "@/utils/service/manageService";

export default {
  name: "ManageRegistRate",
  components: {},
  data() {
    return {
      manageService: new ManageService(),

      homeRate: "",
      awayRate: "",

      beforeData: {},
    };
  },
  watch: {
    homeRate(newValue) {
      if (newValue === "") {
        this.awayRate = "";
      }

      if (newValue > 100) {
        this.homeRate = 100;
      }
    },
    awayRate(newValue) {
      if (newValue === "") {
        this.homeRate = "";
      }

      if (newValue > 100) {
        this.awayRate = 100;
      }
    },
  },
  mounted() {
    this.getModifyRateInfo();
  },
  unmounted() {},
  computed: {
    startDate() {
      return this.$store.state.manage.selectedDate.startDate;
    },
    endDate() {
      return this.$store.state.manage.selectedDate.endDate;
    },
  },
  methods: {
    // 입장 인원 분배 비율 수정 정보
    async getModifyRateInfo() {
      const seasonId = this.$route.query.seasonId;
      const orderNo = this.$route.query.orderNo;

      if (seasonId && orderNo) {
        await this.$store
          .dispatch("manage/getModifyRateInfo", { seasonId, orderNo })
          .then((res) => {
            if (res.data.code == 200) {
              if (res.data.rateList.length > 0) {
                this.$store.commit("manage/setModifyList", res.data.rateList);
                this.$store.commit("manage/setSelectedDate", {
                  section: "start",
                  selectDate: res.data.rateList[0].S_DT,
                });
                this.$store.commit("manage/setSelectedDate", {
                  section: "end",
                  selectDate: res.data.rateList[0].E_DT,
                });

                this.beforeData = res.data.rateList[0];
                this.homeRate = this.beforeData.HOME_RT;
                this.awayRate = this.beforeData.AWAY_RT;
              }
            }
          });
      }
    },
    updateHomeRate() {
      if (this.homeRate != "") {
        this.awayRate = 100 - Number(this.homeRate);
      }
    },
    updateAwayRate() {
      if (this.awayRate != "") {
        this.homeRate = 100 - Number(this.awayRate);
      }
    },
    // 입장 인원 분배 비율 등록
    registerRate() {
      //console.log(this.startDate, this.endDate, this.homeRate, this.awayRate)
      this.manageService
        .$registerRate(
          this.startDate,
          this.endDate,
          this.homeRate,
          this.awayRate
        )
        .then((res) => {
          if (res.data.code == 201) {
            alert("등록 되었습니다.");
            this.$router.push("/manage/rate?page=1");
          }
        });
    },

    // 입장 인원 분배 비율 수정
    modifyRate() {
      //console.log(this.beforeData.LE_ID, this.beforeData.SEASON_ID, this.beforeData.ORDER_NO, this.startDate, this.endDate, this.homeRate, this.awayRate)
      this.manageService
        .$putModifyRateInfo(
          this.beforeData.LE_ID,
          this.beforeData.SEASON_ID,
          this.beforeData.ORDER_NO,
          this.startDate,
          this.endDate,
          this.homeRate,
          this.awayRate
        )
        .then((res) => {
          if (res.data.code == 200) {
            alert("수정 되었습니다.");
            this.$router.go(-1);
          }
        });
    },
  },
};
</script>
