import ReferenceService from "@/utils/service/referenceService";
const referenceService = new ReferenceService();

const referenceStore = {
  namespaced: true,
  state: {
    mainSearch: "",
    subSearch: "",
    year: "",
    month: "",
    firstSelectDate: "",
    secondSelectDate: "",
    teamId: "",
    isReload: false,
    isPrint: false,
    isDownload: false,
    isLoading: false,
  },
  getters: {},
  mutations: {
    // 자료실 > 검색 조건 설정
    setMainState(state, payload) {
      state.mainSearch = payload;
    },
    setSubState(state, payload) {
      state.subSearch = payload;
    },
    setYearState(state, payload) {
      state.year = payload;
    },
    setMonthState(state, payload) {
      state.month = payload;
    },
    setFirstSelectDate(state, payload) {
      state.firstSelectDate = payload;
    },
    setSecondSelectDate(state, payload) {
      state.secondSelectDate = payload;
    },
    setTeamState(state, payload) {
      state.teamId = payload;
    },
    setIsReload(state, payload) {
      state.isReload = payload;
    },
    setIsPrint(state, payload) {
      state.isPrint = payload;
    },
    setIsDownload(state, payload) {
      state.isDownload = payload;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    getMonthEntranceIncome(context) {
      return referenceService.$getMonthEntranceIncome(
        context.state.year,
        context.state.month
      );
    },

    getTeamSeatOccupancy(context) {
      const year = context.state.firstSelectDate.slice(0, 4);
      const month = context.state.firstSelectDate.slice(4, 6);
      const day = context.state.firstSelectDate.slice(6, 8);

      const date = `${year}-${month}-${day}`;
      return referenceService.$getTeamSeatOccupancy(date);
    },

    getTeamEntranceCount(context) {
      const year = context.state.firstSelectDate.slice(0, 4);
      const month = context.state.firstSelectDate.slice(4, 6);
      const day = context.state.firstSelectDate.slice(6, 8);

      const date = `${year}-${month}-${day}`;
      return referenceService.$getTeamEntranceCount(date);
    },

    getTeamEntranceIncome(context) {
      const year = context.state.firstSelectDate.slice(0, 4);
      const month = context.state.firstSelectDate.slice(4, 6);
      const day = context.state.firstSelectDate.slice(6, 8);

      const date = `${year}-${month}-${day}`;
      return referenceService.$getTeamEntranceIncome(date);
    },

    getTeamCalculateIncome(context) {
      const startyear = context.state.firstSelectDate.slice(0, 4);
      const startmonth = context.state.firstSelectDate.slice(4, 6);
      const startday = context.state.firstSelectDate.slice(6, 8);

      const endyear = context.state.secondSelectDate.slice(0, 4);
      const endmonth = context.state.secondSelectDate.slice(4, 6);
      const endday = context.state.secondSelectDate.slice(6, 8);

      const startDate = `${startyear}-${startmonth}-${startday}`;
      const endDate = `${endyear}-${endmonth}-${endday}`;
      return referenceService.$getTeamCalculateIncome(startDate, endDate);
    },

    getTeamRental(context) {
      const startyear = context.state.firstSelectDate.slice(0, 4);
      const startmonth = context.state.firstSelectDate.slice(4, 6);
      const startday = context.state.firstSelectDate.slice(6, 8);

      const endyear = context.state.secondSelectDate.slice(0, 4);
      const endmonth = context.state.secondSelectDate.slice(4, 6);
      const endday = context.state.secondSelectDate.slice(6, 8);

      const startDate = `${startyear}-${startmonth}-${startday}`;
      const endDate = `${endyear}-${endmonth}-${endday}`;
      return referenceService.$getTeamRental(startDate, endDate);
    },

    getTeamRentalDetail(context) {
      const startyear = context.state.firstSelectDate.slice(0, 4);
      const startmonth = context.state.firstSelectDate.slice(4, 6);
      const startday = context.state.firstSelectDate.slice(6, 8);

      const endyear = context.state.secondSelectDate.slice(0, 4);
      const endmonth = context.state.secondSelectDate.slice(4, 6);
      const endday = context.state.secondSelectDate.slice(6, 8);

      const startDate = `${startyear}-${startmonth}-${startday}`;
      const endDate = `${endyear}-${endmonth}-${endday}`;
      const teamId = context.state.teamId;
      return referenceService.$getTeamRentalDetail(startDate, endDate, teamId);
    },

    getTeamHomeAway(context) {
      const startyear = context.state.firstSelectDate.slice(0, 4);
      const startmonth = context.state.firstSelectDate.slice(4, 6);
      const startday = context.state.firstSelectDate.slice(6, 8);

      const endyear = context.state.secondSelectDate.slice(0, 4);
      const endmonth = context.state.secondSelectDate.slice(4, 6);
      const endday = context.state.secondSelectDate.slice(6, 8);

      const startDate = `${startyear}-${startmonth}-${startday}`;
      const endDate = `${endyear}-${endmonth}-${endday}`;
      return referenceService.$getTeamHomeAway(startDate, endDate);
    },

    getDayWeekEntrance(context) {
      const startyear = context.state.firstSelectDate.slice(0, 4);
      const startmonth = context.state.firstSelectDate.slice(4, 6);
      const startday = context.state.firstSelectDate.slice(6, 8);

      const endyear = context.state.secondSelectDate.slice(0, 4);
      const endmonth = context.state.secondSelectDate.slice(4, 6);
      const endday = context.state.secondSelectDate.slice(6, 8);

      const startDate = `${startyear}-${startmonth}-${startday}`;
      const endDate = `${endyear}-${endmonth}-${endday}`;
      return referenceService.$getDayWeekEntrance(startDate, endDate);
    },

    getTotalSpecification(context) {
      return referenceService.$getTotalSpecification(
        context.state.year,
        context.state.month,
        context.state.teamId
      );
    },

    getSoldOutList(context, payload) {
      return referenceService.$getSoldOutList(
        context.state.year,
        payload.nowPageNum,
        payload.pageSize
      );
    },

    uploadIncomeFile(context, payload) {
      return referenceService.$uploadIncomeFile(payload.file, payload.filePath);
    },

    updateGameCrowd(context, payload) {
      let month = context.state.firstSelectDate.substring(4, 6);
      let day = context.state.firstSelectDate.substring(6, 8);
      let shortYear = context.state.firstSelectDate.substring(2, 4);

      return referenceService.$updateGameCrowd(
        6,
        `관중(${shortYear}.${month}.${day})`,
        `pressbox/files/${payload}${context.state.firstSelectDate}-구장별입장수익현황.XLS`
      );
    },

    getTotalCrowd(context, payload) {
      const startyear = context.state.firstSelectDate.slice(0, 4);
      const startmonth = context.state.firstSelectDate.slice(4, 6);
      const startday = context.state.firstSelectDate.slice(6, 8);

      const endyear = context.state.secondSelectDate.slice(0, 4);
      const endmonth = context.state.secondSelectDate.slice(4, 6);
      const endday = context.state.secondSelectDate.slice(6, 8);

      const startDate = `${startyear}-${startmonth}-${startday}`;
      const endDate = `${endyear}-${endmonth}-${endday}`;

      return referenceService.$getTotalCrowd(startDate, endDate, payload.nowPageNum,
        payload.pageSize);
    },

    getCategory(context) {
      return `${context.state.mainSearch}_${context.state.subSearch}`;
    },

    getFirstDateSelect(context) {
      return context.state.firstSelectDate;
    },

    getIsDownload(context) {
      return context.state.isDownload;
    },
    getIsLoading(context) {
      return context.state.isLoading;
    },
  },
};

export default referenceStore;
