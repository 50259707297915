<!-- 구단별 // 홈원정 입장 현황 -->
<template>
  <div class="report-tit"> 
    <p>HOME vs AWAY 입장 현황</p>
    <p class="term">( {{this.common.yyyyMMddDot($store.state.refer.firstSelectDate)}} 부터 ) ( {{this.common.yyyyMMddDot($store.state.refer.secondSelectDate)}} 까지 )</p>
    <span class="txt-l">[인원/(평균):금액(평균)]</span>
    <span class="txt-r">Date : {{this.common.yyyyMMddDot(this.common.DateObjToString(new Date()))}}</span>
  </div>
  <table class="tb-data short">
    <colgroup>
      <col width="">
    </colgroup>
    <tr>
      <th>홈/원정</th>
      <th v-for="(item, index) in itemsTeamColumn" :key="index">{{item.tNm}}</th>
      <th>팀 계</th>
    </tr>
    <template v-for="(item, index) in items" :key="index">
      <tr>
        <td class="txt-c" rowspan="2">{{getTeamFullName(item.tId)}}</td>
        <td v-for="(itemData, childIndex) in item.tData" :key="childIndex">
          {{itemData.crowdNo}}
          <div v-if="itemData.crowdAvgNo !== ''" class="avg">{{itemData.crowdAvgNo}}</div>
        </td>
        <td>
          {{item.sumData.crowdNo}}
          <div v-if="item.sumData.crowdAvgNo !== ''" class="avg">{{item.sumData.crowdAvgNo}}</div>
        </td>
      </tr>
      <tr>
        <td v-for="(itemData, childIndex) in item.tData" :key="childIndex" class="border">
          {{itemData.incomeMo}}
          <div v-if="itemData.incomeAvgMo !== ''" class="avg">{{itemData.incomeAvgMo}}</div>
        </td>
        <td>
          {{item.sumData.incomeMo}}
          <div v-if="item.sumData.incomeAvgMo !== ''" class="avg">{{item.sumData.incomeAvgMo}}</div>
        </td>
      </tr>
    </template>
    <tr class="total">
      <td class="txt-c" rowspan="2">합계</td>
      <td  v-for="(item, index) in itemsResult" :key="index">
        {{item.colSumCrowdNo}}
        <div v-if="item.colSumCrowdAvgNo !== ''" class="avg">{{item.colSumCrowdAvgNo}}</div>
      </td>
      <td>
        {{ totalColSumCrowdNo.toLocaleString() }}
        <div v-if="totalColSumCrowdAvgNo !== ''" class="avg">{{ totalColSumCrowdAvgNo.toLocaleString() }}</div>
      </td>
    </tr>
    <tr class="total">
      <td v-for="(item, index) in itemsResult" :key="index" class="border">
        {{item.colSumIncomeMo}}
        <div v-if="item.colSumIncomeAvgMo !== ''" class="avg">{{item.colSumIncomeAvgMo}}</div>
      </td>
      <td>
        {{ totalColSumIncomeMo.toLocaleString() }}
        <div v-if="totalColSumIncomeAvgMo !== ''" class="avg">{{ totalColSumIncomeAvgMo.toLocaleString() }}</div>
      </td>
    </tr>
  </table>
</template>
<style scoped src="@/assets/css/print.css"></style>

<script>
import Common from '@/utils/common';

export default{ 
  name:'TeamHomeAway',
  components:{},
  data() {
    return{
      sampleData: '',
      common: new Common(),
      items: null,
      itemsResult: null,
      itemsTeamColumn: null,
    };
  },
  computed:{
    totalColSumCrowdNo(){
      if (!this.itemsResult) {
      return 0;
      }
      return this.itemsResult.reduce((sum, item) => {
        const colSumCrowdNo = item.colSumCrowdNo;
        if (colSumCrowdNo !== null && colSumCrowdNo !== undefined) {
          const parsedValue = colSumCrowdNo.trim() !== "" ? parseInt(colSumCrowdNo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);
    },

    totalColSumCrowdAvgNo(){
      if (!this.itemsResult) {
      return 0;
      }
      const totalSum = this.itemsResult.reduce((sum, item) => {
        const colSumCrowdNo = item.colSumCrowdNo;
        if (colSumCrowdNo !== null && colSumCrowdNo !== undefined) {
          const parsedValue = colSumCrowdNo.trim() !== "" ? parseInt(colSumCrowdNo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);

      const totalGameNo = this.itemsResult.reduce((sum, item) => {
        const colSumGameNo = item.colSumGameNo;
        if (colSumGameNo !== null && colSumGameNo !== undefined) {
          const parsedValue = colSumGameNo.trim() !== "" ? parseInt(colSumGameNo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);

      return totalGameNo !== 0 ? Math.round(totalSum / totalGameNo) : 0;
    },

    totalColSumIncomeMo(){
      if (!this.itemsResult) {
      return 0;
      }
      return this.itemsResult.reduce((sum, item) => {
        const colSumIncomeMo = item.colSumIncomeMo;
        if (colSumIncomeMo !== null && colSumIncomeMo !== undefined) {
          const parsedValue = colSumIncomeMo.trim() !== "" ? parseInt(colSumIncomeMo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);
    },

    totalColSumIncomeAvgMo(){
      if (!this.itemsResult) {
      return 0;
      }
      const totalSum = this.itemsResult.reduce((sum, item) => {
        const colSumIncomeMo = item.colSumIncomeMo;
        if (colSumIncomeMo !== null && colSumIncomeMo !== undefined) {
          const parsedValue = colSumIncomeMo.trim() !== "" ? parseInt(colSumIncomeMo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);

      const totalGameNo = this.itemsResult.reduce((sum, item) => {
        const colSumGameNo = item.colSumGameNo;
        if (colSumGameNo !== null && colSumGameNo !== undefined) {
          const parsedValue = colSumGameNo.trim() !== "" ? parseInt(colSumGameNo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);

      return totalGameNo !== 0 ? Math.round(totalSum / totalGameNo) : 0;
    },
  },
  watch:{
    '$store.state.refer.isReload'(){
      this.getTeamHomeAway();
    }
  },
  setup() {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });    
  },
  mounted() {
    this.getTeamHomeAway()
  },
  unmounted() {},
  methods: {
        getTeamHomeAway() {
            this.$store.dispatch("refer/getTeamHomeAway").then(res => {
                    this.items = res.data.value.data.homeawayDataList || [];
                    this.itemsResult = res.data.value.data.homeawaySumDataList || [];
                    this.itemsTeamColumn = res.data.value.data.homeawayDataList?this.items[0].tData : [];   
                });
    }, 
    getTeamFullName(teamId) {
      const teamArray = this.$store.state.teamList.find(
        (item) => item.teamId == teamId
      );
      return teamArray ? teamArray.teamFullName : "";
    },   
  }
}
</script>
