<!-- 매진리스트 -->
<template>
  <div class="report-tit">
    <p class="only-txt">{{$store.state.refer.year}}년 매진 리스트</p>
    <span class="txt-r">&nbsp;</span>
  </div>
	<table class="tb">
    <tr>
      <th>구분</th>
      <th>날짜</th>
      <th>홈</th>
      <th>원정</th>
      <th>DH</th>
      <th>구장</th>
      <th>차전</th>
      <th>관중 수</th>
      <th>매진 시간</th>
    </tr>
    <tr v-for="(item, index) in items" :key="index" >
      <td class="txt-c">{{item.rowNo}}</td>
      <td>{{formatDate(item.gId)}}</td>
      <td>{{item.homeNm}}</td>
      <td>{{item.awayNm}}</td>
      <td>{{setDoubleHeaderText(item.headerNo)}}</td>
      <td>{{item.sNm}}</td>
      <td>{{item.gCn}}</td>
       <td>{{item.crowdCn}}</td>
      <td>{{item.soldoutDt}}</td>
    </tr>
  </table>
  <div id="hidden-result" class="report-wrap hidden-table">
    <div class="report-tit">
    <p class="only-txt">{{$store.state.refer.year}}년 매진 리스트</p>
    <span class="txt-r">&nbsp;</span>
  </div>
	<table class="tb">
    <tr>
      <th>구분</th>
      <th>날짜</th>
      <th>홈</th>
      <th>원정</th>
      <th>DH</th>
      <th>구장</th>
      <th>차전</th>
      <th>관중 수</th>
      <th>매진 시간</th>
    </tr>
    <tr v-for="(item, index) in hiddenItems" :key="index" >
      <td class="txt-c">{{item.rowNo}}</td>
      <td>{{formatDate(item.gId)}}</td>
      <td>{{item.homeNm}}</td>
      <td>{{item.awayNm}}</td>
      <td>{{setDoubleHeaderText(item.headerNo)}}</td>
      <td>{{item.sNm}}</td>
      <td>{{item.gCn}}</td>
       <td>{{item.crowdCn}}</td>
      <td>{{item.soldoutDt}}</td>
    </tr>
  </table>
  </div>
</template>
<style scoped src="@/assets/css/print.css"></style>

<script>
export default{ 
  name:'SoldOutList',
  components:{},
  data() {
    return{
      sampleData: '',
      hiddenItems: [],
      items: null,
      pageSize: 10,
    };
  },
  setup() {},
  watch:{
    '$store.state.refer.isReload'(){
      this.getSoldOutList();
    },  
    getNowPageNum: {
      handler: "getSoldOutList",
      deep: true,
    },
  },
  computed:{
    getNowPageNum() {
      return this.$route.query.page;
    },
  },
  mounted() {
    this.getSoldOutList();
  },
  unmounted() {},
  methods: {
    getSoldOutList() {
      this.$store.dispatch("refer/getSoldOutList", {
        nowPageNum: this.getNowPageNum,
        pageSize: this.pageSize,
      }).then(res => {
        this.items = res.data.value.data.soldoutList;
        this.$store.commit("setTotalCnt", {
          totalCnt: Number(res.data.value.data.pageTotalCn),
        });
      }),
      this.$store.dispatch("refer/getSoldOutList", {
        nowPageNum: 0,
        pageSize: 0
      }).then(res => {
        this.hiddenItems = res.data.value.data.soldoutList || [];
      });
    },
    formatDate(gId) {
      const datePart = gId.substring(0, 8);
      return `${datePart.substring(0, 4)}.${datePart.substring(4, 6)}.${datePart.substring(6, 8)}`;
    },
    setDoubleHeaderText(dHeader) {
      if(dHeader != "0"){
        return `DH${dHeader}`;
      }
    }
  }
}
</script>
