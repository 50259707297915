<template>
  <div class="sub-search-wrap">
    <select v-model="selectedYear">
      <option v-for="item in years" :value="item" :key="item">
        {{ item }}
      </option>
    </select>
    <a class="btn search" href="#" @click.prevent="filterBySearch">검색</a>
    <a class="" href="#" @click.prevent="clickAdd(this.$route.params.section)">
      <img src="@/assets/images/contents/btn_add.png" alt="add" />
    </a>
  </div>
</template>
<script>
export default {
  name: "TeamManageSearchView",
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = 1982;
    const years = Array.from(
      { length: currentYear + 1 - startYear + 1 },
      (_, index) => startYear + index
    );

    return {
      teamId: "",
      years,
      selectedYear: currentYear, // 기본 선택 연도
    };
  },
  computed: {
    resetSelect() {
      return this.$route.params.section;
    },
  },
  watch: {
    resetSelect(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.teamId = "";
        this.selectedYear = new Date().getFullYear();
      }
    },
  },
  methods: {
    clickAdd(section) {
      this.$router.push(`/team/${section}/register`);
    },
    async filterBySearch() {
      await this.$store.commit("manage/setSeasonId", this.selectedYear);

      this.$router.push({ query: { page: 1 } });
    },
  },
};
</script>
