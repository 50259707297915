<!-- 구단별 // 대차 명세 집계표 -->
<template >
  <div class="report-tit"> 
    <p>구단 대차 상세 명세서</p>
    <p class="term">( {{this.common.yyyyMMddDot($store.state.refer.firstSelectDate)}} 부터 ) ( {{this.common.yyyyMMddDot($store.state.refer.secondSelectDate)}} 까지 )</p>
    <span class="txt-l">구단명 : {{getTeamFullName($store.state.refer.teamId)}}</span>
    <span class="txt-r">Date : {{this.common.yyyyMMddDot(this.common.DateObjToString(new Date()))}}</span>
  </div>
  <table class="tb-data font-s-s">
    <colgroup>
      <col width="85px">
    </colgroup>
    <tr>
      <th rowspan="2">경기수</th>
      <th rowspan="2">경기일자</th>
      <th rowspan="2">대진팀</th>
      <th rowspan="2">분배기준금액</th>
      <th rowspan="2">홈구단수입 (72%)</th>
      <th colspan="10">원 정 구 단 수 입 (28%)</th>
    </tr>
    <tr>
      <th>한 화</th>
      <th>L G</th>
      <th>K I A</th>
      <th>K T</th>
      <th>롯 데</th>
      <th>N C</th>
      <th>두 산</th>
      <th>S S G</th>
      <th>삼 성</th>
      <th>키 움</th>
    </tr>
    
    <tr v-for="item in items" :key="item.gameId">
        <td class="txt-c">{{ item.rowNum }}</td>
        <td class="txt-c">{{ setDoubleHeaderText(item.gameDate, item.dHeader) }}</td>
        <td class="txt-c">{{ getTeamFullName(item.awayTeamId) }}</td>
        <td class="txt-c">{{ item.crowdTotal }}</td>
        <td class="txt-c">{{ item.crowdHome }}</td>
        <td class="txt-c">{{ item.HH }}</td>
        <td class="txt-c">{{ item.LG }}</td>
        <td class="txt-c">{{ item.HT }}</td>
        <td class="txt-c">{{ item.KT }}</td>
        <td class="txt-c">{{ item.LT }}</td>
        <td class="txt-c">{{ item.NC }}</td>
        <td class="txt-c">{{ item.OB }}</td>
        <td class="txt-c">{{ item.SK }}</td>
        <td class="txt-c">{{ item.SS }}</td> 
        <td class="txt-c">{{ item.WO }}</td> 
      </tr>
    <tr v-for="item in itemsResult" :key="item" class="total ">
      <td class="txt-c"><b>합계</b></td>
      <td></td>
      <td></td>
      <td class="txt-c">{{ itemsResult[0].crowdTotal }}</td>
      <td class="txt-c">{{ itemsResult[0].crowdHome }}</td> 
      <td class="txt-c">{{ itemsResult[0].HH }}</td>
      <td class="txt-c">{{ itemsResult[0].LG }}</td>
      <td class="txt-c">{{ itemsResult[0].HT }}</td>
      <td class="txt-c">{{ itemsResult[0].KT }}</td>
      <td class="txt-c">{{ itemsResult[0].LT }}</td>
      <td class="txt-c">{{ itemsResult[0].NC }}</td>
      <td class="txt-c">{{ itemsResult[0].OB }}</td>
      <td class="txt-c">{{ itemsResult[0].SK }}</td>
      <td class="txt-c">{{ itemsResult[0].SS }}</td>
      <td class="txt-c">{{ itemsResult[0].WO }}</td> 
    </tr>
  </table>
</template>
<style scoped src="@/assets/css/print.css"></style>

<script>
import Common from '@/utils/common';

export default{ 
  name:'TotalRentalDetail',
  components:{},
  data() {
    return{
      sampleData: '',
      common: new Common(),
      items: [],
      itemsResult: [],
      itemsTeamColumn: [],
    };
  },
  watch:{
    '$store.state.refer.isReload'(){
      this.getTeamRentalDetail();
    }
  },
  setup() {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });   
  },
  mounted() {
    this.getTeamRentalDetail();
  },
  unmounted() {},
  methods: {
    getTeamRentalDetail() {
        this.$store.dispatch("refer/getTeamRentalDetail").then(res => {
                  this.items = res.data.value.data.teamDataList || [];
                  this.itemsResult = res.data.value.data.teamSumList || [];
            });
    },
    getTeamFullName(teamId) {
      const teamArray = this.$store.state.teamList.find(
        (item) => item.teamId == teamId
      );
      return teamArray ? teamArray.teamFullName : "";
    },
    setDoubleHeaderText(gameDate, dHeader) {
      if(dHeader !== "0"){
        return `${gameDate} (DH${dHeader})`;
      }
      else{
        return `${gameDate}`;
      }
    },
  }
}
</script>
