<template>
  <table class="tb">
    <TeamManageCountView></TeamManageCountView>
  </table>
</template>
<script>
import TeamManageCountView from './TeamManageCountView.vue';

export default{ 
  name:'TeamManageListView',
  components:{
    TeamManageCountView,
  }
}
</script>
