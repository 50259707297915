import ExcelJS from "exceljs";
import Refer from "@/utils/service/referenceService";
class createExcel {
  async getExcelForm(selectDate) {
    const refer = new Refer();
    const columnStart = 65; // 알파벳 A ~ V 86까지
    const rowStart = 7; // 7번째 행부터 데이터입력
    // 구단별 입장수입 현황 데이터
    const year = selectDate.slice(0, 4);
    const month = selectDate.slice(4, 6);
    const day = selectDate.slice(6, 8);
    const date = `${year}-${month}-${day}`;
    const result = await refer.$getTeamEntranceIncome(date);
    const resultData = result.data.value.data.teamAdmissionIncomeList;
    const resultSumData = result.data.value.data.sumAdmissionIncomeList;
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet("Sheet1", {
      pageSetup: {
        paperSize: 9,
        orientation: "landscape",
        horizontalCentered: true,
      },
    });
    ws.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    ws.pageSetup.printArea = "A1:V8";
    let excelData = [];
    excelData.push(
      {
        data: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      },
      {
        data: ["구장별 입장수입 현황"],
      },
      {
        data: [`( ${year}. ${month}. ${day} 일 현재 )`],
      },
      {
        data: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      },
      {
        data: [
          "구 장",
          "",
          "",
          "경기 수",
          `${year - 1} 년`,
          "",
          "",
          "",
          `${year} 년`,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "평균인원",
          "",
          "",
          "",
        ],
      },
      {
        data: [
          "",
          "",
          "",
          "",
          "인원",
          "",
          "금액",
          "",
          "당일인원",
          "",
          "누적인원",
          "",
          "증감",
          "당일수입",
          "",
          "누적금액",
          "",
          "증감(%)",
          `${year - 1} 년`,
          "",
          `${year} 년`,
          "",
        ],
      }
    );
    if (resultData == undefined) {
      alert("데이터가 없습니다.");
    }
    // 구단 데이터
    for (let teamCount = 0; teamCount < resultData.length; teamCount++) {
      excelData.push(
        {
          data: [
            `${resultData[teamCount].tNm}`,
            "",
            "",
            `${resultData[teamCount].nowTotalGCn}`,
            `${resultData[teamCount].pastCrowdCn}`,
            "",
            `${resultData[teamCount].pastCrowdMo}`,
            "",
            `${resultData[teamCount].dailyCrowdCn}`,
            "",
            `${resultData[teamCount].nowCrowdCn}`,
            "",
            `${resultData[teamCount].crowdCnRt}`,
            `${resultData[teamCount].dailyCrowdMo}`,
            "",
            `${resultData[teamCount].nowCrowdMo}`,
            "",
            `${resultData[teamCount].crowdMoRt}`,
            `${resultData[teamCount].pastAvgCn}`,
            "",
            `${resultData[teamCount].nowAvgCn}`,
            "",
          ],
        },
        {
          data: [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
        }
      );
    }

    // 합계
    excelData.push(
      {
        data: [
          "합계",
          "",
          "",
          `${resultSumData.nowTotalGCn}`,
          `${resultSumData.pastCrowdCn}`,
          "",
          `${resultSumData.pastCrowdMo}`,
          "",
          `${resultSumData.dailyCrowdCn}`,
          "",
          `${resultSumData.nowCrowdCn}`,
          "",
          `${resultSumData.crowdCnRt}`,
          `${resultSumData.dailyCrowdMo}`,
          "",
          `${resultSumData.nowCrowdMo}`,
          "",
          `${resultSumData.crowdMoRt}`,
          `${resultSumData.pastAvgCn}`,
          "",
          `${resultSumData.nowAvgCn}`,
          "",
        ],
      },
      {
        data: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      }
    );

    excelData.forEach((item, index) => {
      ws.getRow(index + 1).values = [...item.data];
    });

    // 셀 병합
    ws.mergeCells("A2:V2");
    ws.mergeCells("A3:V3");
    ws.mergeCells("E5:H5");
    ws.mergeCells("I5:R5");
    ws.mergeCells("S5:V5");

    ws.mergeCells("E6:F6");
    ws.mergeCells("G6:H6");
    ws.mergeCells("I6:J6");
    ws.mergeCells("K6:L6");
    ws.mergeCells("N6:O6");
    ws.mergeCells("P6:Q6");
    ws.mergeCells("S6:T6");
    ws.mergeCells("U6:V6");

    ws.mergeCells("D5:D6");
    ws.mergeCells("A5:C6");

    // 폰트 사이즈, 굵기, 색상 변경
    ws.getCell("A2").font = { size: 18, bold: true, color: { argb: "000000" } };
    ws.getCell("A3").font = { size: 10 };
    ws.getCell("A5").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("D5").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("E5").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("E6").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("G6").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("I5").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("I6").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("K6").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("M6").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("N6").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("P6").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("R6").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("S5").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("S6").font = { size: 11, bold: true, color: { argb: "000000" } };
    ws.getCell("U6").font = { size: 11, bold: true, color: { argb: "000000" } };

    // 정렬
    ws.getCell("A2").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("A3").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("A5").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("D5").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("E5").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("E6").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("G6").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("I5").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("I6").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("K6").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("M6").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("N6").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("P6").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("R6").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("S5").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("S6").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("U6").alignment = { horizontal: "center", vertical: "middle" };

    // 데이터 행렬 MERGE
    for (
      let row = rowStart;
      row < (resultData.length + 1) * 2 + rowStart;
      row += 2
    ) {
      // 7 ~ (팀수 + 합계) * 2 +7
      for (let col = columnStart; col < 87; col++) {
        // A ~ V

        // 구장
        if (col === 65) {
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col + 2)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 68) {
          //경기 수
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 69) {
          //인원
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col + 1)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 71) {
          // 금액
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col + 1)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 73) {
          // 당일인원
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col + 1)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 75) {
          // 누적인원
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col + 1)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 77) {
          // 증감
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 78) {
          // 당일 수입
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col + 1)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 80) {
          // 누적금액
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col + 1)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 82) {
          // 증감
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 83) {
          //전년도 평균인원
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col + 1)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        } else if (col === 85) {
          // 올해 평균인원
          // 셀 이름
          let startCellName = `${String.fromCharCode(col)}${row}`;
          let endCellName = `${String.fromCharCode(col + 1)}${row + 1}`;
          ws.mergeCells(`${startCellName}:${endCellName}`);
          ws.getCell(`${startCellName}`).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
        }
      }
    }

    // 전체 셀에 대한 점선 테두리 설정
    ws.eachRow({ includeEmpty: false }, function (row) {
      row.eachCell({ includeEmpty: false }, function (cell) {
        // A1, A2 셀에 대해 예외 처리
        if (
          cell._address.length === 2 &&
          (cell._address.endsWith("1") ||
            cell._address.endsWith("2") ||
            cell._address.endsWith("3") ||
            cell._address.endsWith("4"))
        ) {
          // 패스 (
        } else {
          cell.border = {
            top: { style: "dotted", color: { argb: "000000" } },
            bottom: { style: "dotted", color: { argb: "000000" } },
            left: { style: "dotted", color: { argb: "000000" } },
            right: { style: "dotted", color: { argb: "000000" } },
          };
        }
      });
    });

    // 가로 길이
    const col = [];
    for (let i = 0; i < excelData.length; i++) {
      col.push({
        key: "data",
        width: 10,
      });
    }
    ws.columns = col;

    // 세로 길이
    for (let i = 1; i <= excelData.length; i++) {
      let row = ws.getRow(i);
      row.height = 25;
    }
    
    let b = await wb.xlsx.writeBuffer()
    let a = new Blob([b]);
    let file = new File([a], `${year}${month}${day}-구장별입장수익현황.XLS`);
    return file;
  }
  SetExcelColSpan(title){
    
    if (title.includes('월 입장수입 현황')) {
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } }, // 구단
        { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } }, // 경기수
        { s: { r: 3, c: 2 }, e: { r: 3, c: 5 } }, // 입장인원
        { s: { r: 3, c: 6 }, e: { r: 4, c: 6 } } // 금액
      ];
      return merge; 
    } 
    else if (title.includes('구단별 좌석 점유율')) {
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }, // 상단 경기 날짜
        { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } }, // 구단
        { s: { r: 3, c: 1 }, e: { r: 3, c: 3 } }, // 홈 경기수
        { s: { r: 3, c: 4 }, e: { r: 3, c: 5 } }, // 최대 입장인원
        { s: { r: 3, c: 6 }, e: { r: 3, c: 8 } }, // 총 좌석수
        { s: { r: 3, c: 9 }, e: { r: 3, c: 11 } }, // 입장인원
        { s: { r: 3, c: 12 }, e: { r: 3, c: 14 } } // 좌석 점유율
      ];
      return merge; 
    } 
    else if (title.includes('구단별 입장인원 현황')){
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }, // 상단 경기 날짜
        { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } }, // 구단
        { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } }, // 홈 경기수
        { s: { r: 3, c: 2 }, e: { r: 3, c: 3 } }, // 2022 년
        { s: { r: 3, c: 4 }, e: { r: 3, c: 7 } }, // 2023 년
        { s: { r: 3, c: 8 }, e: { r: 3, c: 9 } }, // 평균유료인원
      ];
      return merge; 
    }
    else if (title.includes('구단별 입장수입 현황')){
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }, // 상단 경기 날짜
        { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } }, // 구단
        { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } }, // 홈 경기수
        { s: { r: 3, c: 2 }, e: { r: 3, c: 3 } }, // 2022 년
        { s: { r: 3, c: 4 }, e: { r: 3, c: 9 } }, // 2023 년
        { s: { r: 3, c: 10 }, e: { r: 3, c: 11 } }, // 평균유료인원
      ];
      return merge; 
    } else if (title.includes('구단별 정산수입')){
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }, // 상단 경기 날짜
        { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } }, // 순위
        { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } }, // 구단
        { s: { r: 3, c: 2 }, e: { r: 3, c: 3 } }, // 홈경기수입
        { s: { r: 3, c: 4 }, e: { r: 3, c: 5 } }, // 원정경기수입
        { s: { r: 3, c: 6 }, e: { r: 3, c: 7 } }, // 구단수입
        { s: { r: 3, c: 8 }, e: { r: 4, c: 8 } }, // 1위와의 차액
        { s: { r: 3, c: 9 }, e: { r: 4, c: 9 } }, // 비고
      ];
      return merge; 
    } else if (title.includes('구단 대차 명세 집계표')){
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }, // 상단 경기 날짜
      ];
      return merge; 
    } else if (title.includes('HOME vs AWAY 입장 현황')){
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }, // 상단 경기 날짜
        { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } }, // SSG
        { s: { r: 6, c: 0 }, e: { r: 7, c: 0 } }, // 키움
        { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } }, // LG
        { s: { r: 10, c: 0 }, e: { r: 11, c: 0 } }, // KT
        { s: { r: 12, c: 0 }, e: { r: 13, c: 0 } }, // KIA
        { s: { r: 14, c: 0 }, e: { r: 15, c: 0 } }, // NC
        { s: { r: 16, c: 0 }, e: { r: 17, c: 0 } }, // 삼성
        { s: { r: 18, c: 0 }, e: { r: 19, c: 0 } }, // 롯데
        { s: { r: 20, c: 0 }, e: { r: 21, c: 0 } }, // 두산
        { s: { r: 22, c: 0 }, e: { r: 23, c: 0 } }, // 한화
        { s: { r: 24, c: 0 }, e: { r: 25, c: 0 } }, // 합계
      ];
      return merge; 
    } else if (title.includes('요일별 입장 현황')){
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }, // 상단 경기 날짜
        { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } }, // SSG
        { s: { r: 6, c: 0 }, e: { r: 7, c: 0 } }, // 키움
        { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } }, // LG
        { s: { r: 10, c: 0 }, e: { r: 11, c: 0 } }, // KT
        { s: { r: 12, c: 0 }, e: { r: 13, c: 0 } }, // KIA
        { s: { r: 14, c: 0 }, e: { r: 15, c: 0 } }, // NC
        { s: { r: 16, c: 0 }, e: { r: 17, c: 0 } }, // 삼성
        { s: { r: 18, c: 0 }, e: { r: 19, c: 0 } }, // 롯데
        { s: { r: 20, c: 0 }, e: { r: 21, c: 0 } }, // 두산
        { s: { r: 22, c: 0 }, e: { r: 23, c: 0 } }, // 한화
        { s: { r: 24, c: 0 }, e: { r: 25, c: 0 } }, // 합계
      ];
      return merge;
    } else if (title.includes('월 입장수입 명세서')){
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        { s: { r: 3, c: 0 }, e: { r: 3, c: 4 } }, // 경기정보
        { s: { r: 3, c: 5 }, e: { r: 3, c: 10 } }, // 관중 수
        { s: { r: 3, c: 11 }, e: { r: 3, c: 16 } }, // 입장수입
      ];
      return merge; 
    } else if (title.includes('구단 대차 상세 명세서')){
      const merge = [
         { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }, // 상단 경기 날짜
        { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } }, // 경기수
        { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } }, // 경기일자
        { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } }, // 대진팀
        { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } }, // 분배기준금액
        { s: { r: 3, c: 4 }, e: { r: 4, c: 4 } }, // 홈구단수입
        { s: { r: 3, c: 5 }, e: { r: 3, c: 14 } } // 원정 구단 수입 
      ];
      return merge;
    } else if (title.includes('년 매진 리스트')){
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
        ];
        return merge; 
    } else if (title.includes('전체 입장수입 현황')) {
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 상단 타이틀
      ];
      return merge; 
    } 
    return ;
  }
}
export default createExcel;



