<template>
  <tr>
    <th>구단</th>
    <td>
      <select v-model="teamId">
        <option :value="''" disabled>구단</option>
        <option
          v-for="item in this.$store.state.teamList"
          :key="item"
          :value="item.teamId"
        >
          {{ item.teamName }}
        </option>
      </select>
    </td>
  </tr>
  <tr>
    <th>1구장</th>
    <td>
      <select v-model="firstStadiumId">
        <option :value="''">구장</option>
        <option
          v-for="item in firstStadiumList"
          :key="item"
          :value="item.stadiumId"
        >
          {{ item.stadiumName }}
        </option>
      </select>
    </td>
  </tr>
  <tr>
    <th>2구장</th>
    <td>
      <select v-model="secondStadiumId">
        <option :value="''">구장</option>
        <option
          v-for="item in secondStadiumList"
          :key="item"
          :value="item.stadiumId"
        >
          {{ item.stadiumName }}
        </option>
      </select>
    </td>
  </tr>
  <tr>
    <th>최대입장인원<br />(1구장)</th>
    <td>
      <input type="number" v-model="firstStadiumCount" />
    </td>
  </tr>
  <tr>
    <th>최대입장인원<br />(2구장)</th>
    <td>
      <input type="number" v-model="secondStadiumCount" />
    </td>
  </tr>
</template>
<script>
import ManageService from "@/utils/service/manageService";

export default {
  name: "ManageRegistCount",
  components: {},
  created() {
    this.$store
      .dispatch("manage/getStadiumList", { seasonId: null, stadiumSection: 1 })
      .then((res) => {
        this.firstStadiumList = res.data.stadiumList;
      });
    this.$store
      .dispatch("manage/getStadiumList", { seasonId: null, stadiumSection: 2 })
      .then((res) => {
        this.secondStadiumList = res.data.stadiumList;
      });
  },
  data() {
    return {
      manageService: new ManageService(),

      teamId: "",
      firstStadiumId: "",
      secondStadiumId: "",
      firstStadiumCount: "",
      secondStadiumCount: "",
      firstStadiumList: [],
      secondStadiumList: [],

      beforeData: {},
    };
  },
  mounted() {
    this.getModifyCountInfo();
  },
  computed: {
    startDate() {
      return this.$store.state.manage.selectedDate.startDate;
    },
    endDate() {
      return this.$store.state.manage.selectedDate.endDate;
    },
  },
  methods: {
    // 입장 인원 관리 수정 정보
    async getModifyCountInfo() {
      const seasonId = this.$route.query.seasonId;
      const teamId = this.$route.query.teamId;
      const orderNo = this.$route.query.orderNo;

      if (seasonId && teamId && orderNo) {
        await this.$store
          .dispatch("manage/getModifyCountInfo", { seasonId, teamId, orderNo })
          .then((res) => {
            if (res.data.code === 200) {
              if (res.data.admissionCountList.length > 0) {
                this.$store.commit(
                  "manage/setModifyList",
                  res.data.admissionCountList
                );
                this.$store.commit("manage/setSelectedDate", {
                  section: "start",
                  selectDate: res.data.admissionCountList[0].S_DT,
                });
                this.$store.commit("manage/setSelectedDate", {
                  section: "end",
                  selectDate: res.data.admissionCountList[0].E_DT,
                });
                this.beforeData = res.data.admissionCountList[0];

                this.teamId = this.beforeData.teamId;
                this.firstStadiumId =
                  this.beforeData.firstStadiumId.trim().length > 0
                    ? this.beforeData.firstStadiumId
                    : "";
                this.secondStadiumId =
                  this.beforeData.secondStadiumId.trim().length > 0
                    ? this.beforeData.secondStadiumId
                    : "";
                this.firstStadiumCount =
                  this.beforeData.firstStadiumCount != 0
                    ? this.beforeData.firstStadiumCount
                    : "";
                this.secondStadiumCount =
                  this.beforeData.secondStadiumCount != 0
                    ? this.beforeData.secondStadiumCount
                    : "";
              }
            }
          });
      }
    },

    // 입장 인원 관리 등록
    registerCount() {

      const $firstStadiumCount =
        this.firstStadiumCount == "" ? 0 : this.firstStadiumCount;
      const $secondStadiumCount =
        this.secondStadiumCount == "" ? 0 : this.secondStadiumCount;

      this.manageService
        .$registerCount(
          this.startDate,
          this.endDate,
          this.teamId,
          this.firstStadiumId,
          $firstStadiumCount,
          this.secondStadiumId,
          $secondStadiumCount
        )
        .then((res) => {
          if (res.data.code == 201) {
            alert("등록 되었습니다.");
            //this.$router.go(-1);
            this.$router.push("/manage/count?page=1");
          }
        });
    },

    // 입장 인원 관리 수정
    modifyCount() {

      const $firstStadiumCount =
        this.firstStadiumCount == "" ? 0 : this.firstStadiumCount;
      const $secondStadiumCount =
        this.secondStadiumCount == "" ? 0 : this.secondStadiumCount;
      this.manageService
        .$putModifyCountInfo(
          this.beforeData.seasonId,
          this.beforeData.S_DT,
          this.beforeData.teamId,
          this.beforeData.orderNo,
          this.startDate,
          this.endDate,
          this.teamId,
          this.firstStadiumId,
          $firstStadiumCount,
          this.secondStadiumId,
          $secondStadiumCount
        )
        .then((res) => {
          if (res.data.code == 201) {
            alert("수정 되었습니다.");
            this.$router.go(-1);
          }
        });
    },
  },
};
</script>
