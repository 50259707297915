<template>
  <table class="tb">
    <colgroup>
      <col width="130px">
      <col width="">
      <col width="250px">
    </colgroup>
    <tr v-for="item in newAlarmList" :key="item.rowNo">
      <td class="new">N</td>
      <td class="txt-l">{{ item.alarmVa }}</td>
      <td class="date">{{ item.alarmDt.substring(0, 10) }}&nbsp;&nbsp;&nbsp;&nbsp;{{ item.alarmDt.substring(11) }}</td>
    </tr>
    <tr v-if="newAlarmList.length === 0">
      <td colspan="3">
        새로 받은 알림이 없습니다.
      </td>
    </tr>
  </table>

  
</template>
<script>

export default{ 
  name:'AlarmNewView',
  data() {
    return {
      newAlarmList: []
    }
  },
  created() {
    this.$store.dispatch("alarm/getNewAlarmList").then(res => {
      this.newAlarmList = res.data.alarmList;
    });
  },
  unmounted() {
    this.$store.dispatch("alarm/putNewAlarmList").then(() => {
      this.$store.commit("alarm/resetNewAlarmCount");
    });
  }
}
</script>
