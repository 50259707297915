import axios from "axios";

class ManageService {
  /* 입장 인원 관리 */
  // 입장 인원 관리 게시판 리스트 출력
  async $getCountBoardList(seasonId, teamId, pageSize, pageNo) {
    const result = axios.get('/api/count/counts', {
      params: {
        seasonId, teamId, pageSize, pageNo
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 입장 인원 관리 등록
  async $registerCount(startDate, endDate, teamId, firstStadiumId, firstStadiumCount, secondStadiumId, secondStadiumCount) {
    const formData = new FormData();
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("teamId", teamId);
    formData.append("firstStadiumId", firstStadiumId);
    formData.append("firstStadiumCount", firstStadiumCount);
    formData.append("secondStadiumId", secondStadiumId);
    formData.append("secondStadiumCount", secondStadiumCount);

    const result = axios.post('/api/count/regists', formData, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 입장 인원 관리 등록 확인
  async $registerCountCheck(startDate, endDate, teamId) {
    const result = axios.get('/api/count/checkRegists', {
      params: {
        startDate, endDate, teamId
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 입장 인원 관리 수정 정보
  async $getModifyCountInfo(seasonId, teamId, orderNo) {
    const result = axios.get('/api/count/regists', {
      params: {
        seasonId, teamId, orderNo
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  // 입장 인원 관리 수정
  async $putModifyCountInfo(beforeSeasonId, beforeStartDate, beforeTeamId, beforeOrderNo, afterStartDate, afterEndDate,
                          afterTeamId, afterFirstStadiumId, afterFirstStadiumCount, afterSecondStadiumId, afterSecondStadiumCount) {
    const formData = new FormData();
    formData.append("beforeSeasonId", beforeSeasonId);
    formData.append("beforeStartDate", beforeStartDate);
    formData.append("beforeTeamId", beforeTeamId);
    formData.append("beforeOrderNo", beforeOrderNo);
    formData.append("afterStartDate", afterStartDate);
    formData.append("afterEndDate", afterEndDate);
    formData.append("afterTeamId", afterTeamId);
    formData.append("afterFirstStadiumId", afterFirstStadiumId);
    formData.append("afterFirstStadiumCount", afterFirstStadiumCount);
    formData.append("afterSecondStadiumId", afterSecondStadiumId);
    formData.append("afterSecondStadiumCount", afterSecondStadiumCount);

    const result = axios.put('/api/count/regists', formData, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 입장 인원 관리 리스트 삭제
  async $deleteCountList(leId, seasonId, teamId, orderNo) {
    const result = axios.delete('/api/count/regists', {
      params: {
        leId, seasonId, teamId, orderNo
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  /* 입장 인원 분배 비율 */
  // 입장 인원 분배 비율 게시판 리스트 출력
  async $getRatesBoardList(seasonId, pageNo, pageSize) {
    const result = axios.get('/api/admin/rates/distribute', {
      params: {
        seasonId, pageNo, pageSize
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 스타디움 리스트 가져오기
  async $getStadiumList(seasonId, stadiumSection) {
    const result = axios.get("/api/common/stadiums", {
      params: {
        seasonId, stadiumSection
      }
    });

    return await result;
  }
  // 팀스타디움1,2 가져오기
  async $getTeamStadium(seasonId, teamId) {
    const result = axios.get("/api/common/team-stadium", {
      params: {
        seasonId, teamId
      }
    });

    return await result;
  }
  // 입장 인원 분배 비율 등록
  async $registerRate(startDate, endDate, homeRate, awayRate) {
    const formData = new FormData();
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("homeRate", homeRate);
    formData.append("awayRate", awayRate);

    const result = axios.post('/api/admin/rates/distribute', formData, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 입장 인원 분배 비율 리스트 삭제
  async $deleteRateList(leId, seasonId, orderNo) {
    const result = axios.delete('/api/admin/rates/distribute', {
      params: {
        leId, seasonId, orderNo
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 입장 인원 분배 비율 수정 정보
  async $getModifyRateInfo(seasonId, orderNo) {
    const result = axios.get(`/api/admin/rates/distribute/${seasonId}/${orderNo}`, {seasonId, orderNo})
    .catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 입장 인원 분배 비율 수정
  async $putModifyRateInfo(leId, beforeSeasonId, beforeOrderNo, startDate, endDate, homeRate, awayRate) {
    const formData = new FormData();
    formData.append("leId", leId);
    formData.append("beforeSeasonId", beforeSeasonId);
    formData.append("beforeOrderNo", beforeOrderNo);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("homeRate", homeRate);
    formData.append("awayRate", awayRate);

    const result = axios.put('/api/admin/rates/distribute', formData, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  /* 사용자 관리 */
  // 사용자 관리 게시판 리스트 출력
  async $getUsersBoardList(teamId, pageNo, pageSize) {
    const result = axios.get('/api/admin/users/userinfo', {
      params: {
        teamId, pageNo, pageSize
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 수정할 사용자 정보 출력
  async $getModifyUserInfo(userSe) {
    const result = axios.get(`/api/admin/users/userinfo/${userSe}`, {userSe}).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 사용자 정보 수정
  async $putUser(userSe, userId, password, authId, teamId) {
    const formData = new FormData();
    formData.append("userSe", userSe);
    formData.append("userId", userId);
    formData.append("password", password);
    formData.append("authId", authId);
    formData.append("teamId", teamId);

    const result = axios.put('/api/admin/users/userinfo', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
  // 사용자 정보 등록
  async $registerUser(userId, password, authId, teamId) {
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("password", password);
    formData.append("authId", authId);
    formData.append("teamId", teamId);

    const result = axios.post('/api/admin/users/userinfo', formData, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
}

export default ManageService;