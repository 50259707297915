import axios from 'axios';

class AlarmService {
  // 새로 받은 알림 개수
  async $getNewAlarmCount() {
    const result = axios.get('/api/alarm/total/alarms');
    return await result;
  }
  
  // 새로 받은 알림 목록
  async $getNewAlarmList() {
    const result = axios.get('/api/alarm/alarms').catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
        return;
      }
    });
    return await result;
  }

  // 새로 받은 알림 읽음
  async $putNewAlarmList() {
    const result = axios.put('/api/alarm/alarms');
    return await result;
  }

  // 기존 알림 목록
  async $getHistoryAlarmList(pageNo, pageSize, updateCk, pastCk, seasonId, monthId, tId) {
    const result = axios.get('/api/alarm/historys', {
      params: {
        pageNo, pageSize, updateCk, pastCk, seasonId, monthId, tId
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
        return;
      }
    });
    return await result;
  }

  // 알림 수정 내용
  async $getUpdateRecordList(alarmSe) {
    const result = axios.get('/api/alarm/records', {
      params: {
        alarmSe
      }
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
        return;
      }
    });
    return await result;
  }
}

export default AlarmService;