<template>
  <div class="soldout-box"> 
    <input type="checkbox" id='soldout' v-model="soldoutInfo.soldoutCk" @change="chkSoldout">
    <label for='soldout'>매진 시간</label>
    <input type="text" v-model="this.soldoutInfo.soldoutDt" :disabled="soldoutInfo.soldoutCk == false"
                      :placeholder="soldoutInfo.soldoutCk == true ? `HH : MM` : ''"
                      @keyup="keyupText"
                      @blur="blurText">
  </div>
  <table class="tb-mini left">
    <colgroup>
      <col width="80px">
      <col width="150px">
      <col width="118px">
      <col width="150px">
    </colgroup>
    <tr>
      <th>경기일자</th>
      <td>{{gameInfo.gameDate}}</td>
      <th>요일</th>
      <td>{{gameInfo.gameday}}</td>
    </tr>
    <tr>
      <th>홈팀</th>
      <td>{{gameInfo.homeTeamName}}</td>
      <th>경기차수</th>
      <td>{{gameInfo.gameCn}}</td>
    </tr>
    <tr>
      <th>경기장</th>
      <td>{{gameInfo.stadiumNm}}</td>
      <th>점수(홈)</th>
      <td>{{gameInfo.homeCn}}</td>
    </tr>
    <tr>
      <th>원정팀</th>
      <td>{{gameInfo.awayTeamName}}</td>
      <th>점수(원정)</th>
      <td>{{gameInfo.awayCn}}</td>
    </tr>
    <tr>
      <th>리그</th>
      <td>{{gameInfo.leId}}</td>
    </tr>
    <tr>
      <th>TV 중계 1</th>
      <td>{{gameInfo.arrTvName[0]}}</td>
      <th>TV 중계 2</th>
      <td>{{gameInfo.arrTvName[1]}}</td>
    </tr>
    <tr>
      <th>TV 중계 3</th>
      <td>{{gameInfo.arrTvName[2]}}</td>
      <th>TV 중계 4</th>
      <td>{{gameInfo.arrTvName[3]}}</td>
    </tr>
    <tr>
      <th>시리즈</th>
      <td>{{gameInfo.srId}}</td>
      <th>DH</th>
      <td>{{gameInfo.headerNo}}</td>
    </tr>
  </table>
  <table class="tb-mini right">
    <colgroup>
      <col width="150px">
      <col width="150px">
      <col width="150px">
    </colgroup>
    <tr>
      <th></th>
      <th class="txt-c">입장객</th>
      <th class="txt-c">입장금액</th>
    </tr>
    <tr>
      <th>일반 관중(유료)</th>
      <td>
        <input type="text" v-model="admissionInfo.sec1.crowdCn" @input="formatNumber($event, 1, 'crowdCn')"
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
      <td>
        <input type="text" v-model="admissionInfo.sec1.crowdMo" @input="formatNumber($event, 1, 'crowdMo')"
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
    </tr>
    <tr>
      <th>스카이박스</th>
      <td>
        <input type="text" v-model="admissionInfo.sec2.crowdCn" @input="formatNumber($event, 2, 'crowdCn')"
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
      <td>
        <input type="text" v-model="admissionInfo.sec2.crowdMo" @input="formatNumber($event, 2, 'crowdMo')"
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
    </tr>
    <tr>
      <th>어린이(무료)</th>
      <td>
        <input type="text" v-model="admissionInfo.sec3.crowdCn" @input="formatNumber($event, 3, 'crowdCn')"
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
      <td>
        <input type="text" v-model="admissionInfo.sec3.crowdMo" @input="formatNumber($event, 3, 'crowdMo')"
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
    </tr>
    <tr>
      <th>우대석(무료)</th>
      <td>
        <input type="text" v-model="admissionInfo.sec4.crowdCn" @input="formatNumber($event, 4, 'crowdCn')"
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
      <td>
        <input type="text" v-model="admissionInfo.sec4.crowdMo" @input="formatNumber($event, 4, 'crowdMo')"
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
    </tr>
    <tr>
      <th>카드 할인</th>
      <td>
        <input type="text" v-model="admissionInfo.sec5.crowdCn" @input="formatNumber($event, 5, 'crowdCn')" 
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
      <td>
        <input type="text" v-model="admissionInfo.sec5.crowdMo" @input="formatNumber($event, 5, 'crowdMo')">
      </td>
    </tr>
    <tr>
      <th>멤버십</th>
      <td>
        <input type="text" v-model="admissionInfo.sec6.crowdCn" @input="formatNumber($event, 6, 'crowdCn')" 
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
      <td>
        <input type="text" v-model="admissionInfo.sec6.crowdMo" @input="formatNumber($event, 6, 'crowdMo')">
      </td>
    </tr>
    <tr>
      <th>광고 및 프로모션</th>
      <td>
        <input type="text" v-model="admissionInfo.sec7.crowdCn" @input="formatNumber($event, 7, 'crowdCn')" 
        :disabled="authId !== '1' && !isEditable && showUpdateButton">
      </td>
      <td>
        <input type="text" v-model="admissionInfo.sec7.crowdMo" @input="formatNumber($event, 7, 'crowdMo')">
      </td>
    </tr>
    <tr>
      <th>기타1</th>
      <td>
        <input type="text" v-model="admissionInfo.sec8.crowdCn" @input="formatNumber($event, 8, 'crowdCn')">
      </td>
      <td>
        <input type="text" v-model="admissionInfo.sec8.crowdMo" @input="formatNumber($event, 8, 'crowdMo')">
      </td>
    </tr>
    <tr>
      <th>기타2</th>
      <td>
        <input type="text" v-model="admissionInfo.sec9.crowdCn" @input="formatNumber($event, 9, 'crowdCn')">
      </td>
      <td>
        <input type="text" v-model="admissionInfo.sec9.crowdMo" @input="formatNumber($event, 9, 'crowdMo')">
      </td>
    </tr>
    <tr>
      <th>합계</th>
      <td>
        <input type="text" disabled :value="totalCrowdCn">
      </td>
      <td>
        <input type="text" disabled v-model="totalCrowdMo">
      </td>
    </tr>
  </table>
</template>
<script>
import EnrollService from '@/utils/service/enrollService'
import CommonService from '@/utils/service/commonService'

export default{ 
  name:'EnrollBottomView',
  components:{},
  data() {
    return{
      enrollService: new EnrollService(),
      commonService: new CommonService(),

      //gameId: this.$store.state.enroll.selectedGame,
      //teamId: this.$store.state.enroll.selectedTeam,
      authId: this.$store.state.auth.authId,

      gameInfo: {arrTvName: []},         // 왼쪽 경기정보
      admissionInfo: {sec1: {}, sec2: {}, sec3: {}, sec4: {}, sec5: {}, sec6: {}, sec7: {}, sec8: {},sec9: {},sec10: {},sec11: {}},    // 오른쪽 입장정보

      beforeSoldout: {},  // 초기 매진 값
      soldoutInfo: {soldoutCk: false, soldoutDt: ''},

      sectionCn: 9,    // 입력 section 수
      state: '',
      //isSoldout: false,

      beforeAdmission: {},  // 초기 입장객/입장금액 값
      arrAlarmUpdate: []      

    };
  },
  setup() {},
  created() {},
  mounted() {
    this.getGameInfo();
  },
  unmounted() {},
  watch: {
    '$store.state.enroll.selectedGame' () {
      if(this.$store.state.enroll.selectedGame.length > 0){
        this.getGameInfo();
      }
    },
    // 리로드 시 하단 내용 표출
    // '$store.state.enroll.isReload' () {
    //   this.getGameInfo();
    // },
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false
    },
    showUpdateButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalCrowdCn() {
      const numSecs = this.sectionCn;
      let total = 0;

      for (let i = 1; i <= numSecs; i++) {
        const crowdCn = this.admissionInfo[`sec${i}`].crowdCn;
        const crowdCnWithoutCommas = crowdCn ? crowdCn.toString().replace(/,/g, '') : 0;
        total += Number(crowdCnWithoutCommas) || 0;
      }
      return total.toLocaleString();
    },

    totalCrowdMo() {
      const numSecs = this.sectionCn; // sec1부터 sec11까지
      let total = 0;

      for (let i = 1; i <= numSecs; i++) {
        const crowdMo = this.admissionInfo[`sec${i}`].crowdMo;
        const crowdMoWithoutCommas = crowdMo ? crowdMo.toString().replace(/,/g, '') : 0;
        total += Number(crowdMoWithoutCommas) || 0;
      }
      return total.toLocaleString();
    },

    gameId() {
      return this.$store.state.enroll.selectedGame;
    },

    teamId() {
      return this.$store.state.enroll.selectedTeam;
    }
  },
  methods: {
    // 경기 정보 가져오기
    getGameInfo(){
      this.$store.dispatch('enroll/getGameInfo').then(res => {
        //console.log('getGameInfo', res, res.data.crowdArr.length);
        this.state = res.data.crowdArr.length > 0 ? 'update' : 'save';

        for(let i = 1; i <=this.sectionCn; i++){
          this.filterAndAssign(`${i}`, `sec${i}`, res);
        }

        this.gameInfo = res.data.gameInfoData;
        this.gameInfo.approvalCk = res.data.gameInfoData.approvalCk == 'True' ? true : false;
        this.gameInfo.arrTvName = [];
        res.data.gameInfoData.tvIf.split('|').forEach(element => {
          this.getBroadcastName(element).then(res => {
            this.gameInfo.arrTvName.push(res)
          })
        });

        this.soldoutInfo = res.data.objSoldout;
        this.beforeSoldout = {...res.data.objSoldout};
        if(res.data.objSoldout.soldoutCk == true) {
          this.soldoutInfo.soldoutDt = res.data.objSoldout.soldoutDt.trim();
          this.beforeSoldout.soldoutDt = res.data.objSoldout.soldoutDt.trim();
        }

      });
    },

    // 방송사명 가져오기
    async getBroadcastName(tvCd){
      const result = await this.commonService.$getBroadcasts();
      const filteredItem = result.data.broadCastList.filter(item => {
        return item.bcSc === "TV" && item.bcId === tvCd;
      });
        
      if(filteredItem){
        return filteredItem[0].fullName;
      }
    },

    // 입장객 / 입장금액 필터링 후 넣어주기
    filterAndAssign(crowdId, target, res){
      const filteredData = res.data.crowdArr.find(element => element.crowdId === crowdId);
      if (filteredData) {
        this.admissionInfo[target] = {
          crowdCn: parseInt(filteredData.crowdCn).toLocaleString() === '0' ? '' : parseInt(filteredData.crowdCn).toLocaleString(),
          crowdMo: parseInt(filteredData.crowdMo).toLocaleString() === '0' ? '' : parseInt(filteredData.crowdMo).toLocaleString(),
        };

        this.beforeAdmission[target] = {
          crowdCn: parseInt(filteredData.crowdCn).toLocaleString() === '0' ? '' : parseInt(filteredData.crowdCn).toLocaleString(),
          crowdMo: parseInt(filteredData.crowdMo).toLocaleString() === '0' ? '' : parseInt(filteredData.crowdMo).toLocaleString(),
        };
      } else {
        this.admissionInfo[target] = {};
        this.beforeAdmission[target] = {};
      }
    },

    // 입장객, 입장금액 포맷
    formatNumber(e, index, section) {
      let value = e.target.value;
      let num = this.getNumber(value);

      if (num === 0) {
        this.admissionInfo[`sec${index}`][section] = '0';
      } else {
        this.admissionInfo[`sec${index}`][section] = num.toLocaleString();
      }
    },
    
    getNumber(_str){
      var arr = _str.split('');
      var out = new Array();
      for(var cnt=0;cnt<arr.length;cnt++){
        if(isNaN(arr[cnt])==false){
          out.push(arr[cnt]);
        }
      }
      return Number(out.join(''));
    },

    // save or update
    saveUpdateAdmission(){
      let arrAdmissionInfo = [];    // 입장객, 입장금액 데이터 배열
      let gameId = this.gameId;   
      let teamId = this.teamId;
      
      // 입장객 / 입장금액
      for(let i = 1; i <= this.sectionCn; i++){
        let crowdCnWithoutCommas = this.admissionInfo[`sec${i}`].crowdCn ? this.admissionInfo[`sec${i}`].crowdCn.replace(/,/g, '') : 0;
        let crowdMoWithoutCommas = this.admissionInfo[`sec${i}`].crowdMo ? this.admissionInfo[`sec${i}`].crowdMo.replace(/,/g, '') : 0;

        arrAdmissionInfo.push({
                            LE_ID: 1,
                            SR_ID: this.gameInfo.srId,
                            G_ID: gameId,
                            T_ID: teamId,
                            CROWD_ID: i,
                            CROWD_CN:crowdCnWithoutCommas === null ? 0 : crowdCnWithoutCommas,
                            CROWD_MO: crowdMoWithoutCommas  === null ? 0 : crowdMoWithoutCommas});
      }
      //console.log(JSON.stringify(arrAdmissionInfo))

      // 알림 코드
      const alarmCd = this.enrollService.getAlarmCd(this.state);
      
      let arrSoldoutDt = this.soldoutInfo.soldoutDt?.includes(':') ? this.soldoutInfo.soldoutDt.split(':') : [];
      const [hour, minute] = arrSoldoutDt.map(part => part.trim());

      // 매진 시간 체크 
      if (this.soldoutInfo.soldoutCk == true) {
          // 빈 값일 때 처리
          if(this.soldoutInfo.soldoutDt === undefined || this.soldoutInfo.soldoutDt.trim() === ''){
            this.soldoutInfo.soldoutDt = '';
            if(this.state == 'save') {              // 초기 저장
              this.insertAdmission(arrAdmissionInfo, alarmCd, gameId, teamId);
            } else if (this.state == 'update') {    // 수정
              this.updateAdmission(arrAdmissionInfo, alarmCd, gameId, teamId);
            }
        } 
        // 시간 입력 했을 때 처리
        else {
          if (arrSoldoutDt.length !== 2 || !/^\d+$/.test(hour) || !/^\d+$/.test(minute) || parseInt(hour) > 23 || parseInt(minute) > 59 || hour.length > 2 || minute.length > 2) {
            alert('매진 시간 형식을 확인하세요. 올바른 형식은 HH:mm 입니다.');
          } else {
            // 시간 형식이 올바르면, 필요한 경우 시간을 포맷팅
            this.soldoutInfo.soldoutDt = arrSoldoutDt
              .map(part => (part.trim().length === 1 ? `0${part.trim()}` : part.trim()))
              .join(':');

              if(this.state == 'save') {              // 초기 저장
                this.insertAdmission(arrAdmissionInfo, alarmCd, gameId, teamId);
              } else if (this.state == 'update') {    // 수정
                this.updateAdmission(arrAdmissionInfo, alarmCd, gameId, teamId);
              }
          }
        }
      } 
      // 매진 시간 체크 아닐 때
      else {
        if(this.soldoutInfo.soldoutCk){
          this.soldoutInfo.soldoutDt = arrSoldoutDt
          .map(part => (part.trim().length === 1 ? `0${part.trim()}` : part.trim()))
          .join(' : ');
        }
        
        if(this.state == 'save') {              // 초기 저장
          this.insertAdmission(arrAdmissionInfo, alarmCd, gameId, teamId);
        } else if (this.state == 'update') {    // 수정
          this.updateAdmission(arrAdmissionInfo, alarmCd, gameId, teamId);
        }
      }
    },

    // 초기 저장
    insertAdmission(arrAdmissionInfo, alarmCd, gameId, teamId){
      //console.log(arrAdmissionInfo, alarmCd, gameId, teamId, this.soldoutInfo.soldoutCk, this.soldoutInfo.soldoutDt)

      this.enrollService.$insertAdmission(JSON.stringify(arrAdmissionInfo), alarmCd, 1, this.gameInfo.srId, gameId, gameId.substring(0, 4), teamId).then(res => {
        if(res.data.code === 201) {
          this.updateSoldout(gameId, this.gameInfo.gameCn, this.soldoutInfo.soldoutCk, this.soldoutInfo.soldoutDt);
        } else {
          alert('저장 중 오류 발생하였습니다.');
        }
      })
    },

    // 수정
    updateAdmission(arrAdmissionInfo, alarmCd, gameId, teamId){
      this.arrAlarmUpdate = [];
      // 수정 내용 비교
      for(let i = 1; i <=this.sectionCn; i++){
        this.comparePush(`sec${i}`, `${i}`, 'crowdCn');
        this.comparePush(`sec${i}`, `${i}`, 'crowdMo');
      }
      this.comparePushSoldout();
      
      this.enrollService.$updateAdmission(JSON.stringify(arrAdmissionInfo), JSON.stringify(this.arrAlarmUpdate), alarmCd, 1, this.gameInfo.srId, gameId, gameId.substring(0, 4), teamId).then(res => {
        //console.log(res)
        if(res.data.code === 201) {
          this.updateSoldout(gameId, this.gameInfo.gameCn, this.soldoutInfo.soldoutCk, this.soldoutInfo.soldoutDt);
        } else {
          alert('저장 중 오류 발생하였습니다.2');
        }
      })
    },

    // 매진 데이터 수정
    updateSoldout(gameId, gameCn, soldoutCk, soldoutDt) {
      this.enrollService.$updateSoldout(gameId, gameCn, soldoutCk, soldoutDt).then(res => {
        if(res.data.code === 201) {
          alert('정상적으로 저장 되었습니다.');
          this.refresh();
        } else {
          alert('저장 중 오류 발생하였습니다.1');
        }
      })
    },

    keyupText(e){
      if(e.target.value.length == 2) {
        if(e.target.value.substring(1, 2) === ':'){
          this.soldoutInfo.soldoutDt = `0${e.target.value.substring(0, 1)} : `;
        } else if (!isNaN(e.target.value.substring(0, 2))) {
          if(e.key !== 'Backspace' && e.key !== 'Delete') {
            this.soldoutInfo.soldoutDt = this.soldoutInfo.soldoutDt + ' : ';
          }
        }
      } else if (e.target.value.length == 3) {
        if(e.target.value.substring(2, 3) === ':'){
          this.soldoutInfo.soldoutDt = `0${e.target.value.substring(0, 1)} : `;
        }
      }
    },

    // 업데이트 내역 비교
    comparePush(target, crowdId, crowdSc){
      let beforeWithoutCommas = this.beforeAdmission[target][crowdSc] ? this.beforeAdmission[target][crowdSc].replace(/,/g, '') : 0;
      let afterWithoutCommas = this.admissionInfo[target][crowdSc] ? this.admissionInfo[target][crowdSc].replace(/,/g, '') : 0;
      
      if (this.admissionInfo[target][crowdSc] !== this.beforeAdmission[target][crowdSc]) {
        this.arrAlarmUpdate.push({
          CROWD_ID: crowdId,
          CROWD_SC: crowdSc === 'crowdCn' ? '0' : '1',
          BEFORE_CN: beforeWithoutCommas,
          AFTER_CN: afterWithoutCommas
        });
      }
    },

    comparePushSoldout(){
      if(JSON.stringify(this.soldoutInfo) !== JSON.stringify(this.beforeSoldout)){
        this.arrAlarmUpdate.push({
          CROWD_ID: 0,
          CROWD_SC: '2',
          BEFORE_CN: this.beforeSoldout.soldoutCk == false ? null : this.beforeSoldout.soldoutDt,
          AFTER_CN: this.soldoutInfo.soldoutCk == false ? null : this.soldoutInfo.soldoutDt
        });
      }
    },

    refresh(){
      this.$store.commit('enroll/setIsReload', !this.$store.state.enroll.isReload);
      this.$store.commit('enroll/setSelectedGame', '');
      this.$store.dispatch("alarm/getNewAlarmCount").then(res => {
        this.$store.commit("alarm/setNewAlarmCount", res.data.totalAlarmCn);
      });
    },

    // 매진 checkbox
    chkSoldout(){
      if(this.soldoutInfo.soldoutCk == false) {
        this.soldoutInfo.soldoutDt = '';
      }
    },

    // 승인
    approveAdmission(section){
      let gameId = this.gameId;   
      let teamId = this.teamId;
      let srId = this.gameInfo.srId;
      let crowdCn = ['sec1', 'sec2', 'sec3', 'sec4', 'sec8', 'sec9'] // 기타1, 기타2도 합계에 반영
        .map(sec => parseInt(this.admissionInfo[sec].crowdCn ? this.admissionInfo[sec].crowdCn.replace(/,/g, '') : 0 || 0))
        .reduce((acc, value) => acc + value, 0);
      //console.log(section, gameId, teamId, crowdCn, this.admissionInfo)
      this.enrollService.$approveAdmission(1, srId, gameId.substring(0, 4), teamId, this.enrollService.getAlarmCd(section), gameId).then(res => {
        if(res.data.code === 201) {
          this.enrollService.$updateKboCrowd(1, srId, gameId.substring(0, 4), gameId, crowdCn).then(res => {
            if(res.data.code == '201') {
              alert('승인 되었습니다.');
              this.$store.commit('enroll/setSelectedGame', '');
              this.$store.dispatch("alarm/getNewAlarmCount").then(res => {
                this.$store.commit("alarm/setNewAlarmCount", res.data.totalAlarmCn);
              });
            }
          })
        }
      })
    },
    // 전송
    sendCrowdData(){
      let gameId = this.gameId;   
      let srId = this.gameInfo.srId;
      let crowdCn = ['sec1', 'sec2', 'sec3', 'sec4', 'sec8', 'sec9'] // 기타1, 기타2도 합계에 반영
        .map(sec => parseInt(this.admissionInfo[sec].crowdCn ? this.admissionInfo[sec].crowdCn.replace(/,/g, '') : 0 || 0))
        .reduce((acc, value) => acc + value, 0);
         
        this.enrollService.$updateKboCrowd(1, srId, gameId.substring(0, 4), gameId, crowdCn).then(res => {
          if(res.data.code == '201') {
            alert('전송 되었습니다.');
            this.$store.commit('enroll/setSelectedGame', '');
          }
        })
    },
  }
}
</script>
