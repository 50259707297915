<template>
  <div class="modal"></div>
  <div class="popup">
    <a href="" class="close" @click.prevent="clickCancel()">
      <img src="@/assets/images/contents/icon_close.png" alt="close" />
    </a>
    <div class="content">
      <p class="tit">사용자 관리</p>
      <input type="text" placeholder="아이디" v-model="userId" />
      <div class="pw">
        <input
          :disabled="isDisabled"
          type="password"
          placeholder="패스워드"
          v-model="password"
        />
        <a
          href=""
          v-if="this.$store.state.manage.modalSection == 'modify'"
          @click.prevent="modifyPassword"
          >비밀번호 수정</a
        >
      </div>
      <select v-model="authId" @change="changeAuthId">
        <option :value="''" disabled>권한</option>
        <option value="1">관리자</option>
        <option value="2">구단</option>
      </select>
      <select v-model="teamId">
        <option :value="''" disabled>구단</option>
        <option v-if="authId === '1'" :value="'KBO'">KBO</option>
        <option
          v-else-if="authId === '2'"
          v-for="item in this.$store.state.teamList"
          :key="item"
          :value="item.teamId"
        >
          {{ item.teamName }}
        </option>
      </select>
    </div>
    <div class="btn-wrap">
      <button
        class="save"
        @click.prevent="
          this.$store.state.manage.modalSection == 'modify'
            ? putUser(userId, authId, teamId)
            : registerUser({ userId, password, authId, teamId })
        "
      >
        확인
      </button>
      <button class="cancel" @click.prevent="clickCancel()">취소</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ManagerUserModal",
  components: {},
  data() {
    return {
      isDisabled:
        this.$store.state.manage.modalSection == "modify" ? true : false,
      userId:
        this.$store.state.manage.modalSection == "modify"
          ? this.$store.state.manage.modifyList[0].ID_IF
          : "",
      password:
        this.$store.state.manage.modalSection == "modify" ? "@@@@@@@" : "",
      authId:
        this.$store.state.manage.modalSection == "modify"
          ? this.$store.state.manage.modifyList[0].AUTH_ID
          : "",
      teamId:
        this.$store.state.manage.modalSection == "modify"
          ? this.$store.state.manage.modifyList[0].T_ID
          : "",
    };
  },
  methods: {
    clickCancel() {
      this.$store.commit("manage/modalState", {
        isModalShow: false,
        modalSection: "",
        modifyList: [],
      });
    },
    changeAuthId() {
      this.teamId = this.authId === "1" ? "KBO" : "";
    },
    modifyPassword() {
      this.password = "";
      this.isDisabled = !this.isDisabled;
    },
    putUser(userId, authId, teamId) {
      if (this.password == "@@@@@@@") {
        this.password = "";
      }

      this.$store
        .dispatch("manage/putUser", {
          userId,
          password: this.password,
          authId,
          teamId,
        })
        .then(() => {
          this.clickCancel();
          this.$store.commit(
            "manage/setIsReload",
            !this.$store.state.manage.isReload
          );
        });
    },
    registerUser(payload) {
      this.$store.dispatch("manage/registerUser", payload).then(() => {
        this.clickCancel();
        this.$store.commit(
          "manage/setIsReload",
          !this.$store.state.manage.isReload
        );
      });
    },
  },
};
</script>
