<template>
  <div class="search-wrap">
    <p class="tit">항목선택</p>
    <ul>
      <li :class="{ on: this.$route.params.section == 'count' }">
        <a href="#" @click.prevent="this.$router.push('/manage/count?page=1')"
          >입장 인원 관리</a
        >
      </li>
      <li :class="{ on: this.$route.params.section == 'rate' }">
        <a href="#" @click.prevent="this.$router.push('/manage/rate?page=1')"
          >입장 인원 분배 비율</a
        >
      </li>
      <li :class="{ on: this.$route.params.section == 'user' }">
        <a href="#" @click.prevent="this.$router.push('/manage/user?page=1')"
          >사용자 관리</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "ManageMenuView",
};
</script>
