<!-- 요일별 // 입장 현황 -->
<template>
  <div class="report-tit"> 
    <p>요일별 입장 현황</p>
    <p class="term">( {{this.common.yyyyMMddDot($store.state.refer.firstSelectDate)}} 부터 ) ( {{this.common.yyyyMMddDot($store.state.refer.secondSelectDate)}} 까지 )</p>
    <span class="txt-l">[인원/(평균):금액(평균)]</span>
    <span class="txt-r">Date : {{this.common.yyyyMMddDot(this.common.DateObjToString(new Date()))}}</span>
  </div>
  <table class="tb-data short">
    <colgroup>
      <col width="110px">
    </colgroup>
    <tr>
      <th>구단/요일</th>
      <th>일</th>
      <th>월</th>
      <th>화</th>
      <th>수</th>
      <th>목</th>
      <th>금</th>
      <th>토</th>
      <th>팀계</th>
    </tr>
    <template v-for="(item, index) in items" :key="index">
      <tr>
        <td class="txt-c" rowspan="2">{{getTeamFullName(item.tId)}}</td>
        <td v-for="(itemData, childIndex) in item.tData" :key="childIndex">
          {{itemData.crowdNo}}
          <div v-if="itemData.crowdAvgNo != ''" class="avg">{{itemData.crowdAvgNo}}</div>
        </td>
        <td>
          {{item.rowSumData.crowdNo}}
          <div v-if="item.rowSumData.crowdAvgNo != ''" class="avg">{{item.rowSumData.crowdAvgNo}}</div>
        </td>
      </tr>
      <tr>
        <td v-for="(itemData, childIndex) in item.tData" :key="childIndex" class="border">
          {{itemData.incomeMo}}
          <div v-if="itemData.incomeAvgMo != ''" class="avg">{{itemData.incomeAvgMo}}</div>
        </td>
        <td>
          {{item.rowSumData.incomeMo.toLocaleString()}}
          <div v-if="item.rowSumData.incomeAvgMo != ''" class="avg">{{item.rowSumData.incomeAvgMo.toLocaleString()}}</div>
        </td>
      </tr>
    </template>
    <tr class="total">
      <td class="txt-c" rowspan="2"><b>합계</b></td>
      <td  v-for="(item, index) in itemsResult" :key="index">
        {{ item.colSumCrowdNo}}
        <div v-if="item.colSumCrowdAvgNo" class="avg">{{ item.colSumCrowdAvgNo }}</div>
      </td>
      <td>
        {{ totalColSumCrowdNo.toLocaleString() }}
        <div v-if="totalColSumCrowdAvgNo" class="avg">{{ totalColSumCrowdAvgNo.toLocaleString() }}</div>
      </td>
    </tr>
    <tr class="total">
      <td v-for="(item, index) in itemsResult" :key="index" class="border">
        {{ item.colSumIncomeMo }}
        <div v-if="item.colSumIncomeAvgMo" class="avg">{{ item.colSumIncomeAvgMo }}</div>
      </td>
      <td>
        {{ totalColSumIncomeMo.toLocaleString() }}
        <div v-if="totalColSumIncomeAvgMo" class="avg">{{ totalColSumIncomeAvgMo.toLocaleString() }}</div>
      </td>
    </tr>
  </table>
</template>
<style scoped src="@/assets/css/print.css"></style>

<script>
import Common from '@/utils/common';

export default{ 
  name:'DayWeekEntrance',
  components:{},
  data() {
    return{
      sampleData: '',
      common: new Common(),
      items: [],
      itemsResult: [],
    };
  },
  computed:{
    totalColSumCrowdNo(){
      if (!this.itemsResult) {
      return 0;
      }
      return this.itemsResult.reduce((sum, item) => {
        const colSumCrowdNo = item.colSumCrowdNo;
        if (colSumCrowdNo !== null && colSumCrowdNo !== undefined) {
          const parsedValue = colSumCrowdNo.trim() !== "" ? parseInt(colSumCrowdNo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);
    },

    totalColSumCrowdAvgNo(){
      if (!this.itemsResult) {
      return 0;
      }
      const totalSum = this.itemsResult.reduce((sum, item) => {
        const colSumCrowdNo = item.colSumCrowdNo;
        if (colSumCrowdNo !== null && colSumCrowdNo !== undefined) {
          const parsedValue = colSumCrowdNo.trim() !== "" ? parseInt(colSumCrowdNo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);

      const totalGameNo = this.itemsResult.reduce((sum, item) => {
        const colSumGameNo = item.colSumGameNo;
        if (colSumGameNo !== null && colSumGameNo !== undefined) {
          const parsedValue = colSumGameNo.trim() !== "" ? parseInt(colSumGameNo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);

      return totalGameNo !== 0 ? Math.round(totalSum / totalGameNo) : 0;
    },

    totalColSumIncomeMo(){
      if (!this.itemsResult) {
      return 0;
      }
      return this.itemsResult.reduce((sum, item) => {
        const colSumIncomeMo = item.colSumIncomeMo;
        if (colSumIncomeMo !== null && colSumIncomeMo !== undefined) {
          const parsedValue = colSumIncomeMo.trim() !== "" ? parseInt(colSumIncomeMo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);
    },

    totalColSumIncomeAvgMo(){
      if (!this.itemsResult) {
        return 0;
      }
      const totalSum = this.itemsResult.reduce((sum, item) => {
        const colSumIncomeMo = item.colSumIncomeMo;
        if (colSumIncomeMo !== null && colSumIncomeMo !== undefined) {
          const parsedValue = colSumIncomeMo.trim() !== "" ? parseInt(colSumIncomeMo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);

      const totalGameNo = this.itemsResult.reduce((sum, item) => {
        const colSumGameNo = item.colSumGameNo;
        if (colSumGameNo !== null && colSumGameNo !== undefined) {
          const parsedValue = colSumGameNo.trim() !== "" ? parseInt(colSumGameNo.replace(/,/g, ''), 10) : 0;
          return sum + parsedValue;
        }

        return sum;
      }, 0);

      return totalGameNo !== 0 ? Math.round(totalSum / totalGameNo) : 0;
    },
  },
  watch:{
    '$store.state.refer.isReload'(){
      this.getDayWeekEntrance();
    }
  },
  setup() {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });
  },
  mounted() {
    this.getDayWeekEntrance();
  },
  unmounted() {},
  methods: {
        getDayWeekEntrance() {
            this.$store.dispatch("refer/getDayWeekEntrance").then(res => {
                    this.items = res.data.value.data.dailyDataList;
                    this.itemsResult = res.data.value.data.dailySumDataList || ['', '', '', '', '', '', ''];
                });
    },    
        getTeamFullName(teamId) {
          const teamArray = this.$store.state.teamList.find(
            (item) => item.teamId == teamId
          );
          return teamArray ? teamArray.teamFullName : "";
        },   
  }
}
</script>
