<template>
  <router-view />
</template>
<script>

import "@/assets/css/reset.css"
import "@/assets/css/common.css"
import "@/assets/css/font.css"
import "@/assets/css/layout.css"
import "@/assets/css/contents.css"

export default {
  name: 'App',
  components: {
  }
}
</script>