<template>
  <tr>
    <th>구단</th>
    <td>
      <select disabled>
        <option :value="teamId">{{ teamNm }}</option>
      </select>
    </td>
  </tr>
  <tr>
    <th>1구장</th>
    <td>
      <select disabled>
        <option :value="firstStadiumId">{{ firstStadiumName }}</option>
        <!-- <option
          v-for="item in firstStadiumList"
          :key="item"
          :value="item.stadiumId"
        >
          {{ item.stadiumName }}
        </option> -->
      </select>
    </td>
  </tr>
  <tr>
    <th>2구장</th>
    <td>
      <select disabled>
        <option :value="secondStadiumId">{{ secondStadiumName }}</option>
        <!-- <option
          v-for="item in secondStadiumList"
          :key="item"
          :value="item.stadiumId"
        >
          {{ item.stadiumName }}
        </option> -->
      </select>
    </td>
  </tr>
  <tr>
    <th>최대입장인원<br />(1구장)</th>
    <td>
      <input type="number" v-model="firstStadiumCount" :disabled="!isRegist"/>
    </td>
  </tr>
  <tr>
    <th>최대입장인원<br />(2구장)</th>
    <td>
      <input type="number" v-model="secondStadiumCount" :disabled="!isRegist"/>
    </td>
  </tr>
</template>
<script>
import ManageService from "@/utils/service/manageService";

export default {
  name: "TeamManageRegistCount",
  components: {},
  created() {
    this.$store
      .dispatch("manage/getTeamStadium", { teamId: this.teamId })
      .then((res) => {
        this.teamNm = res.data.t_nm;
        this.firstStadiumId = res.data.s_1_id || '';
        this.secondStadiumId = res.data.s_2_id || '';
        this.firstStadiumName = res.data.s_1_nm || '구장';
        this.secondStadiumName = res.data.s_2_nm || '구장';
      });
  },
  data() {
    return {
      manageService: new ManageService(),

      teamId: this.$store.state.auth.teamId,
      teamNm: "",
      firstStadiumId: "",
      secondStadiumId: "",
      firstStadiumCount: "",
      secondStadiumCount: "",
      firstStadiumName: "",
      secondStadiumName: "",

      beforeData: {},
      isRegist: true
    };
  },
  mounted() {
    this.getModifyCountInfo();
  },
  watch: {
    "$store.state.manage.modifyList"(data) {
      if (data) {
        this.isRegist = false;
      }
    },
  },
  computed: {
    startDate() {
      return this.$store.state.manage.selectedDate.startDate;
    },
    endDate() {
      return this.$store.state.manage.selectedDate.endDate;
    },
  },
  methods: {
    // 입장 인원 관리 수정 정보
    async getModifyCountInfo() {
      const seasonId = this.$route.query.seasonId;
      const teamId = this.$route.query.teamId;
      const orderNo = this.$route.query.orderNo;

      if (seasonId && teamId && orderNo) {
        await this.$store
          .dispatch("manage/getModifyCountInfo", { seasonId, teamId, orderNo })
          .then((res) => {
            if (res.data.code === 200) {
              if (res.data.admissionCountList.length > 0) {
                this.$store.commit(
                  "manage/setModifyList",
                  res.data.admissionCountList
                );
                this.$store.commit("manage/setSelectedDate", {
                  section: "start",
                  selectDate: res.data.admissionCountList[0].S_DT,
                });
                this.$store.commit("manage/setSelectedDate", {
                  section: "end",
                  selectDate: res.data.admissionCountList[0].E_DT,
                });
                this.beforeData = res.data.admissionCountList[0];

                this.teamId = this.beforeData.teamId;
                this.firstStadiumId =
                  this.beforeData.firstStadiumId.trim().length > 0
                    ? this.beforeData.firstStadiumId
                    : "";
                this.secondStadiumId =
                  this.beforeData.secondStadiumId.trim().length > 0
                    ? this.beforeData.secondStadiumId
                    : "";
                this.firstStadiumCount =
                  this.beforeData.firstStadiumCount != 0
                    ? this.beforeData.firstStadiumCount
                    : "";
                this.secondStadiumCount =
                  this.beforeData.secondStadiumCount != 0
                    ? this.beforeData.secondStadiumCount
                    : "";
              }
            }
          });
      }
    },

    // 입장 인원 관리 등록
    registerCount() {

      const $firstStadiumCount =
        this.firstStadiumCount == "" ? 0 : this.firstStadiumCount;
      const $secondStadiumCount =
        this.secondStadiumCount == "" ? 0 : this.secondStadiumCount;

      this.manageService
        .$registerCount(
          this.startDate,
          this.endDate,
          this.teamId,
          this.firstStadiumId,
          $firstStadiumCount,
          this.secondStadiumId,
          $secondStadiumCount
        )
        .then((res) => {
          if (res.data.code == 201) {
            alert("등록 되었습니다.");
            //this.$router.go(-1);
            this.$router.push("/team/count?page=1");
          }
        });
    },

    // 입장 인원 관리 수정
    modifyCount() {

      const $firstStadiumCount =
        this.firstStadiumCount == "" ? 0 : this.firstStadiumCount;
      const $secondStadiumCount =
        this.secondStadiumCount == "" ? 0 : this.secondStadiumCount;
      this.manageService
        .$putModifyCountInfo(
          this.beforeData.seasonId,
          this.beforeData.S_DT,
          this.beforeData.teamId,
          this.beforeData.orderNo,
          this.startDate,
          this.endDate,
          this.teamId,
          this.firstStadiumId,
          $firstStadiumCount,
          this.secondStadiumId,
          $secondStadiumCount
        )
        .then((res) => {
          if (res.data.code == 201) {
            alert("수정 되었습니다.");
            this.$router.go(-1);
          }
        });
    },
    // 입장 인원 관리 등록 - 중복 확인
    registerCountCheck() {
      return new Promise((resolve, reject) => {
        this.manageService
          .$registerCountCheck(
            this.startDate,
            this.endDate,
            this.teamId,
          )
          .then((res) => {
            if(res.data.code == 200) {
              resolve(res.data.count);
            }
          }).catch((error) => {
            reject(error);
          });
      });
    },
  },
};
</script>
