<!-- 구단별 // 대차 명세 집계표 -->
<template>
  <div class="report-tit"> 
    <p>구단 대차 명세 집계표</p>
    <p class="term">( {{this.common.yyyyMMddDot($store.state.refer.firstSelectDate)}} 부터 ) ( {{this.common.yyyyMMddDot($store.state.refer.secondSelectDate)}} 까지 )</p>
    <span class="txt-r">Date : {{this.common.yyyyMMddDot(this.common.DateObjToString(new Date()))}}</span>
  </div>
  <table class="tb-data font-tiny">
    <colgroup>
      <col width="85px">
    </colgroup>
    <tr>
      <th>차/대</th>
      <th v-for="(item, index) in itemsTeamColumn" :key="index">{{item.tNm}}</th>
      <th>합계</th>
    </tr>
    <tr v-for="(item, index) in items" :key="index">
      <td class="txt-c">{{getTeamFullName(item.tId)}}</td>
      <td v-for="(itemData, index) in item.tData" :key="index">{{ itemData.crowdMo }}</td>
      <td class="txt-c">{{ item.rowSum }}</td>
    </tr>
    <tr v-if="itemsResult" class="total " >
      <td class="txt-c"><b>합계</b></td>
      <td v-for="(item, index) in itemsResult" :key="index">{{ item }}</td>
      <td>{{ formattedTotalValue  }}</td>
    </tr>
  </table>
</template>
<style scoped src="@/assets/css/print.css"></style>

<script>
import Common from '@/utils/common';

export default{ 
  name:'TeamRental',
  components:{},
  data() {
    return{
      sampleData: '',
      common: new Common(),
      items: [],
      itemsResult: [],
      itemsTeamColumn: [],
    };
  },
  computed:{
    totalValue() {
      return this.itemsResult.reduce((sum, item) => {
        const parsedValue = item.trim() !== "" ? parseInt(item.replace(/,/g, ''), 10) : 0;
        return sum + parsedValue;
      }, 0);
    },
    formattedTotalValue() {
      return this.totalValue.toLocaleString();
    },
  },
  watch:{
    '$store.state.refer.isReload'(){
      this.getTeamRental();
    }
  },
  setup() {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });   
  },
  mounted() {
    this.getTeamRental();
  },
  unmounted() {},
  methods: {
        getTeamRental() {
            this.$store.dispatch("refer/getTeamRental").then(res => {
                     this.items = res.data.value.data.teamDataList || [];
                     this.itemsResult = res.data.value.data.teamSumList || [];
                     this.itemsTeamColumn = res.data.value.data.teamDataList?this.items[0].tData : [];                   
                });
    },
    getTeamFullName(teamId) {
      const teamArray = this.$store.state.teamList.find(
        (item) => item.teamId == teamId
      );
      return teamArray ? teamArray.teamFullName : "";
    },
  }
}
</script>
