<!-- 월별 // 입장 수입 현황 -->
<template>
  <div class="report-tit">
    <p>
      {{ $store.state.refer.year }}년 {{ $store.state.refer.month }}월 입장수입
      현황
    </p>
    <span class="txt-r">(단위: 명, 원)</span>
  </div>
  <table class="tb-data">
    <colgroup>
      <col width="138px" />
      <col width="67px" />
    </colgroup>
    <tr>
      <th rowspan="2">구단</th>
      <th rowspan="2">경기수</th>
      <th colspan="4">입장인원</th>
      <th rowspan="2">금액</th>
    </tr>
    <tr>
      <th class="border">유료관중</th>
      <th>우대석(무료)</th>
      <th>어린이회원(무료)</th>
      <th>계</th>
    </tr>
    <tr v-for="(item, index) in items" :key="index">
      <td class="txt-c">{{ getTeamFullName(item.tId) }}</td>
      <td>{{ item.homeGameCn.toLocaleString() }}</td>
      <td>{{ item.payCrowdCn.toLocaleString() }}</td>
      <td>{{ item.freePreferCrowdCn.toLocaleString() }}</td>
      <td>{{ item.freeChildCrowdCn.toLocaleString() }}</td>
      <td>{{ item.totalCrowdCn.toLocaleString() }}</td>
      <td>{{ item.totalCrowdMo.toLocaleString() }}</td>
    </tr>
    <tr v-if="itemsResult" class="total">
      <td class="txt-c"><b>합계</b></td>
      <td>{{ itemsResult.homeGameCn }}</td>
      <td>{{ itemsResult.payCrowdCn }}</td>
      <td>{{ itemsResult.freePreferCrowdCn }}</td>
      <td>{{ itemsResult.freeChildCrowdCn }}</td>
      <td>{{ itemsResult.totalCrowdCn }}</td>
      <td>{{ itemsResult.totalCrowdMo }}</td>
    </tr>
  </table>
</template>
<style scoped src="@/assets/css/print.css"></style>

<script>
export default {
  name: "MonthEntranceIncome",
  components: {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });
  },
  data() {
    return {
      sampleData: "",
      items: null,
      itemsResult: null,
    };
  },
  setup() {},
  watch: {
    "$store.state.refer.isReload"() {
      this.getMonthEntranceIncome();
    },
  },
  mounted() {
    this.getMonthEntranceIncome();
  },
  unmounted() {},
  methods: {
    getMonthEntranceIncome() {
      this.$store.dispatch("refer/getMonthEntranceIncome").then((res) => {
        this.items = res.data.value.data.monthList;
        this.itemsResult = res.data.value.data.sumMonthList;
      });
    },
    getTeamFullName(teamId) {
      const teamArray = this.$store.state.teamList.find(
        (item) => item.teamId == teamId
      );
      return teamArray ? teamArray.teamFullName : "";
    },
  },
};
</script>
