<template>
  <colgroup>
    <col width="" />
    <col width="" />
    <col width="" />
    <col width="" />
    <col width="" />
  </colgroup>
  <tr>
    <th>날짜</th>
    <th>리그</th>
    <th>홈팀 수익</th>
    <th>원정팀 수익</th>
    <th>수정&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;삭제</th>
  </tr>
  <tr v-if="rateBoardList.length == 0">
    <td colspan="5">검색 결과가 존재하지 않습니다.</td>
  </tr>
  <tr v-else v-for="item in rateBoardList" :key="item.ROW_NO">
    <td>{{ item.S_DT }} - {{ item.E_DT }}</td>
    <td>{{ item.LE_NM }}</td>
    <td>{{ item.HOME_RT }}</td>
    <td>{{ item.AWAY_RT }}</td>
    <td>
      <a
        href=""
        class="btn edit"
        @click.prevent="clickModify(item.SEASON_ID, item.ORDER_NO)"
        >수정</a
      >
      <a
        href=""
        class="btn remove"
        @click.prevent="
          deleteRateList(item.LE_ID, item.SEASON_ID, item.ORDER_NO)
        "
        >삭제</a
      >
    </td>
  </tr>
</template>

<script>
export default {
  data() {
    return {
      rateBoardList: [],
    };
  },
  created() {
    this.getRateBoardList();
  },
  unmounted() {
    this.$store.commit("manage/setSeasonId", new Date().getFullYear());
  },
  computed: {
    getNowPageNum() {
      return this.$route.query.page;
    },
    getSeasonId() {
      return this.$store.state.manage.seasonId;
    },
  },
  watch: {
    getNowPageNum: {
      handler: "getRateBoardList",
      deep: true,
    },
    getSeasonId: {
      handler: "getRateBoardList",
      deep: true,
    },
  },
  methods: {
    getRateBoardList() {
      if (this.$route.params.section == "rate") {
        this.$store
          .dispatch("manage/getRatesBoardList", {
            nowPageNum: this.getNowPageNum,
            pageSize: 10,
          })
          .then((res) => {
            this.rateBoardList = res.data.rateList;
            this.$store.commit("setTotalCnt", {
              totalCnt: Number(res.data.totalCnt),
            });
          });
      }
    },
    deleteRateList(leId, seasonId, orderNo) {
      if (confirm("삭제 하시겠습니까?")) {
        this.$store
          .dispatch("manage/deleteRateList", { leId, seasonId, orderNo })
          .then((res) => {
            if (res.data.code == 201) {
              alert("삭제 되었습니다.");
              if (this.getNowPageNum == 1) {
                this.$router.go();
              } else {
                this.$router.push({ query: { page: 1 } });
              }
            }
          });
      }
    },
    clickModify(seasonId, orderNo) {
      this.$router.push({
        path: "/manage/rate/register",
        query: {
          seasonId: seasonId,
          orderNo: orderNo,
        },
      });
    },
  },
};
</script>
