<template>
  <h3>자료실</h3>
  <section class="board">
    <ReferenceSearchView></ReferenceSearchView>
    <div id='mask' v-if="$store.state.refer.isLoading == true" style='position:absolute;z-index:9000;left:50%;top:200px;'>
      <div id='loadingImg'>
        <img src='@/assets/images/contents/loading.gif' style='width:100px;height:100px;'/>
      </div>
    </div>    
    <ReferenceResultView></ReferenceResultView>
  </section>
</template>

<script>
import ReferenceSearchView from '@/components/Reference/ReferenceSearchView.vue'
import ReferenceResultView from '@/components/Reference/ReferenceResultView.vue'
export default{ 
  name:'ReferenceView',
  components:{ReferenceSearchView, ReferenceResultView},
  data() {
    return{
      sampleData: ''
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>
