<template>
  <div div class="board left">
    <ul class="tit-list">
      <!-- <li>홈팀</li> -->
      <li>구단명</li>
      <li>홈경기수</li>
    </ul>
    <ul class="data-list">
      <li :class="{on: $store.state.enroll.selectedTeam === team.teamId}"
                  v-for="team in teamList" :key="team.teamId">
        <a href="#" @click.prevent="clickTeam(team.teamId)">
          <!-- <span>{{team.teamId}}</span> -->
          <span>{{team.teamFirstName + " " + team.teamLastName}}</span>
          <span>{{team.homeGameCn}}</span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>

import EnrollService from '@/utils/service/enrollService.js'

export default{ 
  name:'EnrollTeamListView',
  components:{},
  data() {
    return{
      enrollService: new EnrollService(),

      teamList: []    // 구단 목록
    };
  },
  setup() {},
  created() {},
  mounted() {
    //this.getTeamList();
  },
  unmounted() {},
  watch: {
    '$store.state.enroll.selectedTeam' (data) {
      if(data === '' && this.$store.state.enroll.selectedTeam.length > 0) {
        this.getTeamList();
      }
    },
  },
  methods: {
    // 팀 목록
    async getTeamList(){
      //console.log('getTeamList')
      const year = this.$store.state.enroll.selectedCal.year;
      const month = this.$store.state.enroll.selectedCal.month;
      const teamId = this.$store.state.auth.teamId;
      const srIdList = '0,6';
      const result = await this.enrollService.$getTeamList(1, srIdList, month, year, teamId)
      if(result.data.code === '200') {
        this.teamList = result.data.teamList;
      } else {
        this.teamList = [];
      }
    },

    // 팀 선택
    clickTeam(tId){
      this.$store.commit('enroll/setSelectedTeam', tId);
      this.$store.commit('enroll/setSelectedGame', '');
    },
  }
}
</script>
