<!-- 구단별 // 좌석 점유율 -->
<template>
  <div class="report-tit"> 
    <p>구단별 좌석 점유율</p>
    <p class="term">( {{this.common.yyyyMMddDot($store.state.refer.firstSelectDate)}} 현재 )</p>
    <span class="txt-r">Date : {{this.common.yyyyMMddDot(this.common.DateObjToString(new Date()))}}</span>
  </div>
  <table class="tb-data">
    <colgroup>
      <col width=""> 
      <col width="48px"> 
      <col width="48px"> 
      <col width="48px"> 
      <col width="76px"> 
      <col width="76px"> 
      <col width="85px"> 
      <col width="85px"> 
      <col width="85px"> 
      <col width="85px"> 
      <col width="85px"> 
      <col width="85px"> 
      <col width="48px"> 
      <col width="48px"> 
      <col width="48px"> 
    </colgroup>
    <tr>
      <th rowspan="2">구단</th>
      <th colspan="3">홈경기수</th>
      <th colspan="2">최대입장인원</th>
      <th colspan="3">총좌석수</th>
      <th colspan="3">입장인원</th>
      <th colspan="3">좌석점유율</th>
    </tr>
    <tr>
      <th class="border">합계</th>
      <th>MAIN</th>
      <th>SUB</th>

      <th>MAIN</th>
      <th>SUB</th>

      <th>합계</th>
      <th>MAIN</th>
      <th>SUB</th>

      <th>합계</th>
      <th>MAIN</th>
      <th>SUB</th>
      
      <th>합계</th>
      <th>MAIN</th>
      <th>SUB</th>
    </tr>
    <tr v-for="(item, index) in items" :key="index">
      <td class="txt-c">{{ getTeamFullName(item.tId) }}</td>
      <td>{{ item.totalGCn }}</td>
      <td>{{ item.mainGCn }}</td>
      <td>{{ item.subGCn }}</td>
      <td>{{ item.mainMaxCn }}</td>
      <td>{{ item.subMaxCn }}</td>
      <td>{{ item.totalSeatCn }}</td>
      <td>{{ item.mainSeatCn }}</td>
      <td>{{ item.subSeatCn }}</td>
      <td>{{ item.totalCrowdCn }}</td>
      <td>{{ item.mainCrowdCn }}</td>
      <td>{{ item.subCrowdCn }}</td>
      <td>{{ item.totalSeatRt }}</td>
      <td>{{ item.mainSeatRt }}</td>
      <td>{{ item.subSeatRt }}</td>
    </tr>
    <tr v-if="itemsResult" class="total">
      <td class="txt-c"><b>합계</b></td>
      <td>{{ itemsResult.totalGCn }}</td>
      <td>{{ itemsResult.mainGCn }}</td>
      <td>{{ itemsResult.subGCn }}</td>
      <td></td>
      <td></td>
      <td>{{ itemsResult.totalSeatCn }}</td>
      <td>{{ itemsResult.mainSeatCn }}</td>
      <td>{{ itemsResult.subSeatCn }}</td>     
      <td>{{ itemsResult.totalCrowdCn }}</td>
      <td>{{ itemsResult.mainCrowdCn }}</td>
      <td>{{ itemsResult.subCrowdCn }}</td>    
      <td>{{ itemsResult.totalSeatRt }}</td>
      <td>{{ itemsResult.mainSeatRt }}</td>
      <td>{{ itemsResult.subSeatRt }}</td>
    </tr>
  </table>
</template>
<style scoped src="@/assets/css/print.css"></style>

<script>
import Common from '@/utils/common';

export default{ 
  name:'TeamSeatOccupancy',
  components:{},
  data() {
    return{
      sampleData: '',
      common: new Common(),
      items: null,
      itemsResult: null,
    };
  },
  setup() {},
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });   
  },
  watch:{
    '$store.state.refer.isReload'(){
      this.getTeamSeatOccupancy();
    }
  },
  mounted() {
    this.getTeamSeatOccupancy();
  },
  unmounted() {},
  methods: {
    getTeamSeatOccupancy() {
            this.$store.dispatch("refer/getTeamSeatOccupancy").then(res => {
                    this.items = res.data.value.data.teamSeatRateList;
                    this.itemsResult = res.data.value.data.sumSeatRateList;
                });
    },
    getTeamFullName(teamId) {
      const teamArray = this.$store.state.teamList.find(
        (item) => item.teamId == teamId
      );
      return teamArray ? teamArray.teamFullName : "";
    },
  }
}
</script>
