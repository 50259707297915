import AlarmService from "@/utils/service/alarmService";

const alarmService = new AlarmService();

const alarmStore = {
  namespaced: true,
  state: {
    newAlarmCount: 0,
    isModalShow: false,
    firstCrowdList: [],
    secondCrowdList: [],
    soldOutList: [],
    modifyInfo: {},
  },
  getters: {},
  actions: {
    // 새로 받은 알림 개수
    getNewAlarmCount() {
      return alarmService.$getNewAlarmCount();
    },
    // 새로 받은 알림 목록 추출
    getNewAlarmList() {
      return alarmService.$getNewAlarmList();
    },
    // 새로 받은 알림 읽음
    putNewAlarmList() {
      return alarmService.$putNewAlarmList();
    },
    // 기존 알림 목록 추출
    getHistoryAlarmList(context, payload) {
      return alarmService.$getHistoryAlarmList(
        payload.nowPageNum,
        payload.pageSize,
        payload.updateCk,
        payload.pastCk,
        payload.seasonId,
        payload.monthId,
        payload.tId
      );
    },
    // 알림 수정 내용
    getUpdateRecordList(context, payload) {
      return alarmService.$getUpdateRecordList(payload);
    },
  },
  mutations: {
    // 팝업 표출 여부
    modalState(state, payload) {
      state.isModalShow = payload.isModalShow;
      state.modifyInfo = payload.modifyInfo;
      state.firstCrowdList = payload.firstCrowdList;
      state.secondCrowdList = payload.secondCrowdList;
      state.soldOutList = payload.soldOutList;
    },
    // 새로 받은 알림 개수 값 설정
    setNewAlarmCount(state, alarmCount) {
      state.newAlarmCount = alarmCount;
    },
    // 새로 받은 알림 개수 초기화
    resetNewAlarmCount(state) {
      state.newAlarmCount = 0;
    },
  },
};

export default alarmStore;
