import ManageService from "@/utils/service/manageService";

const manageService = new ManageService();

const manageStore = {
  namespaced: true,
  state: {
    isModalShow: false,
    modalSection: "",
    teamId: "",
    seasonId: new Date().getFullYear(),
    modifyList: [],
    isReload: false,
    selectedDate: { startDate: "", endDate: "" },
  },
  actions: {
    // 입장 인원 관리 게시판 리스트 출력
    getCountBoardList(context, payload) {
      const state = context.state;
      return manageService.$getCountBoardList(
        state.seasonId,
        state.teamId,
        payload.pageSize,
        payload.nowPageNum
      );
    },
    // 입장 인원 관리 수정 정보
    getModifyCountInfo(context, payload) {
      return manageService.$getModifyCountInfo(
        payload.seasonId,
        payload.teamId,
        payload.orderNo
      );
    },
    // 입장 인원 관리 리스트 삭제
    deleteCountList(context, payload) {
      return manageService.$deleteCountList(
        payload.leId,
        payload.seasonId,
        payload.teamId,
        payload.orderNo
      );
    },
    // 입장 인원 분배 비율 게시판 리스트 출력
    getRatesBoardList(context, payload) {
      const state = context.state;
      return manageService.$getRatesBoardList(
        state.seasonId,
        payload.nowPageNum,
        payload.pageSize
      );
    },
    // 스타디움 리스트 가져오기
    getStadiumList(context, payload) {
      const state = context.state;
      return manageService.$getStadiumList(
        state.seasonId,
        payload.stadiumSection
      );
    },
    // 팀 스타디움 가져오기
    getTeamStadium(context, payload) {
      const state = context.state;
      return manageService.$getTeamStadium(
        state.seasonId,
        payload.teamId
      );
    },
    // 입장 인원 분배 비율 리스트 삭제
    deleteRateList(context, payload) {
      return manageService.$deleteRateList(
        payload.leId,
        payload.seasonId,
        payload.orderNo
      );
    },
    // 입장 인원 분배 비율 수정 정보
    getModifyRateInfo(context, payload) {
      return manageService.$getModifyRateInfo(
        payload.seasonId,
        payload.orderNo
      );
    },
    // 사용자 관리 게시판 리스트 출력
    getUsersBoardList(context, payload) {
      const state = context.state;
      return manageService.$getUsersBoardList(
        state.teamId,
        payload.nowPageNum,
        payload.pageSize
      );
    },
    // 수정할 사용자 정보 출력
    getModifyUserInfo(context, userSe) {
      return manageService.$getModifyUserInfo(userSe);
    },
    // 사용자 정보 수정
    putUser(context, payload) {
      const state = context.state;
      return manageService.$putUser(
        state.modifyList[0].U_SE,
        payload.userId,
        payload.password,
        payload.authId,
        payload.teamId
      );
    },
    // 사용자 정보 등록
    registerUser(context, payload) {
      return manageService.$registerUser(
        payload.userId,
        payload.password,
        payload.authId,
        payload.teamId
      );
    },
  },
  mutations: {
    // 팝업 표출 여부
    modalState: function (state, payload) {
      state.isModalShow = payload.isModalShow;
      state.modalSection = payload.modalSection;
      state.modifyList = payload.modifyList;
    },
    // 팀 아이디 변경
    setTeamId(state, payload) {
      state.teamId = payload;
    },
    // 시즌 연도 변경
    setSeasonId(state, payload) {
      state.seasonId = payload;
    },
    // 수정할 정보 설정
    setModifyList(state, payload) {
      state.modifyList = payload;
    },
    // 달력 선택 시
    setSelectedDate(state, payload) {
      state.selectedDate[`${payload.section}Date`] = payload.selectDate;
    },
    setIsReload(state, payload) {
      state.isReload = payload;
    },
  },
};

export default manageStore;
