<template>
  <table class="tb">
    <ManageCountView v-if="this.$route.params.section == 'count'"></ManageCountView>
    <ManageRateView v-if="this.$route.params.section == 'rate'"></ManageRateView>
    <ManageUserView v-if="this.$route.params.section == 'user'"></ManageUserView>
  </table>
</template>
<script>
import ManageCountView from './ManageCountView.vue';
import ManageRateView from './ManageRateView.vue';
import ManageUserView from './ManageUserView.vue';

export default{ 
  name:'ManageListView',
  components:{
    ManageCountView,
    ManageRateView,
    ManageUserView
  }
}
</script>
