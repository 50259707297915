<template>
  <div v-if = "$store.state.refer.subSearch !== ''" class="control">
      <a v-if = "$store.state.refer.mainSearch === 'team' && $store.state.refer.subSearch === 'income' && $store.state.refer.firstSelectDate > '20230000'" class="btn save" @click="uploadExcel">저장</a>
      <!-- 엑셀 전체 출력 페이지 -->
      <a>
        <img 
          src="@/assets/images/contents/btn_pdf.png" 
          alt="pdf down" 
          @click="makeExcel($store.state.refer.mainSearch === 'totalCrowd' || $store.state.refer.mainSearch === 'soldout' ? 'true' : 'false')"
        >
      </a>
      <a>
        <img 
          src="@/assets/images/contents/btn_print.png" 
          alt="print" 
          @click="printContent($store.state.refer.mainSearch === 'totalCrowd' || $store.state.refer.mainSearch === 'soldout' ? 'true' : 'false')"
        >
      </a>
  </div>
  <div id="resultWrap" class="report-wrap">
      <MonthEntranceIncome v-if = "($store.state.refer.mainSearch === 'month') && ($store.state.refer.subSearch === 'income')"></MonthEntranceIncome>
      <TeamSeatOccupancy v-else-if = "($store.state.refer.mainSearch === 'team') && ($store.state.refer.subSearch === 'seat')"></TeamSeatOccupancy>
      <TeamEntranceCount v-else-if = "($store.state.refer.mainSearch === 'team') && ($store.state.refer.subSearch === 'count')"></TeamEntranceCount>
      <TeamEntranceIncome v-else-if = "($store.state.refer.mainSearch === 'team') && ($store.state.refer.subSearch === 'income')"></TeamEntranceIncome>
      <TeamCalculateIncome v-else-if = "($store.state.refer.mainSearch === 'team') && ($store.state.refer.subSearch === 'calculate')"></TeamCalculateIncome>
      <TeamRental v-else-if = "($store.state.refer.mainSearch === 'team') && ($store.state.refer.subSearch === 'rental')"></TeamRental>
      <TeamHomeAway v-else-if = "($store.state.refer.mainSearch === 'team') && ($store.state.refer.subSearch === 'homeaway')"></TeamHomeAway>
      <DayWeekEntrance v-else-if = "($store.state.refer.mainSearch === 'day') && ($store.state.refer.subSearch === 'entrance')"></DayWeekEntrance>
      <TotalRentalDetail v-else-if = "($store.state.refer.mainSearch === 'total') && ($store.state.refer.subSearch === 'rentalDetail') && ($store.state.refer.teamId !== '')"></TotalRentalDetail>
      <TotalSpecification v-else-if = "($store.state.refer.mainSearch === 'total') && ($store.state.refer.subSearch === 'specification') && ($store.state.refer.teamId !== '')"></TotalSpecification>
      <SoldOutListVue v-else-if = "($store.state.refer.mainSearch === 'soldout') && ($store.state.refer.subSearch === 'list')"></SoldOutListVue>
      <TotalCrowdVue v-else-if = "($store.state.refer.mainSearch === 'totalCrowd') && ($store.state.refer.subSearch === 'list')" ref="totalCrowdComponent"></TotalCrowdVue>
      <PagingView v-if = "($store.state.refer.mainSearch === 'soldout' || $store.state.refer.mainSearch === 'totalCrowd') && ($store.state.refer.subSearch === 'list') && !this.$store.state.refer.isPrint"></PagingView>
  </div>
</template>

<script>

import MonthEntranceIncome from '@/components/Reference/ReferenceDetail/MonthEntranceIncome.vue'
import TeamSeatOccupancy from '@/components/Reference/ReferenceDetail/TeamSeatOccupancy.vue'
import TeamEntranceCount from '@/components/Reference/ReferenceDetail/TeamEntranceCount.vue'
import TeamEntranceIncome from '@/components/Reference/ReferenceDetail/TeamEntranceIncome.vue'
import TeamCalculateIncome from '@/components/Reference/ReferenceDetail/TeamCalculateIncome.vue'
import TeamRental from '@/components/Reference/ReferenceDetail/TeamRental.vue'
import TeamHomeAway from '@/components/Reference/ReferenceDetail/TeamHomeAway.vue'
import DayWeekEntrance from '@/components/Reference/ReferenceDetail/DayWeekEntrance.vue'
import TotalRentalDetail from '@/components/Reference/ReferenceDetail/TotalRentalDetail.vue'
import TotalSpecification from '@/components/Reference/ReferenceDetail/TotalSpecification.vue'
import SoldOutListVue from '@/components/Reference/ReferenceDetail/SoldOutList.vue'
import TotalCrowdVue from '@/components/Reference/ReferenceDetail/TotalCrowd.vue'
import PagingView from '@/components/Layout/PagingView.vue'

import CreateExcel from '@/utils/createExcel'
import * as XLSX from 'xlsx';
import XLSXStyle  from 'xlsx-js-style';

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export default{ 
  name:'ReferenceResultView',
  components:{MonthEntranceIncome,
              TeamSeatOccupancy, TeamEntranceCount, TeamEntranceIncome, TeamCalculateIncome, TeamRental, TeamHomeAway,
              DayWeekEntrance, TotalRentalDetail, TotalSpecification, SoldOutListVue, TotalCrowdVue, PagingView},
  data() {
    return{
      createExcel: new CreateExcel(),
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  computed:{
    isPrint() {
      return this.$store.state.refer.isPrint;
    }
    ,excelTitle(title, term, clubName) {
    // 배열에 title, term (값이 있는 경우만), clubName (값이 있는 경우만) 포함
    const parts = [title, term, clubName].filter(part => part);
    // "_"로 연결된 문자열 반환
    return parts[title, term, clubName].filter(part => part).join("_");
  }
  },
  methods: {
    printContent(isFullPrint){
      this.$store.commit('refer/setIsPrint', true);
      const element = (isFullPrint === 'true') ? document.getElementById("hidden-result") : document.getElementById("resultWrap");
      const clonedElement = element.cloneNode(true);

      if (isFullPrint === 'true') {
        // clonedElement에서 hidden-table 클래스를 가진 요소들 찾기
        const hiddenTables = clonedElement.querySelectorAll('.hidden-table');
        hiddenTables.forEach(table => {
          table.style.display = 'block'; // 인라인 스타일로 display 설정
        });
      }

      const printWindow = window.open(`/Reference/PrintReferenceDetail`, '', 'width=900,height=800');
      setTimeout(() => {
        const body = printWindow.document.getElementById("contents");
        if (body) {
          body.style.width = "1122.520px";
          body.appendChild(clonedElement);
        }
        const style = document.createElement('style');
        // 엑셀 전체 출력 > 히든 테이블 보이게
        style.appendChild(document.createTextNode(`
          @page { size: landscape; }
          .hidden-table { display: block !important; }
        `));
        printWindow.document.head.appendChild(style);
        setTimeout(() => {
          printWindow.document.close();
          printWindow.print();
          printWindow.onafterprint = function () {
            printWindow.close();
          };
        }, 1000);
        this.$store.commit('refer/setIsPrint', false);
      }, 1000);
    },

    uploadExcel(){
      this.$store.dispatch("refer/getFirstDateSelect").then(res => {
        this.createExcel.getExcelForm(res).then(excel => {
          this.$store.dispatch("refer/uploadIncomeFile", {file: excel, filePath: "KBO_FILE/pressbox/files"}).then(result => {
            if(result.status == 200) {
              this.$store.dispatch("refer/updateGameCrowd", result.data.path).then(res => {
                if(res.status == 200) {
                  alert('저장되었습니다.')
                }
              });
            }
          });
        });
      });
    },
    makePDF() {
      this.$store.commit('refer/setIsPrint', true);
      this.$store.commit('refer/setIsDownload', true);
      const element = document.getElementById("resultWrap");
      const options = {
        //scrollX: 0,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: 2,
      };

      if (element) {
        const printWindow = window.open(`/Reference/PrintReferenceDetail`, '', 'width=100,height=100');
        
        setTimeout(() => {
          const body = printWindow.document.getElementById('contents');
          body.style.width = "1122.520px";
          if (body) {
            body.appendChild(element.cloneNode(true));
          }
          this.$store.commit('refer/setIsLoading', true);
          const html2canvasOptions = {
            scrollY: -window.scrollY, // 페이지가 스크롤된 경우에도 전체 콘텐츠 캡처
            ...options
          };

          setTimeout(() => {
            html2canvas(body, html2canvasOptions).then(canvas => {
              const pdf = new jsPDF('landscape', 'mm', 'a4');
              const maxCanvasHeight = 1800; // 각 페이지의 최대 높이 설정
              const totalHeight = canvas.height;
              const numPages = Math.ceil(totalHeight / maxCanvasHeight);

              let startY = 0;

              for (let currentPage = 1; currentPage <= numPages; currentPage++) {
                const endY = Math.min(startY + maxCanvasHeight, totalHeight);

                const croppedCanvas = document.createElement('canvas');
                const ctx = croppedCanvas.getContext('2d');
                croppedCanvas.width = canvas.width;
                croppedCanvas.height = endY - startY;
                ctx.drawImage(canvas, 0, startY, canvas.width, croppedCanvas.height, 0, 0, canvas.width, croppedCanvas.height);

                const imgData = croppedCanvas.toDataURL('image/png');
                const imgWidth = 277; // 이미지 가로 길이(mm) A4 기준
                const imgHeight = croppedCanvas.height * imgWidth / croppedCanvas.width;

                if (currentPage !== 1) {
                  pdf.addPage('landscape', 'mm', 'a4');
                }

                pdf.addImage(imgData, 'png', 10, 10, imgWidth, imgHeight,'', 'FAST');

                startY += maxCanvasHeight;
              }

              this.$store.dispatch("refer/getCategory").then(res => {
                pdf.save(res);
              });
              this.$store.commit('refer/setIsLoading', false);
              printWindow.close();
            });
          }, 1000);
          this.$store.commit('refer/setIsPrint', false);
          this.$store.commit('refer/setIsDownload', false);
        }, 1000);
      }
    },
    // 텍스트별 길이(Length)를 측정하는 함수
    calculateAdjustedLength(cellValue){
      const string = cellValue.toString();
        let adjustedLength = 0;

        for (let i = 0; i < string.length; i++) {
            const char = string[i];
            if (char.match(/[A-Za-z0-9]/)) {
                adjustedLength += 1; // ASCII 문자
            } else if (char.match(/[\uac00-\ud7a3]/)) {
                adjustedLength += 2; // 한글
            } else {
                adjustedLength += 1.5; // 기타 문자, 가중치 조정 필요
            }
        }
        return adjustedLength;
    },
    // json 배열을 순회하며 각 열의 최대 길이를 계산하는 함수
    calculateCellLength(colWidths, json){
      json.forEach(row => {
            row.forEach((cell, index) => {
                // 셀의 길이를 계산 (숫자는 toLocaleString을 사용하여 문자열로 변환)
                const cellLength = this.calculateAdjustedLength(cell);

                // 해당 열의 최대 길이를 업데이트
                if (!colWidths[index] || colWidths[index] < cellLength) {
                    colWidths[index] = cellLength;
                }
            });
        });

        // 셀 너비 설정을 위한 배열 생성
        return Object.keys(colWidths).map(colIndex => ({
            wch: (colWidths[colIndex] <= 9) ? 10 : colWidths[colIndex] + 2 // 디폴트로 크기 10 지정
        }));
    },
    getTitleText(selector){
      const element = document.querySelector(selector);
      return element ? element.textContent : '';
    },
    convertExcelJson(json){
      // 경기일자 컬럼을 변환
      json.forEach((row, index) => {    
        var indexNumber;
        // 헤더 또는 빈 행은 패스
        if(json[0][0] === "경기일자") {
          indexNumber = 0;
        }
        else if(json[0][1] === "날짜"){
          indexNumber = 1;
        }
        else if(json[0][1] === "경기일자"){
          indexNumber = 1;
        }
        else return;

        if(index < 1 || !row[indexNumber]) return;
        
        // 날짜 컬럼(첫 번째 컬럼)의 값을 변환
        const dateValue = row[indexNumber];

        if (typeof dateValue === 'number') {
          const utc_days = Math.floor(dateValue - 25569);
          const utc_value = utc_days * 86400;
          const date_info = new Date(utc_value * 1000);
          
          row[indexNumber] = date_info.toISOString().split('T')[0]; 
        }
      });

      json.forEach((row, index) => {
        // 헤더 또는 빈 행은 패스
        if(index < 1 || !row[0]) return;
        
        // 숫자 컬럼을 쉼표로 구분된 문자열로 변환
        row.forEach((cell, cellIndex) => {
           if (typeof cell === 'number') { // 숫자 컬럼일 경우
            row[cellIndex] = cell.toLocaleString('en-US');
           }
        });
      });
    },
    // 셀 병합 등 스타일 함수
    setCellStyleOption(newWs, merge){
      // 병합 설정을 워크시트에 추가
      if (!newWs['!merges']) newWs['!merges'] = [];
      newWs['!merges'].push(...merge);

      const allCellRefs = Object.keys(newWs);

      for (const cellRef of allCellRefs) {
        if (cellRef[0] === '!') continue;

        // 셀에 스타일 객체가 없다면 초기화
        if (!newWs[cellRef].s) {
          newWs[cellRef].s = {};
        }

        // 정렬 스타일 적용
        newWs[cellRef].s.alignment = {
            horizontal: "center",
            vertical: "center",
            wrapText: true 
        };
      }
    },
    makeExcel(isFullPrint) {
      // 각 열의 최대 길이를 저장할 객체 초기화
      const colWidths = {};

      const originalTable = (isFullPrint === 'true') ? document.querySelector('#hidden-result') : document.querySelector('#resultWrap');

      // 카피 테이블 생성
      const clonedTable = originalTable.cloneNode(true);

      // 복사본에서 .avg 클래스를 가진 모든 요소를 찾아 괄호를 추가
      clonedTable.querySelectorAll('.avg').forEach(avgElement => {
        const text = avgElement.textContent;
        avgElement.textContent = ` '  (${text})`; 
      });

      // 전체 입장수입 현황 > 경기 일자 포맷지정
      clonedTable.querySelectorAll('#gameDate').forEach(avgElement => {
          avgElement.textContent= avgElement.textContent.replace(/\s/g, '').replace(/\./g, '-').replace(/-$/, '') + '`';
      });
              
      // 엑셀 전체 출력 체크
      const title = (isFullPrint === 'true') ? this.getTitleText('#hidden-result .report-tit p') : this.getTitleText('#resultWrap .report-tit p');
      const term = (isFullPrint === 'true') ? this.getTitleText('#hidden-result .report-tit .term') : this.getTitleText('#resultWrap .report-tit .term');
      const clubName = (isFullPrint === 'true') ? this.getTitleText('#hidden-result .report-tit .txt-l') : this.getTitleText('#resultWrap .report-tit .txt-l');
      const date = (isFullPrint === 'true') ? this.getTitleText('#hidden-result .report-tit .txt-r') : this.getTitleText('#resultWrap .report-tit .txt-r');

      // 파일제목 생성
      const excelTitle = [title, term, clubName].filter(part => part).join("_");
    
      const ws = XLSXStyle.utils.table_to_sheet(clonedTable);

      // 워크시트를 JSON으로 변환
      const json = XLSXStyle.utils.sheet_to_json(ws, { header:1, raw: true, wrapText: true });

      // json 데이터 조작
      this.convertExcelJson(json);

      // 워크시트 데이터 앞에 빈 행 추가
      json.unshift(["", "", "", "", ""]);
      json.unshift([term, "", "", "", "", date]);
      json.unshift([title, "", "", clubName]);

      // json 배열을 순회하며 각 열의 최대 길이를 계산하는 함수
      const wscols = this.calculateCellLength(colWidths, json);

      // 워크시트 생성
      const newWs = XLSX.utils.aoa_to_sheet(json);
      
      if (title.includes('전체 입장수입 현황')){
        // 특정 컬럼 (예: 첫 번째 컬럼)을 날짜 형식으로 지정
        const dateColumnIndex = 0; // 날짜가 있는 컬럼의 인덱스 (0부터 시작)
        for (let R = 0; R < json.length; R++) {
          const cellAddress = XLSX.utils.encode_cell({c: dateColumnIndex, r: R});
          if (newWs[cellAddress]) {
            let dateString = newWs[cellAddress].v;
            if (dateString.endsWith('`')) {
              dateString = dateString.slice(0, -1); // 백틱 제거
              // 날짜를 'yyyy-mm-dd' 형식으로 변환
              const [year, month, day] = dateString.split('-');
              newWs[cellAddress].v = `${year}-${month}-${day}`;
              newWs[cellAddress].z = 'yyyy-mm-dd'; // 날짜 형식 설정
            }
          }
        }
      }

      // 셀 병합
      const merge = this.createExcel.SetExcelColSpan(title);

      // 셀 병합 등 스타일 함수
      this.setCellStyleOption(newWs, merge);

      newWs['!cols'] = wscols;

      // 워크북을 생성하고 워크시트를 추가
      const wb = XLSXStyle.utils.book_new();
      XLSXStyle.utils.book_append_sheet(wb, newWs, 'sheet1');

      // 엑셀 파일 생성 및 다운로드
      XLSXStyle.writeFile(wb, excelTitle + '.xlsx');
    },
  }
}
</script>
