<template>
  <h3>관리자</h3>
  <section v-if="this.$route.name == 'manage'" class="board">
    <ManageMenuView></ManageMenuView>
    <ManageSearchView></ManageSearchView>
    <ManageListView></ManageListView>
    <PagingView></PagingView>
  </section>
  <section v-else-if="this.$route.name == 'manageRegister'" class="board">
    <ManageRegisterView></ManageRegisterView>
  </section>
</template>

<script>
import ManageMenuView from "@/components/Manage/ManageMenuView.vue";
import ManageSearchView from "@/components/Manage/ManageSearchView.vue";
import ManageListView from "@/components/Manage/ManageListView.vue";
import PagingView from "@/components/Layout/PagingView.vue";
import ManageRegisterView from "@/components/Manage/ManageRegist/ManageRegistView.vue";

export default {
  name: "ManageView",
  components: {
    ManageMenuView,
    ManageSearchView,
    ManageListView,
    PagingView,
    ManageRegisterView,
  },
  data() {
    return {
      totalCount: 0,
    };
  },
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });
  },
};
</script>
