<template>
  <colgroup>
    <col width="200px" />
    <col width="100px" />
    <col width="100px" />
    <col width="" />
    <col width="100px" />
    <col width="" />
    <col width="" />
  </colgroup>
  <tr>
    <th>날짜</th>
    <th>구단</th>
    <th>1구장</th>
    <th>최대입장인원(1구장)</th>
    <th>2구장</th>
    <th>최대입장인원(2구장)</th>
    <th>수정&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;삭제</th>
  </tr>
  <tr v-if="countBoardList.length == 0">
    <td colspan="7">검색 결과가 존재하지 않습니다.</td>
  </tr>
  <tr v-else v-for="item in countBoardList" :key="item.rowNum">
    <td>{{ item.startDate }} ~ {{ item.endDate }}</td>
    <td>{{ item.teamName }}</td>
    <td>{{ item.firstStadiumName }}</td>
    <td>{{ parseInt(item.firstStadiumCount).toLocaleString() }}</td>
    <td>{{ item.secondStadiumName }}</td>
    <td>{{ parseInt(item.secondStadiumCount).toLocaleString() }}</td>
    <td>
      <a
        href=""
        class="btn edit"
        @click.prevent="clickModify(item.seasonId, item.teamId, item.orderNo)"
        >수정</a
      >
      <a
        href=""
        class="btn remove"
        @click.prevent="
          deleteCountList(item.leId, item.seasonId, item.teamId, item.orderNo)
        "
        >삭제</a
      >
    </td>
  </tr>
</template>

<script>
export default {
  data() {
    return {
      countBoardList: [],
    };
  },
  created() {
    this.getCountBoardList();
  },
  unmounted() {
    this.$store.commit("manage/setTeamId", "");
    this.$store.commit("manage/setSeasonId", new Date().getFullYear());
  },
  computed: {
    getNowPageNum() {
      return this.$route.query.page;
    },
    getTeamId() {
      return this.$store.state.manage.teamId;
    },
    getSeasonId() {
      return this.$store.state.manage.seasonId;
    },
  },
  watch: {
    getNowPageNum: {
      handler: "getCountBoardList",
      deep: true,
    },
    getSeasonId: {
      handler: "getCountBoardList",
      deep: true,
    },
    getTeamId: {
      handler: "getCountBoardList",
      deep: true,
    },
  },
  methods: {
    getCountBoardList() {
      if (this.$route.params.section == "count") {
        this.$store
          .dispatch("manage/getCountBoardList", {
            pageSize: 10,
            nowPageNum: this.getNowPageNum,
          })
          .then((res) => {
            this.countBoardList = res.data.admissionCountList;
            this.$store.commit("setTotalCnt", {
              totalCnt: Number(res.data.totalCount),
            });
          });
      }
    },
    deleteCountList(leId, seasonId, teamId, orderNo) {
      if (confirm("삭제 하시겠습니까?")) {
        this.$store
          .dispatch("manage/deleteCountList", {
            leId,
            seasonId,
            teamId,
            orderNo,
          })
          .then((res) => {
            if (res.data.code == 201) {
              alert("삭제 되었습니다.");
              if (this.getNowPageNum == 1) {
                this.$router.go();
              } else {
                this.$router.push({ query: { page: 1 } });
              }
            }
          });
      }
    },
    clickModify(seasonId, teamId, orderNo) {
      this.$router.push({
        path: "/manage/count/register",
        query: {
          seasonId: seasonId,
          teamId: teamId,
          orderNo: orderNo,
        },
      });
    },
  },
};
</script>
