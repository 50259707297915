import EnrollService from '@/utils/service/enrollService';

const enrollService = new EnrollService();
const enrollStore = {
  namespaced: true,
  state: {
    selectedCal: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1 
    },
    selectedTeam: '',
    selectedGame: '',

    isReload: false,
  },
  getters: {
  
  },
  mutations: {
    // 달력 값 업데이트
    setCalendar: function (state, payload) {
      return state.selectedCal = payload;
    },

    setSelectedTeam: function (state, payload){
      return state.selectedTeam = payload;
    },

    // 하단 표출값 업데이트
    setSelectedGame: function (state, payload) {
      return state.selectedGame = payload;
    },

    setIsReload: function (state, payload) {
      return state.isReload = payload;
    }

  },
  actions: {
    // 경기 목록 가져오기
    getGameList: function(context){
      const state = context.state;
      const srIdList = '0,6';
      return enrollService.$getGameList(1, srIdList, state.selectedCal.month, state.selectedCal.year, state.selectedTeam);
    },

    // 선택 월 합계 가져오기
    getMonthTotal: function(context) {
      const state = context.state;
      const srIdList = '0,6';
      return enrollService.$getMonthTotal(1, srIdList, state.selectedCal.month, state.selectedCal.year, state.selectedTeam);
    },

    // 경기 정보 가져오기
    getGameInfo: function(context) {
      const state = context.state;
      const srIdList = '0,6';
      return enrollService.$getGameInfo(1, srIdList, state.selectedGame, state.selectedCal.year, state.selectedTeam)
    },
    
    // 입장 수입 삭제하기
    deleteAdmission: function(context, gId) {
      const state = context.state;
      const srIdList = '0,6';
      return enrollService.$deleteAdmission(1, srIdList, gId.substring(0, 4), state.selectedTeam, enrollService.getAlarmCd('delete'), gId)
    },
  },
}

export default enrollStore