<template>
  <div class="modal"></div>
  <div class="popup">
		<a href="" class="close" @click.prevent="clickCancel">
			<img src="@/assets/images/contents/icon_close.png" alt="close">
		</a>
		<div class="content">
			<p class="tit">{{ this.$store.state.alarm.modifyInfo.gameDate }} {{ this.$store.state.alarm.modifyInfo.teamName }} 수정 내역</p>
			
			<div class='scroll-box'>
				<template v-if="this.$store.state.alarm.soldOutList.length &gt; 0">
					<p class="sub-tit">&bull; 매진</p>
					<ul class="detail" v-for="item in this.$store.state.alarm.soldOutList" :key="item.crowdId">
						<li>
							<span>기존</span>
							<p>{{item.beforeCn == '' ? '매진 아님' : '매진'}} {{ item.beforeCn }}</p>
						</li>
						<li> 
							<span>수정</span>
							<p>{{item.afterCn == '' ? '매진 아님' : '매진'}} {{ item.afterCn }}</p>
						</li>
					</ul>
				</template>
				<p class="sub-tit">&bull; 입장객</p>
				<ul v-if="this.$store.state.alarm.firstCrowdList.length == 0" class="detail single">
					<li>
						<p>없음</p>
					</li>
				</ul>
				<ul v-else class="detail" v-for="item in this.$store.state.alarm.firstCrowdList" :key="item.crowdId">
					<li>
						<span>기존</span>
						<p>{{ item.crowdName }} {{ Number(item.beforeCn).toLocaleString('ko-KR') }}</p>
					</li>
					<li> 
						<span>수정</span>
						<p>{{ item.crowdName }} {{ Number(item.afterCn).toLocaleString('ko-KR') }}</p>
					</li>
				</ul>

				<p class="sub-tit">&bull; 입장금액</p>
				<ul v-if="this.$store.state.alarm.secondCrowdList.length == 0" class="detail single">
					<li>
						<p>없음</p>
					</li>
				</ul>
				<ul v-else class="detail" v-for="item in this.$store.state.alarm.secondCrowdList" :key="item.crowdId">
					<li>
						<span>기존</span>
						<p>{{ item.crowdName }} {{ Number(item.beforeCn).toLocaleString('ko-KR') }}</p>
					</li>
					<li>
						<span>수정</span>
						<p>{{ item.crowdName }} {{ Number(item.afterCn).toLocaleString('ko-KR') }}</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name:'AlarmModal',
  methods: {
    clickCancel() {
      this.$store.commit('alarm/modalState', {
        isModalShow: false,
				modifyInfo: {},
        firstCrowdList: [],
        secondCrowdList: [],
				soldOutList: [],
      });
	},
  }
}
</script>

<style>

</style>