<template>
  <div class="sub-search-wrap">
    <select v-if="this.$route.params.section != 'user'" v-model="selectedYear">
      <option v-for="item in years" :value="item" :key="item">
        {{ item }}
      </option>
    </select>
    <select v-model="teamId" v-if="this.$route.params.section != 'rate'">
      <option :value="''">전체</option>
      <option v-if="this.$route.params.section === 'user'" :value="'KBO'">
        KBO
      </option>
      <option
        v-for="item in this.$store.state.teamList"
        :key="item"
        :value="item.teamId"
      >
        {{ item.teamName }}
      </option>
    </select>
    <a class="btn search" href="#" @click.prevent="filterBySearch">검색</a>
    <a class="" href="#" @click.prevent="clickAdd(this.$route.params.section)">
      <img src="@/assets/images/contents/btn_add.png" alt="add" />
    </a>
  </div>
</template>
<script>
export default {
  name: "ManageSearchView",
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = 1982;
    const years = Array.from(
      { length: currentYear + 1 - startYear + 1 },
      (_, index) => startYear + index
    );

    return {
      teamId: "",
      years,
      selectedYear: currentYear, // 기본 선택 연도
    };
  },
  computed: {
    resetSelect() {
      return this.$route.params.section;
    },
  },
  watch: {
    resetSelect(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.teamId = "";
        this.selectedYear = new Date().getFullYear();
      }
    },
  },
  methods: {
    clickAdd(section) {
      // 입장 인원 관리, 입장 인원 분배 비율일 경우만 등록 페이지 이동
      let arrSection = ["count", "rate"];
      if (arrSection.includes(section)) {
        this.$router.push(`/manage/${section}/register`);
      } else {
        this.$store.commit("manage/modalState", {
          isModalShow: true,
          modalSection: "enroll",
        });
      }
    },
    async filterBySearch() {
      if (this.$route.params.section == "user") {
        await this.$store.commit("manage/setTeamId", this.teamId);
      } else if (this.$route.params.section == "rate") {
        await this.$store.commit("manage/setSeasonId", this.selectedYear);
      } else if (this.$route.params.section == "count") {
        await this.$store.commit("manage/setTeamId", this.teamId);
        await this.$store.commit("manage/setSeasonId", this.selectedYear);
      }
      this.$router.push({ query: { page: 1 } });
    },
  },
};
</script>
