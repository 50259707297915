import axios from "axios";
import store from '@/store';

class MemberService {
  // 로그인
  async $login(id, password, isSave) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("password", password);
    formData.append("isSave", isSave);
    const result = axios.post("/api/member/login", formData);

    return await result;
  }

  // 로그아웃
  async $logout() {
    axios.get("/api/member/logout");
  }

  // 유저정보 세션값 확인
  async $getUserSession() {
    try {
      const response = await axios.get("/api/member/session");
      const result = response;
      if(result.data) {
        store.commit('setUserAuth', {teamId: JSON.parse(response.data).U_T_ID || 'null', authId: JSON.parse(response.data).U_AUTH, userSe: JSON.parse(response.data).U_SE});
      }
      return result;
    } catch (error) {
      console.error(error);
    }
  }
}

export default MemberService;
