import axios from "axios";

class ReferenceService {
  async $getMonthEntranceIncome(seasonId, monthId) {
    const result = axios.get("/api/admin/Archive/month/admission", {
      params: {
        seasonId,
        monthId,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $getTeamSeatOccupancy(date) {
    const result = axios.get("/api/admin/Archive/team/seat-rate", {
      params: {
        date,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $getTeamEntranceCount(date) {
    const result = axios.get("/api/admin/Archive/team/admission-crowd", {
      params: {
        date,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $getTeamEntranceIncome(date) {
    const result = axios.get("/api/admin/Archive/team/admission-income", {
      params: {
        date,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $getTeamCalculateIncome(startDate, endDate) {
    const result = axios.get("/api/admin/Archive/team/total-income", {
      params: {
        startDate,
        endDate,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $getTeamRental(startDate, endDate) {
    const result = axios.get("/api/admin/Archive/team/detail-sheet", {
      params: {
        startDate,
        endDate,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $getTeamHomeAway(startDate, endDate) {
    const result = axios.get("/api/admin/Archive/team/home-away-admission", {
      params: {
        startDate,
        endDate,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $getDayWeekEntrance(startDate, endDate) {
    const result = axios.get("/api/admin/Archive/daily/admission", {
      params: {
        startDate,
        endDate,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $getTotalSpecification(seasonId, monthId, teamId) {
    var tId = "";
    if (teamId == "") {
      return;
    } else {
      tId = teamId;
    }
    const result = axios.get("/api/admin/Archive/team-admission-detail", {
      params: {
        tId,
        seasonId,
        monthId,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $getTeamRentalDetail(startDate, endDate, teamId) {
    if (teamId == "") {
      return;
    } 
    const result = axios.get("/api/admin/Archive/team-admission-rental-detail", {
      params: {
        startDate
        , endDate
        , teamId       
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $getSoldOutList(seasonId, pageNo, pageSize) {
    const result = axios.get("/api/admin/Archive/soldout-list", {
      params: {
        seasonId,
        pageSize,
        pageNo,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $uploadIncomeFile(file, filePath) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filePath", filePath);

    const result = axios.post("/api/File/uploadFile", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }

  async $updateGameCrowd(bdSc, bdTt, fileNm) {
    const formData = new FormData();
    formData.append("bdSc", bdSc);
    formData.append("bdTt", bdTt);
    formData.append("fileNm", fileNm);
    
    const result = axios.post('/api/admin/Archive/callApi', formData).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });

    return await result;
  }

  async $getTotalCrowd(startDate, endDate, pageNo, pageSize) {
    const result = axios.get("/api/admin/Archive/total-crowd", {
      params: {
        startDate,
        endDate,
        pageSize,
        pageNo,
      },
    }).catch(err => {
      if(err.response.status === 401){
        alert("세션이 만료되었습니다.\n로그인 페이지로 이동합니다.");
        window.location.href = window.location.origin;
      }
    });
    return await result;
  }
}

export default ReferenceService;
