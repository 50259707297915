<template>
  <h3>관리자</h3>
  <section v-if="this.$route.name == 'team'" class="board">
    <TeamManageMenuView></TeamManageMenuView>
    <TeamManageSearchView></TeamManageSearchView>
    <TeamManageListView></TeamManageListView>
    <PagingView></PagingView>
  </section>
  <section v-else-if="this.$route.name == 'teamRegister'" class="board">
    <TeamManageRegistView></TeamManageRegistView>
  </section>
</template>

<script>
import TeamManageMenuView from "@/components/TeamManage/TeamManageMenuView.vue";
import TeamManageSearchView from "@/components/TeamManage/TeamManageSearchView.vue";
import TeamManageListView from "@/components/TeamManage/TeamManageListView.vue";
import PagingView from "@/components/Layout/PagingView.vue";
import TeamManageRegistView from "@/components/TeamManage/TeamManageRegist/TeamManageRegistView.vue";

export default {
  name: "TeamManageView",
  components: {
    TeamManageMenuView,
    TeamManageSearchView,
    TeamManageListView,
    PagingView,
    TeamManageRegistView,
  },
  data() {
    return {
      totalCount: 0,
    };
  },
  mounted() {
  },
  created() {
    this.$store.dispatch("getTeamList").then((res) => {
      this.$store.commit(
        "setTeamList",
        res.data.teamList.filter(
          (item) => item.season == new Date().getFullYear()
        )
      );
    });
  },
};
</script>
