<template>
  <div class="sub-search-wrap">
    <div class="condition">
      <input
        type="checkbox"
        id="update"
        @change="updateCk = updateCk == 2 ? 0 : 2"
        :checked="updateCk == 2"
      /><label for="update">수정만 보기</label>
      <input
        type="checkbox"
        id="sort"
        @change="pastCk = pastCk == 1 ? 0 : 1"
        :checked="pastCk == 1"
      /><label for="sort">과거순</label>
    </div>
    <select v-model="selectedYear">
      <option v-for="item in years" :value="item" :key="item">
        {{ item }}
      </option>
    </select>
    <select v-model="selectedMonth">
      <option :value="''">월별</option>
      <option v-for="item in 12" :value="item" :key="item">{{ item }}</option>
    </select>
    <select v-model="teamId">
      <option :value="''">구단</option>
      <option
        v-for="item in this.$store.state.teamList"
        :key="item"
        :value="item.teamId"
      >
        {{ item.teamName }}
      </option>
    </select>
  </div>

  <table class="tb">
    <colgroup>
      <col width="100px" />
      <col width="100px" />
      <col width="" />
      <col width="130px" />
      <col width="130px" />
    </colgroup>
    <tr>
      <th>구단</th>
      <th>구분</th>
      <th>세부내용</th>
      <th>ID</th>
      <th>업데이트 시간</th>
    </tr>
    <tr v-if="newAlarmList.length == 0">
      <td colspan="5">등록·수정 이력이 존재하지 않습니다.</td>
    </tr>
    <tr v-else v-for="item in newAlarmList" :key="item">
      <td>{{ item.userTeamName }}</td>
      <td>
        {{
          item.alarmId == 1
            ? "등록"
            : item.alarmId == 2
            ? "수정"
            : item.alarmId == 3
            ? "삭제"
            : "승인"
        }}
      </td>
      <td>
        {{ item.alarmVa }}
        <a
          v-if="item.alarmId == 2"
          class="memo"
          href=""
          @click.prevent="getModifyInfo(item.alarmSe)"
          >수정내역</a
        >
      </td>
      <td>{{ item.idIf }}</td>
      <td>{{ item.alarmDt }}</td>
    </tr>
  </table>
</template>
<script>
export default {
  name: "AlarmHistoryView",
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = 2023; // 2023년도부터 표출
    const years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => startYear + index
    );

    return {
      teamId: "",
      years,
      selectedMonth: "",
      selectedYear: currentYear,
      newAlarmList: [],
      updateCk: 0,
      pastCk: 0,
    };
  },
  created() {
    this.getHistoryAlarmList();
  },
  methods: {
    getHistoryAlarmList() {
      if (this.$route.params.section == "history") {
        this.$store
          .dispatch("alarm/getHistoryAlarmList", {
            nowPageNum: this.getNowPageNum,
            pageSize: 8,
            updateCk: this.updateCk,
            pastCk: this.pastCk,
            seasonId: this.selectedYear,
            monthId: this.selectedMonth,
            tId: this.teamId,
          })
          .then((res) => {
            this.newAlarmList = res.data.historyList;
            this.$store.commit("setTotalCnt", {
              totalCnt: Number(res.data.totalCn),
            });
          });
      }
    },
    async changedSelect() {
      await this.$router.push({ query: { page: 1 } });
      this.getHistoryAlarmList();
    },
    async getModifyInfo(alarmSe) {
      await this.$store
        .dispatch("alarm/getUpdateRecordList", alarmSe)
        .then((res) => {
          this.$store.commit("alarm/modalState", {
            isModalShow: true,
            modifyInfo: {
              teamName: res.data.teamName,
              gameDate: res.data.gameDate,
            },
            firstCrowdList: res.data.firstCrowdArr,
            secondCrowdList: res.data.secondCrowdArr,
            soldOutList: res.data.soldOutArr,
          });
        });
    },
  },
  computed: {
    getNowPageNum() {
      return this.$route.query.page;
    },
    getTeamId() {
      return this.teamId;
    },
    getSelectedMonth() {
      return this.selectedMonth;
    },
    getSelectedYear() {
      return this.selectedYear;
    },
    getUpdateCk() {
      return this.updateCk;
    },
    getPastCk() {
      return this.pastCk;
    },
  },
  watch: {
    getNowPageNum: {
      handler: "getHistoryAlarmList",
      deep: true,
    },
    getTeamId: {
      handler: "changedSelect",
      deep: true,
    },
    getSelectedMonth: {
      handler: "changedSelect",
      deep: true,
    },
    getSelectedYear: {
      handler: "changedSelect",
      deep: true,
    },
    getUpdateCk: {
      handler: "changedSelect",
      deep: true,
    },
    getPastCk: {
      handler: "changedSelect",
      deep: true,
    },
  },
};
</script>
