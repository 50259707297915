<template>
  <!-- wrap -->
  <div id="wrap">
    <HeaderView></HeaderView>

    <!-- container -->
    <div id="container" :class="{adm: $store.state.auth.authId === '1' || this.$route.name.indexOf('team') &gt; -1}"> <!-- adm 클래스 관리자일때만 붙게 추후 수정-->
      <div id="contents">
        <template v-if="this.$route.name.indexOf('enroll') &gt; -1">
          <EnrollView></EnrollView>
        </template>
        <template v-else-if="this.$route.name.indexOf('reference') &gt; -1">
          <ReferenceView></ReferenceView>
        </template>
        <template v-else-if="this.$route.name.indexOf('manage') &gt; -1">
          <ManageView></ManageView>
        </template>
        <template v-else-if="this.$route.name.indexOf('alarm') &gt; -1">
          <AlarmView></AlarmView>
        </template>
        <template v-else-if="this.$route.name.indexOf('team') &gt; -1">
          <TeamManageView></TeamManageView>
        </template>
      </div>
    </div>
    <!-- // container -->
  </div>
  <!-- // wrap -->
  
  <FooterView></FooterView>
  <!-- 관리자 > 알림 Modal -->
  <AlarmModal v-if="$store.state.alarm.isModalShow"></AlarmModal>
  <!-- 관리자 > 사용자 관리 Modal -->
  <ManagerUserModal v-if="$store.state.manage.isModalShow"></ManagerUserModal>
</template>

<script>
import HeaderView from '@/components/Layout/HeaderView.vue'
import EnrollView from '@/components/Enroll/EnrollView.vue'
import ReferenceView from '@/components/Reference/ReferenceView.vue'
import ManageView from '@/components/Manage/ManageView.vue'
import AlarmView from '@/components/Alarm/AlarmView.vue'
import TeamManageView from '@/components/TeamManage/TeamManageView.vue'

import AlarmModal from '@/components/Alarm/AlarmModal.vue'
import ManagerUserModal from '@/components/Manage/ManageUserModal.vue'

import FooterView from '@/components/Layout/FooterView.vue'
export default{ 
  name:'MainView',
  components:{HeaderView, EnrollView, ReferenceView, ManageView, AlarmView, TeamManageView, AlarmModal, ManagerUserModal, FooterView},
  data() {
    return{
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  watch: {},
  methods: {}
}
</script>
