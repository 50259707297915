<template>
  <div div id="wrap">
    <!-- container -->
    <div class="container">
      <div class="contents">
        <div class="login-wrap">
          <img
            src="@/assets/images/contents/icon_login.png"
            alt="KBO 관중입장수입"
          />
          <div class="login-box">
            <ul class="login-input">
              <li class="id">
                <input
                  type="text"
                  id="id"
                  placeholder="아이디"
                  v-model="txtId"
                />
              </li>
              <li class="pw">
                <input
                  type="password"
                  id="pw"
                  placeholder="패스워드"
                  v-model="txtPassword"
                  @keyup.enter="btnLogin()"
                />
              </li>
            </ul>
            <p class="id-save">
              <input type="checkbox" id="id_save" v-model="idSave" />
              <label for="id_save">아이디 저장</label>
            </p>
            <a
              href="user_list_before.html"
              class="btn-login"
              @click.prevent="btnLogin()"
              >로그인</a
            >

            <div v-if="isFail" class="notice">
              가입된 회원이 아니거나 비밀번호가 틀립니다. <br />로그인 정보를
              다시 확인해주세요.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //container -->
  </div>
  <FooterView />
</template>

<script>
import FooterView from "@/components/Layout/FooterView.vue";
import MemberService from "@/utils/service/memberService.js";
import CommonService from "@/utils/service/commonService";

export default {
  name: "LoginView",
  components: { FooterView },
  data() {
    return {
      common: new CommonService(),
      memberService: new MemberService(),

      txtId: "", // 아이디
      txtPassword: "", // 비밀번호
      idSave: false, // 아이디 저장
      isFail: false,
    };
  },
  setup() {},
  created() {},
  mounted() {
    document.body.classList.add("login");
    let $saveId = this.common.getCookie("EIM_COOKIE_ID"); // 쿠키에 저장한 id 값

    if ($saveId) {
      this.txtId = $saveId;
      this.idSave = true;
    }
  },
  unmounted() {
    document.body.classList.remove("login");
  },
  methods: {
    async btnLogin() {
      // 아이디 유효성 검사
      const validateId = /^[a-z0-9]{4,12}$/;

      if (!validateId.test(this.txtId)) {
        this.isFail = true;
        return;
      }

      try {
        // API 호출 코드
        const result = await this.memberService.$login(
          this.txtId,
          this.txtPassword,
          this.idSave
        );
        // API 응답 처리
        if (result.data.code == "200") {
          this.$router.push("/enroll");
        }
      } catch (error) {
        this.isFail = true;
      }
    },
  },
};
</script>
