<template>
  <colgroup>
    <col width="" />
    <col width="" />
    <col width="" />
    <col width="" />
    <col width="" />
  </colgroup>
  <tr>
    <th>아이디</th>
    <th>권한</th>
    <th>구단</th>
    <th>최종접속</th>
    <th>등록일</th>
    <th>수정</th>
  </tr>
  <tr v-if="userBoardList.length == 0">
    <td colspan="6">검색 결과가 존재하지 않습니다.</td>
  </tr>
  <tr v-else v-for="item in userBoardList" :key="item.U_SE">
    <td>{{ item.ID_IF }}</td>
    <td>{{ item.AUTH_NM }}</td>
    <td>{{ item.AUTH_ID == 1 ? item.T_ID : item.T_NM }}</td>
    <td>{{ item.LOGIN_DT == "" ? "-" : item.LOGIN_DT }}</td>
    <td>{{ item.REG_DT }}</td>
    <td>
      <a href="#" @click.prevent="modifyUser(item.U_SE)" class="btn edit"
        >수정</a
      >
    </td>
  </tr>
</template>

<script>
export default {
  data() {
    return {
      userBoardList: [],
    };
  },
  created() {
    this.getUserBoardList();
  },
  unmounted() {
    this.$store.commit("manage/setTeamId", "");
  },
  methods: {
    async modifyUser(userSe) {
      await this.$store
        .dispatch("manage/getModifyUserInfo", userSe)
        .then((res) => {
          this.$store.commit("manage/modalState", {
            isModalShow: true,
            modalSection: "modify",
            modifyList: res.data.userList,
          });
        });
    },
    getUserBoardList() {
      if (this.$route.params.section == "user") {
        this.$store
          .dispatch("manage/getUsersBoardList", {
            nowPageNum: this.getNowPageNum,
            pageSize: 10,
          })
          .then((res) => {
            this.userBoardList = res.data.userList;
            this.$store.commit("setTotalCnt", {
              totalCnt: Number(res.data.totalCnt),
            });
          });
      }
    },
  },
  watch: {
    getNowPageNum: {
      handler: "getUserBoardList",
      deep: true,
    },
    getTeamId: {
      handler: "getUserBoardList",
      deep: true,
    },
    getIsReload: {
      handler: "getUserBoardList",
      deep: true,
    },
  },
  computed: {
    getNowPageNum() {
      return this.$route.query.page;
    },
    getTeamId() {
      return this.$store.state.manage.teamId;
    },
    getIsReload() {
      return this.$store.state.manage.isReload;
    },
  },
};
</script>
