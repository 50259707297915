<template>
  <footer>
		<span>Copyright ©KBO, All Rights Reserved.</span>
	</footer>
</template>

<script>

export default{ 
  name:'FooterView',
  components:{},
  data() {
    return{
    };
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>
