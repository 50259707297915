<template>
  <div class="search-wrap">
    <p class="tit">항목선택</p>
    <ul>
      <li :class="{ on: this.$route.params.section == 'count' }">
        <a href="#" @click.prevent="this.$router.push('/team/count?page=1')"
          >입장 인원 관리</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "TeamManageMenuView",
};
</script>
